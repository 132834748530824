import React, { Fragment, useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import {
  CardBody,
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Collapse,
  Table,
} from "reactstrap";
import { H2, H4, H5 } from "../../../AbstractElements";
import { Accordion } from "react-bootstrap";
import { useSelector, dispatch } from "react-redux";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import { FETCH_MARKET_INSTRUMENTS_URL } from "../../../_helper/url_helper";
import { useDraggable } from "react-use-draggable-scroll";
import CreateOrderModel from "./CreateOrderModel";
import { useDispatch } from "react-redux";
import { changeOrderWindow } from "../../../store/Order/action";
import io from 'socket.io-client';

const GeneralComponent = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [isChange, setIsChange] = useState(false);
  const loginState = useSelector((state) => state.login);
  const [instruments, setInstruments] = useState([]);
  const [allInstruments, setAllInstruments] = useState([]);
  const instrumentsRef = useRef(0);
  instrumentsRef.current = instruments;
  const [isOpen, setIsOpen] = useState(1);
  const [query, setQuery] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
  const { events } = useDraggable(ref);

  async function changeOrderWindowHandler(instrument, type) {
    const data = {
      instrument: instrument,
      type: type,
    };
    dispatch(changeOrderWindow(data));
  }

  useEffect(() => {
    // let ws = new WebSocket("wss:/ws2.tradeyarr.com/websocket-server");
    const socket = io('https://ws2alphastock.bullex.in');
    // const socket = io('https://ws2.bullex.in');
    socket.on('connect', () => {
      Object.keys(instruments).forEach((key) => {
        // ws.send(
        //   JSON.stringify({
        //     action: "subscribe",
        //     token: key
        //   })
        // );

        socket.emit('tokenData', key);
      });
    });
    // socket.on('*', (eventName, data) => {
    //   console.log(`Received event '${eventName}' from any room:`, data);
    // });
    Object.keys(instruments).forEach((key) => {
      socket.on(`room${key}`, (data) => {
        // data = JSON.parse(data);
        // console.log(data);
        //for instrument live price
        const tempWatchlist = JSON.parse(JSON.stringify(instrumentsRef.current));
        if (data.token !== undefined) {
          if (data.ask_price !== undefined && data.ask_price !== tempWatchlist[data.token]["ask_price"]) {
            //update watchlist
            // if (tempWatchlist[data.token] !== undefined) {
            tempWatchlist[data.token]["pre_ask_price"] =
              tempWatchlist[data.token]["ask_price"];
            tempWatchlist[data.token]["ask_price"] = data.ask_price;
            // }
          }
          if (data.bid_price !== undefined && data.bid_price !== tempWatchlist[data.token]["bid_price"]) {
            //update watchlist
            // if (tempWatchlist[data.token] !== undefined) {
            tempWatchlist[data.token]["pre_bid_price"] =
              tempWatchlist[data.token]["bid_price"];
            tempWatchlist[data.token]["bid_price"] = data.bid_price;
            // }
          }

          if (data.ltp !== undefined) {
            //update watchlist
            // if (tempWatchlist[data.token] !== undefined) {
            tempWatchlist[data.token]["pre_ltp"] =
              tempWatchlist[data.token]["ltp"];
            tempWatchlist[data.token]["ltp"] = data.ltp;
            // }
          }

          if (data.percentage_change !== undefined) {
            tempWatchlist[data.token]["percentage_change"] =
              data.percentage_change;
          }

          if (data.low_price !== undefined) {
            tempWatchlist[data.token]["low_price"] =
              data.low_price;
          }

          if (data.high_price !== undefined) {
            tempWatchlist[data.token]["high_price"] =
              data.high_price;
          }
          setInstruments(tempWatchlist);
        }
      });
    });

    return () => {
      //  ws.close()
      socket.disconnect();
    };
  }, [isChange]);

  // useEffect(()=>{
  //       console.log(instruments[37833]);
  //  },[instruments]);

  useEffect(() => {
    fetchMarketInstruments();
  }, []);

  useEffect(() => {
    let temp = {};
    Object.keys(allInstruments).forEach((key) => {
      if (Object.keys(temp).length >= 12) {
        return;
      }
      let instrument = allInstruments[key];
      if (["FUTSTK", "FUTCOM", "FUTIDX"].includes(instrument["segment"])) {
        temp[instrument.token] = instrument;
      }
    });
    setInstruments(temp);
    setIsChange(!isChange);
  }, [allInstruments]);

  useEffect(() => {
    let temp = {};
    if (query === "") {

      Object.keys(allInstruments).forEach((key) => {
        if (Object.keys(temp).length >= 12) {
          return;
        }
        let instrument = allInstruments[key];
        if (["FUTSTK", "FUTCOM", "FUTIDX"].includes(instrument["segment"])) {
          temp[instrument.token] = instrument;
        }
      });
    } else {
      Object.keys(allInstruments).forEach((key) => {
        if (Object.keys(temp).length >= 12) {
          return;
        }
        let instrument = allInstruments[key];
        if (instrument["trading_symbol"].startsWith(query)) {
          temp[instrument.token] = instrument;
        }
      });
    }
    setInstruments(temp);
    setIsChange(!isChange);
  }, [query]);

  async function fetchMarketInstruments() {
    try {
      // let data = {
      //   jwttoken: loginState.jwttoken,
      // };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_MARKET_INSTRUMENTS_URL);
      if (res.data.status === "SUCCESS") {
        let temp = {};
        res.data.payload.forEach((instrument) => {
          instrument["pre_ltp"] = instrument["ltp"]
          temp[instrument.token] = instrument;
        });
        setAllInstruments(temp);
        // res.data.data.forEach((instrument) => {
        //   if (isConnected) {
        //     ws.send(
        //       JSON.stringify({
        //         action: "subscribe",
        //         token: instrument.token,
        //       })
        //     );
        //   }
        // });
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Fragment>
      <CreateOrderModel />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="py-3">
                <h5>MarketWatch</h5>
              </CardHeader>
              <div className="border-top">
                <input
                  type="search"
                  className="form-control search border-0 fw-semibold px-4 py-3"
                  placeholder="Search for instruments..."
                  value={query}
                  onChange={(e) => { setQuery(e.target.value.toUpperCase()) }}
                />
                <i className="ri-search-line search-icon"></i>
              </div>
              <div className="table-responsive" ref={ref} {...events}>
                <Table className="table-border-horizontal fw-semibold align-middle">
                  <thead>
                    <tr className="bg-secondary">
                      <th style={{ color: "#ffffff" }}>#</th>
                      <th style={{ color: "#ffffff" }}>Script</th>
                      <th style={{ color: "#ffffff" }}>LotSize</th>
                      <th style={{ color: "#ffffff" }}>Bid</th>
                      <th style={{ color: "#ffffff" }}>Ask</th>
                      <th style={{ color: "#ffffff" }}>LTP</th>
                      <th style={{ color: "#ffffff" }}>Change</th>
                      <th style={{ color: "#ffffff" }}>High</th>
                      <th style={{ color: "#ffffff" }}>Low</th>
                      <th style={{ color: "#ffffff" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <MarketData instruments={instruments} changeOrderWindowHandler={changeOrderWindowHandler} />
                  </tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};


function MarketData(props) {
  if (
    Object.keys(props.instruments).length
  ) {
    return (
      <>
        {Object.keys(props.instruments).map((key, idx) => {
          let instrument = props.instruments[key];
          return (
            <tr key={idx}>
              <td>{idx + 1}</td>
              <td>{instrument.trading_symbol}</td>
              <td>{instrument.lotsize}</td>
              <td
                className={

                  (instrument.bid_price > instrument.pre_bid_price
                    ? "bg-success"
                    : "bg-danger")
                }
              // style={{
              //   backgroundColor:
              //     instrument.bid_price > instrument.pre_bid_price
              //       ? "rgba(36,105,92,.1)"
              //       : "rgba(220, 53, 69,.1)",
              // }}
              >
                {instrument.bid_price}
              </td>

              <td
                className={

                  (instrument.ask_price > instrument.pre_ask_price
                    ? "bg-success"
                    : "bg-danger")
                }

              // style={{
              //   backgroundColor:
              //     instrument.ask_price > instrument.pre_ask_price
              //       ? "rgba(36,105,92,.1)"
              //       : "rgba(220, 53, 69,.1)",
              // }}
              >
                {instrument.ask_price}
              </td>

              <td
                className={

                  (instrument.ltp > instrument.pre_ltp
                    ? "bg-success"
                    : "bg-danger")
                }

              // style={{
              //   backgroundColor:
              //     instrument.ask_price > instrument.pre_ask_price
              //       ? "rgba(36,105,92,.1)"
              //       : "rgba(220, 53, 69,.1)",
              // }}
              >
                {instrument.ltp}
              </td>

              <td className={
                "f-14 " +
                (instrument.percentage_change > 0
                  ? "text-success"
                  : "text-danger")
              }>
                {instrument.percentage_change}%
              </td>
              <td>{instrument.high_price}</td>
              <td>{instrument.low_price}</td>
              <td>{actionBinder(props.changeOrderWindowHandler, instrument)}</td>
            </tr>
          );
        })}
      </>
    );
  } else {
    return (<></>);
  }
}

export default GeneralComponent;

function actionBinder(changeOrderWindowHandler, instrument) {
  return (
    <div>
      <ul className="list-inline hstack gap-1 mb-0">
        <li className="list-inline-item">
          <button
            title="view"
            className="btn btn-sm btn-primary p-0 d-flex justify-content-center align-items-center fw-semibold"
            style={{ width: "30px", height: "30px" }}
            onClick={() => { changeOrderWindowHandler(instrument, "buy") }}
          >
            B
          </button>
        </li>
        <li className="list-inline-item edit">
          <button
            title="update"
            className="btn btn-sm btn-danger p-0 d-flex justify-content-center align-items-center fw-semibold"
            style={{ width: "30px", height: "30px" }}
            onClick={() => { changeOrderWindowHandler(instrument, "sell") }}
          >
            S
          </button>
        </li>
      </ul>
    </div>
  );
}
