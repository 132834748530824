import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useSelector } from "react-redux";
import { CHANGE_PASSWORD_URL } from "../../../../_helper/url_helper";
import api from "../../../../_helper/api_helper";
import { toast } from "react-toastify";

export default function ChangePasswordModal(props) {
  const loginState = useSelector((state) => state.login);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  async function changePasswordHandler() {
    try {
      if (oldPassword === "") {
        toast.error("need Old Password");
        return;
      }
      if (newPassword === "") {
        toast.error("need New Password");
        return;
      }
      if (newPassword != confirmPassword) {
        toast.error("Confirm password not match");
        return;
      }
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let data = {
        // jwttoken: loginState.jwttoken,
        old_password: oldPassword,
        password: newPassword,
      };
      let res = await api.post(CHANGE_PASSWORD_URL, data);
      if (res.data.status === "SUCCESS") {
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        toast.success("Password Updated successfully");
        props.setChangePasswordModalStatus(false);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <>
      <Modal
        isOpen={props.changePasswordModalStatus}
        toggle={() => {
          props.setChangePasswordModalStatus(false);
        }}
      >
        <ModalHeader>Change Password</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 mb-4">
              <label>Enter old password</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter password"
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
              />
            </div>
            <div className="col-12 mb-4">
              <label>Enter new password</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter password"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              />
            </div>
            <div className="col-12">
              <label>Enter confirm password</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter password"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary mx-3" onClick={changePasswordHandler}>Submit</button>
          <button
            className="btn btn-outline-secondary"
            onClick={() => {
              props.setChangePasswordModalStatus(false);
            }}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}
