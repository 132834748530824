import React, { Fragment, useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import CreatePositionModal from "./CreatePositionModal";
import {
  CardBody,
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Collapse,
  Table,
} from "reactstrap";
import { H2, H4, H5 } from "../../../AbstractElements";
import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import { FETCH_ACTIVE_SCRIPTS_URL } from "../../../_helper/url_helper";
import { useNavigate } from "react-router-dom";

const GeneralComponent = () => {
  const navigate = useNavigate();
  const loginState = useSelector((state) => state.login);
  const [isOpen, setIsOpen] = useState(1);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  const [startDate, setstartDate] = useState(new Date());
  const [activeScripts, setActiveScripts] = useState({});
  const [createPositionModalStatus, setCreatePositionModalStatus] = useState(false);

  useEffect(() => {
    fetchActiveScriptsHandler();
  }, []);

  useEffect(() => {
    if (createPositionModalStatus === false) {
      fetchActiveScriptsHandler();
    }
  }, [createPositionModalStatus]);

  useEffect(() => {
    let interval = setInterval(fetchActiveScriptsHandler, 20000);
    return () => clearInterval(interval);
  }, []);

  async function fetchActiveScriptsHandler() {
    try {
      // let data = {
      //   jwttoken: loginState.jwttoken,
      // };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_ACTIVE_SCRIPTS_URL);
      if (res.data.status === "SUCCESS") {
        setActiveScripts(res.data.payload);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Fragment>
      {/* <CreatePositionModal createPositionModalStatus={createPositionModalStatus} setCreatePositionModalStatus={setCreatePositionModalStatus} /> */}
      <Container fluid={true}>
        {/* <Row>
          <Col sm="12">
            <Accordion defaultActiveKey="0">
              <div className="default-according" id="accordion1">
                <Card className="mb-4">
                  <CardHeader className="bg-primary" onClick={() => toggle(1)}>
                    <h5 className="d-flex justify-content-between">
                      <span>Filters</span>
                      {isOpen === 1 ? (
                        <i className="icofont icofont-rounded-up"></i>
                      ) : (
                        <i className="icofont icofont-rounded-down"></i>
                      )}
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={isOpen === 1}>
                    <CardBody>
                      <div className="row">
                        <div className="col">
                          <label className="col-form-label form-label">
                            Search Script
                          </label>
                          <input type="text" className="form-control" placeholder="Enter Script"/>
                        </div>

                        <div className="col">
                          <label className="col-form-label form-label">
                            View user panel
                          </label>
                          <input type="text" className="form-control" placeholder="Select Username"/>
                        </div>

                        <div className="col row align-items-end">
                          <div>
                            <button className="btn btn-danger m-t-5">
                              Reset
                            </button>
                          </div>
                        </div>
                        <div className="col"></div>
                      </div>
                    </CardBody>
                  </Collapse>
                </Card>
              </div>
            </Accordion>
          </Col>
        </Row> */}

        <Row>
          <Col sm="12">
            <Card>

              {(loginState.owner != undefined && loginState.owner.type === "super") && <div className="d-flex justify-content-between align-items-center py-2 px-3">
                <h5 className="m-0 fw-semibold">Active Positions</h5>
                {/* <div><button className="btn btn-secondary" onClick={() => { setCreatePositionModalStatus(true) }}>Create</button></div> */}
              </div>}
              {/* <CardBody> */}
              <Table className="table-border-horizontal fw-semibold" striped>
                <thead>
                  <tr className="bg-secondary">
                    <th style={{ color: "#ffffff" }}>Script</th>
                    <th style={{ color: "#ffffff" }}>Active Buy</th>
                    <th style={{ color: "#ffffff" }}>Active Sell</th>
                    <th style={{ color: "#ffffff" }}>Avg Buy Rate</th>
                    <th style={{ color: "#ffffff" }}>Avg Sell Rate</th>
                    <th style={{ color: "#ffffff" }}>Total</th>
                    {/* <th style={{color:"#ffffff"}}>Net</th> */}
                  </tr>
                </thead>
                <tbody>
                  {activeScripts.scripts !== undefined
                    ? activeScripts.scripts.map((script, idx) => {
                      return (
                        <tr key={idx} onClick={() => { navigate(`/activePositionClients/${script.token}`) }}>
                          <td>{script.trading_symbol}</td>
                          <td>{script.active_buy}</td>
                          <td>{script.active_sell}</td>
                          <td>{script.avg_buy_price}</td>
                          <td>{script.avg_sell_price}</td>
                          <td>{script.total}</td>
                        </tr>
                      );
                    })
                    : ""}
                  <tr>
                    <th>Total</th>
                    <td>
                      {activeScripts.total_active_buy !== undefined
                        ? activeScripts.total_active_buy
                        : "loading"}
                    </td>
                    <td>
                      {activeScripts.total_active_sell !== undefined
                        ? activeScripts.total_active_sell
                        : "loading"}
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      {activeScripts.total_total !== undefined
                        ? activeScripts.total_total
                        : "loading"}
                    </td>
                  </tr>
                </tbody>
              </Table>
              {/* </CardBody> */}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GeneralComponent;
