import React, { Fragment, useState } from "react";
import { Card, Col, Row, Collapse, Table } from "reactstrap";
import { Accordion } from "react-bootstrap";

const ScriptTableNfo = (props) => {
  function changeNfoBrokerageLotHandler(value, symbol) {
    if (value < 0) {
      return;
    }
    const temp = JSON.parse(JSON.stringify(props.nfoBrokerageLot));
    temp[symbol] = value;
    props.setNfoBrokerageLot(temp);
  }
  return (
    <Fragment>
      <Accordion defaultActiveKey="0">
        <div className="default-according" id="accordion1">
          <Collapse
            isOpen={
              props.exchange === "equity"
            }
          >
            <Row>
              <Col sm="12">
                <Card>
                  <Table
                    className="table-border-horizontal fw-semibold align-middle"
                    striped
                  >
                    <thead>
                      <tr className="bg-primary">
                        <th style={{ color: "#ffffff", width: "40px" }} >#</th>
                        <th style={{ color: "#ffffff" }}>Script</th>
                        <th style={{ color: "#ffffff" }}>Brokerage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.nfoSymbols.length
                        ? props.nfoSymbols.map((symbol, idx) => {
                          // if(idx >= 2 ){
                          //   return (<></>);
                          // }
                          return (
                            <tr key={idx}>
                              <td>{idx + 1}</td>
                              <td>{symbol["symbol"]}</td>
                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder={(idx < 2) ? "Enter brokerage per lot for index" : "Enter brokerage per crore for options"}
                                  value={
                                    props.nfoBrokerageLot[symbol["symbol"]]
                                  }
                                  onChange={(e) => {
                                    changeNfoBrokerageLotHandler(
                                      e.target.value,
                                      symbol["symbol"]
                                    );
                                  }}
                                />
                              </td>
                            </tr>
                          );
                        })
                        : ""}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
          </Collapse>
        </div>
      </Accordion>
    </Fragment>
  );
};

export default ScriptTableNfo;
