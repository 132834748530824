import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
} from "reactstrap";
import Detail from "./Detail";
import ActivePositions from "./ActivePositions";
import ClosedPositions from "./ClosedPositions";
import ClientBill from "./ClientBill";
import ExecutedOrders from "./ExecutedOrders";
import PendingOrders from "./PendingOrders";
import { Accordion } from "react-bootstrap";
import Brokers from "./Brokers";
import { Btn, H5 } from "../../../../AbstractElements";
import { FETCH_CLIENT_URL, FETCH_CLIENT_TRANSACTIONS_URL } from "../../../../_helper/url_helper";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import api from "../../../../_helper/api_helper";
import { toast } from "react-toastify";
import Clients from "./Clients";
import { H4 } from '../../../../AbstractElements';
import Transactions from "./Transactions";
import { DollarSign } from 'react-feather';

const GeneralComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  const loginState = useSelector((state) => state.login);
  const { clientid } = useParams();
  const [client, setClient] = useState({});
  const [data, setData] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [payinOutData,setPayinOutData] = useState({deposit:0, withdraw:0});

    const topData = [
       {
            id: 1,
            classCompo: 'bg-primary',
            icon: <DollarSign />,
            title: 'Deposit',
            count: parseFloat(payinOutData.deposit).toFixed(2),
            iconWithClass: <DollarSign className="icon-bg" />
        },
          {
            id: 2,
            classCompo: 'bg-danger',
            icon: <DollarSign />,
            title: 'Withdraw',
            count: parseFloat(payinOutData.withdraw).toFixed(2),
            iconWithClass: <DollarSign className="icon-bg" />
        },
    ];

  useEffect(() => {
    fetchClientHandler();
    fetchTransactionsHandler();
  }, []);


  useEffect(() => {
    let interval = setInterval(fetchClientHandler,2500);
    return () => clearInterval(interval);
  },[]);

  async function fetchClientHandler() {
    try {
      let data = {
        jwttoken: loginState.jwttoken,
        client_id: clientid,
      };
      let res = await api.post(FETCH_CLIENT_URL, data);
      if (res.data.status === 1) {
        setClient(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function fetchTransactionsHandler() {
    try {
      let data = {
        jwttoken: loginState.jwttoken,
        client_id: clientid,
      };
      let res = await api.post(FETCH_CLIENT_TRANSACTIONS_URL, data);
      if (res.data.status == 1) {
        setTransactions(res.data.data);
        let withdraw = 0;
        let deposit = 0;
        res.data.data.forEach((transaction)=>{
           if(transaction.status === "complete"){
               if(transaction.type === "withdraw"){
                   withdraw += Number(transaction.amount);
               }else{
                   deposit += Number(transaction.amount);
               }
           }
        });

        setPayinOutData({deposit: deposit,withdraw: withdraw});
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
                      {topData.map((item) =>
             <Col sm="6" xl="6" lg="6" key={item.id}>
                    <Card className="o-hidden border-0">
                        <CardBody className={item.classCompo}>
                            <div className="media static-top-widget">
                                <div className="align-self-center text-center">
                                    {item.icon}
                                </div>
                                <div className="media-body">
                                    <span className="m-0">{item.title}</span>
                                    <H4 attrH4={{ className: 'mb-0 counter' }} >{item.count}</H4>
                                    {item.iconWithClass}
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
)}
        </Row>
        <Row>
          <Accordion defaultActiveKey="0">
            <div className="default-according" id="accordion1">
              <Card className="mb-4">
                <CardHeader className="bg-primary" onClick={() => toggle(1)}>
                  <h5 className="d-flex justify-content-between">
                    <span>Detail</span>{" "}
                    {isOpen === 1 ? (
                      <i className="icofont icofont-rounded-up"></i>
                    ) : (
                      <i className="icofont icofont-rounded-down"></i>
                    )}
                  </h5>
                </CardHeader>
                <Collapse isOpen={isOpen === 1}>
                  <CardBody>
                   {client["personal"] !== undefined && <Detail client={client} />}
                  </CardBody>
                </Collapse>
              </Card>

              <Card className="mb-4">
                <CardHeader className="bg-secondary" onClick={() => toggle(2)}>
                  <h5 className="d-flex justify-content-between">
                    <span>Active Positions</span>{" "}
                    {isOpen === 2 ? (
                      <i className="icofont icofont-rounded-up"></i>
                    ) : (
                      <i className="icofont icofont-rounded-down"></i>
                    )}
                  </h5>
                </CardHeader>
                <Collapse isOpen={isOpen === 2}>
                  <CardBody>
                   {client["active_positions"] !== undefined && <ActivePositions client={client} />}
                  </CardBody>
                </Collapse>
              </Card>

              <Card className="mb-4">
                <CardHeader className="bg-primary" onClick={() => toggle(3)}>
                  <h5 className="d-flex justify-content-between">
                    <span>Closed Positions</span>{" "}
                    {isOpen === 3 ? (
                      <i className="icofont icofont-rounded-up"></i>
                    ) : (
                      <i className="icofont icofont-rounded-down"></i>
                    )}
                  </h5>
                </CardHeader>
                <Collapse isOpen={isOpen === 3}>
                  <CardBody>
                   {client["active_positions"] !== undefined && <ClosedPositions client={client} />}
                  </CardBody>
                </Collapse>
              </Card>

              <Card className="mb-4">
                <CardHeader className="bg-secondary" onClick={() => toggle(4)}>
                  <h5 className="d-flex justify-content-between">
                    <span>Bill</span>{" "}
                    {isOpen === 4 ? (
                      <i className="icofont icofont-rounded-up"></i>
                    ) : (
                      <i className="icofont icofont-rounded-down"></i>
                    )}
                  </h5>
                </CardHeader>
                <Collapse isOpen={isOpen === 4}>
                  <CardBody>
                   {client["bill"] !== undefined && <ClientBill client={client} />}
                  </CardBody>
                </Collapse>
              </Card>

              <Card className="mb-4">
                <CardHeader className="bg-primary" onClick={() => toggle(5)}>
                  <h5 className="d-flex justify-content-between">
                    <span>Executed Orders</span>{" "}
                    {isOpen === 5 ? (
                      <i className="icofont icofont-rounded-up"></i>
                    ) : (
                      <i className="icofont icofont-rounded-down"></i>
                    )}
                  </h5>
                </CardHeader>
                <Collapse isOpen={isOpen === 5}>
                  <CardBody>
                   {client["executed_orders"] !== undefined && <ExecutedOrders client={client} />}
                  </CardBody>
                </Collapse>
              </Card>

              <Card className="mb-4">
                <CardHeader className="bg-secondary" onClick={() => toggle(6)}>
                  <h5 className="d-flex justify-content-between">
                    <span>Pending Orders</span>{" "}
                    {isOpen === 6 ? (
                      <i className="icofont icofont-rounded-up"></i>
                    ) : (
                      <i className="icofont icofont-rounded-down"></i>
                    )}
                  </h5>
                </CardHeader>
                <Collapse isOpen={isOpen === 6}>
                  <CardBody>
                   {client["pending_orders"] !== undefined && <PendingOrders client={client} />}
                  </CardBody>
                </Collapse>
              </Card>


              <Card className="mb-4">
                <CardHeader className="bg-primary" onClick={() => toggle(7)}>
                  <h5 className="d-flex justify-content-between">
                    <span>transactions</span>{" "}
                    {isOpen === 7 ? (
                      <i className="icofont icofont-rounded-up"></i>
                    ) : (
                      <i className="icofont icofont-rounded-down"></i>
                    )}
                  </h5>
                </CardHeader>
                <Collapse isOpen={isOpen === 7}>
                  <CardBody>
                   {<Transactions transactions={transactions} />}
                  </CardBody>
                </Collapse>
              </Card>
            </div>
          </Accordion>
        </Row>
      </Container>
    </Fragment>
  );
};
export default GeneralComponent;
