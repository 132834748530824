import React, { Fragment, useState, useEffect } from "react";
import {
  CardBody,
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Nav,
  Collapse,
  NavItem,
  NavLink,
  Label,
} from "reactstrap";
import { useSelector } from "react-redux";
import api from "../../../../../_helper/api_helper";
import { toast } from "react-toastify";
import {
  FETCH_SYMBOLS_URL,
  CREATE_BROKERAGE_URL,
  FETCH_SINGLE_TEMPLATE_URL,
  UPDATE_BROKERAGE_TEMPLATE
} from "../../../../../_helper/url_helper";
import { useNavigate, useParams } from "react-router-dom";
import ScriptTable from "./ScriptTable";
import ScriptTableNfo from "./ScriptTableNfo";

const GeneralComponent = ({ setBrokerageData, brokerageTemplateId }) => {
  const navigate = useNavigate();
  const templateId = brokerageTemplateId;
  const loginState = useSelector((state) => state.login);
  const [exchange, setExchange] = useState("equity");
  const [mcxBrokerageType, setMcxBrokerageType] = useState("crore");
  const [equityBrokerage, setEquityBrokerage] = useState("");
  const [cdsBrokerage, setCdsBrokerage] = useState("");
  const [mcxBrokerage, setMcxBrokerage] = useState("");
  const [mcxSymbols, setMcxSymbols] = useState("");
  const [mcxBrokerageLot, setMcxBrokerageLot] = useState("");
  const [nfoSymbols, setNfoSymbols] = useState([]);
  const [nfoBrokerageLot, setNfoBrokerageLot] = useState("");
  const [fxBrokerage, setFxBrokerage] = useState("");
  const [cryptoBrokerage, setCryptoBrokerage] = useState("");
  const [usBrokerage, setUsBrokerage] = useState("");

  const [nfoOptionsBrokerageChanger, setNfoOptionsBrokerageChanger] = useState("");

  useEffect(() => {
    submitTemplateHandler()
  }, [mcxBrokerageType, mcxBrokerage, equityBrokerage, cdsBrokerage, mcxBrokerageLot, nfoBrokerageLot, fxBrokerage, cryptoBrokerage, usBrokerage]);
  //fetch first time users
  useEffect(() => {
    fetchSymbolsHandler();
  }, [brokerageTemplateId]);

  useEffect(() => {
    //  setup nfo data
    let temp = {};
    nfoSymbols.map((symbol, idx) => {
      let obj = {};
      temp[symbol["symbol"]] = nfoOptionsBrokerageChanger;
      return obj;
    });
    setNfoBrokerageLot(temp);
  }, [nfoOptionsBrokerageChanger]);

  async function fetchSymbolsHandler() {
    try {
      // let data = {
      //   jwttoken: loginState.jwttoken,
      // };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_SYMBOLS_URL);
      if (res.data.status === "SUCCESS") {
        //setup mcx data
        let temp = {};
        res.data.payload.MCX.map((symbol) => {
          let obj = {};
          temp[symbol["symbol"]] = "";
          return obj;
        });
        setMcxBrokerageLot(temp);
        setMcxSymbols(res.data.payload.MCX);
        //setup nfo data
        temp = {};
        res.data.payload.NFO.map((symbol) => {
          let obj = {};
          temp[symbol["symbol"]] = "";
          return obj;
        });
        setNfoSymbols(res.data.payload.NFO);
        setNfoBrokerageLot(temp);

        if (templateId && (templateId !== "")) {
          fetchTemplate();
        }

      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function fetchTemplate() {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let data = {
        // jwttoken: loginState.jwttoken,
        template_id: templateId
      };
      let res = await api.post(FETCH_SINGLE_TEMPLATE_URL, data);
      if (res.data.status === "SUCCESS") {
        // setName(res.data.payload.name);
        setEquityBrokerage(res.data.payload.equity_brokerage_per_crore);
        setCdsBrokerage(res.data.payload.cds_brokerage_per_lot);
        setFxBrokerage(res.data.payload.fx_brokerage);
        setCryptoBrokerage(res.data.payload.crypto_brokerage);
        setUsBrokerage(res.data.payload.us_brokerage);
        setMcxBrokerageType(res.data.payload.mcx_brokerage_type);
        //set nfo option lot
        let temp = {};
        res.data.payload.get_brokerages.map((brokerage) => {
          let obj = {};
          if (brokerage["exchange"] === "NFO") {
            temp[brokerage["instrument_symbol"]] = brokerage["brokerage"];
          }
          return obj;
        });
        setNfoBrokerageLot(temp);
        //set mcx lot
        if (res.data.payload.mcx_brokerage_type === "crore") {
          setMcxBrokerage(res.data.payload.mcx_brokerage_per_crore);
        } else {
          temp = {};
          res.data.payload.get_brokerages.map((brokerage) => {
            let obj = {};
            if (brokerage["exchange"] === "MCX") {
              temp[brokerage["instrument_symbol"]] = brokerage["brokerage"];
            }
            return obj;
          });
          setMcxBrokerageLot(temp);
        }
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function updateTemplateHandler() {
    try {
      let data = {};
      if (equityBrokerage === "") {
        toast.error("EQUITY brokerage required");
        setExchange("equity");
        return;
      }
      if (fxBrokerage === "") {
        toast.error("FX brokerage required");
        setExchange("fx");
        return;
      }
      if (cryptoBrokerage === "") {
        toast.error("CRYPTO brokerage required");
        setExchange("crypto");
        return;
      }
      if (usBrokerage === "") {
        toast.error("US brokerage required");
        setExchange("us");
        return;
      }
      for (let i = 0; i < nfoSymbols.length; i++) {
        if (nfoBrokerageLot[nfoSymbols[i]["symbol"]] === "") {
          toast.error(`${nfoSymbols[i]["symbol"]} brokerage required`);
          setExchange("equity");
          return;
        } else {
          data[nfoSymbols[i]["symbol"] + "_brokerage"] =
            nfoBrokerageLot[nfoSymbols[i]["symbol"]];
        }
      }

      if (cdsBrokerage === "") {
        toast.error("CDS brokerage required");
        setExchange("cds");
        return;
      }

      if (mcxBrokerageType === "crore") {
        if (mcxBrokerage === "") {
          toast.error("MCX brokerage required");
          setExchange("mcx");
          return;
        } else {
          data["mcx_brokerage_per_crore"] = mcxBrokerage;
        }
      } else {
        for (let i = 0; i < mcxSymbols.length; i++) {
          if (mcxBrokerageLot[mcxSymbols[i]["symbol"]] === "") {
            toast.error(`${mcxSymbols[i]["symbol"]} brokerage required`);
            setExchange("mcx");
            return;
          } else {
            data[mcxSymbols[i]["symbol"] + "_brokerage"] =
              mcxBrokerageLot[mcxSymbols[i]["symbol"]];
          }
        }
      }

      data = {
        ...data,
        // jwttoken: loginState.jwttoken,
        template_id: templateId,
        // name: name,
        equity_brokerage_per_crore: equityBrokerage,
        cds_brokerage_per_lot: cdsBrokerage,
        mcx_brokerage_type: mcxBrokerageType,
        fx_brokerage: fxBrokerage,
        crypto_brokerage: cryptoBrokerage,
        us_brokerage: usBrokerage
      };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.post(UPDATE_BROKERAGE_TEMPLATE, data);
      if (res.data.status === "SUCCESS") {
        toast.success("Template updated sucessfully");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function submitTemplateHandler() {
    try {
      let data = {};
      // if (name === "") {
      //   toast.error("Template name required");
      //   return;
      // }
      // if (equityBrokerage === "") {
      //   toast.error("EQUITY brokerage required");
      //   setExchange("equity");
      //   return;
      // }
      // if (fxBrokerage === "") {
      //   toast.error("FX brokerage required");
      //   setExchange("fx");
      //   return;
      // }
      // if (cryptoBrokerage === "") {
      //   toast.error("CRYPTO brokerage required");
      //   setExchange("crypto");
      //   return;
      // }
      // if (usBrokerage === "") {
      //   toast.error("US brokerage required");
      //   setExchange("us");
      //   return;
      // }

      for (let i = 0; i < nfoSymbols.length; i++) {
        if (nfoBrokerageLot[nfoSymbols[i]["symbol"]] === "") {
          // toast.error(`${nfoSymbols[i]["symbol"]} brokerage required`);
          // setExchange("equity");
          return;
        } else {
          data[nfoSymbols[i]["symbol"] + "_brokerage"] =
            nfoBrokerageLot[nfoSymbols[i]["symbol"]];
        }
      }

      // if (cdsBrokerage === "") {
      //   toast.error("CDS brokerage required");
      //   setExchange("cds");
      //   return;
      // }


      if (mcxBrokerageType === "crore") {
        if (mcxBrokerage === "") {
          // toast.error("MCX brokerage required");
          // setExchange("mcx");
          return;
        } else {
          data["mcx_brokerage_per_crore"] = mcxBrokerage;
        }
      } else {
        for (let i = 0; i < mcxSymbols.length; i++) {
          if (mcxBrokerageLot[mcxSymbols[i]["symbol"]] === "") {
            // toast.error(`${mcxSymbols[i]["symbol"]} brokerage required`);
            // setExchange("mcx");
            return;
          } else {
            data[mcxSymbols[i]["symbol"] + "_brokerage"] =
              mcxBrokerageLot[mcxSymbols[i]["symbol"]];
          }
        }
      }
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      data = {
        ...data,
        // jwttoken: loginState.jwttoken,
        equity_brokerage_per_crore: equityBrokerage,
        cds_brokerage_per_lot: cdsBrokerage,
        mcx_brokerage_type: mcxBrokerageType,
        fx_brokerage: fxBrokerage,
        crypto_brokerage: cryptoBrokerage,
        us_brokerage: usBrokerage
      };
      setBrokerageData(data)
      // let res = await api.post(CREATE_BROKERAGE_URL, data);
      // if (res.data.status === "SUCCESS") {
      //   toast.success("Template created sucessfully");
      // } else {
      //   toast.error("test");
      //   toast.error(res.data.message);
      // }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Fragment>
      {/* <Container fluid={true} className="datatables"> */}
      <Row>
        <Col sm="12">
          <Card>
            {/* <CardHeader className="p-b-0 mb-3 pt-4">
                <div className="row g-4 align-items-center">
                  <div className="col-sm">
                    <div>
                      <h5 className="card-title mb-0">
                        Create Brokerage Template
                      </h5>
                    </div>
                  </div>
                  <div className="col-sm-auto">
                    <div className="row">
                      <div className="col-8">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter template name"
                          value={name}
                          onChange={(e) => {
                            if (e.target.value === "" || /^[a-zA-Z0-9 ]*$/.test(e.target.value)) {
                              setName(e.target.value);
                            }
                          }}
                        />
                      </div> 
                      <div className="col-4">
                        {(!templateId) && <button
                          type="button"
                          className="btn btn-success add-btn"
                          onClick={submitTemplateHandler}
                        >
                          Insert
                        </button>}

                        {(templateId) && <button
                          type="button"
                          className="btn btn-success add-btn"
                          onClick={updateTemplateHandler}
                        >
                          Update
                        </button>}
                      </div>
                    </div>

                  </div>
                </div>
              </CardHeader> */}

            <CardBody className="border-top">
              <Nav tabs className="row justify-content-between mb-4">
                <NavItem className="col px-0">
                  <NavLink
                    className={`text-center ${exchange === "equity" ? "active" : ""
                      }`}
                    onClick={() => setExchange("equity")}
                  >
                    EQUITY
                  </NavLink>
                </NavItem>
                <NavItem className="col px-0">
                  <NavLink
                    className={`text-center ${exchange === "cds" ? "active" : ""
                      }`}
                    onClick={() => setExchange("cds")}
                  >
                    CDS
                  </NavLink>
                </NavItem>

                <NavItem className="col px-0">
                  <NavLink
                    className={`text-center ${exchange === "mcx" ? "active" : ""
                      }`}
                    onClick={() => setExchange("mcx")}
                  >
                    MCX
                  </NavLink>
                </NavItem>

                <NavItem className="col px-0">
                  <NavLink
                    className={`text-center ${exchange === "fx" ? "active" : ""
                      }`}
                    onClick={() => setExchange("fx")}
                  >
                    FX
                  </NavLink>
                </NavItem>

                <NavItem className="col px-0">
                  <NavLink
                    className={`text-center ${exchange === "crypto" ? "active" : ""
                      }`}
                    onClick={() => setExchange("crypto")}
                  >
                    CRYPTO
                  </NavLink>
                </NavItem>

                <NavItem className="col px-0">
                  <NavLink
                    className={`text-center ${exchange === "us" ? "active" : ""
                      }`}
                    onClick={() => setExchange("us")}
                  >
                    US
                  </NavLink>
                </NavItem>
              </Nav>
              <Row>
                {exchange === "equity" ? (
                  <><Col className="px-0">
                    <Label>Enter Equity Brokerage Per Crore</Label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter brokerage"
                      value={equityBrokerage}
                      onChange={(e) => {
                        if (e.target.value >= 0 || e.target.value === "") {
                          setEquityBrokerage(e.target.value);
                        }
                      }}
                    />
                  </Col>
                    <div></div>
                    <Col className="px-0 mt-3">
                      <Label>Change Box for changing all below inputs value</Label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter brokerage"
                        value={nfoOptionsBrokerageChanger}
                        onChange={(e) => {
                          if (e.target.value >= 0 || e.target.value === "") {
                            setNfoOptionsBrokerageChanger(e.target.value);
                          }
                        }}
                      />
                    </Col></>
                ) : (
                  ""
                )}

                {exchange === "cds" ? (
                  <Col className="px-0">
                    <Label>Enter CDS Brokerage Per Lot</Label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter brokerage"
                      value={cdsBrokerage}
                      onChange={(e) => {
                        if (e.target.value >= 0 || e.target.value === "") {
                          setCdsBrokerage(e.target.value);
                        }
                      }}
                    />
                  </Col>
                ) : (
                  ""
                )}

                {exchange === "mcx" ? (
                  <>
                    <Col className="mb-4 px-0" sm={12}>
                      <button
                        className={`btn ${mcxBrokerageType === "crore"
                          ? "btn-primary"
                          : "btn-outline-primary"
                          }`}
                        onClick={() => {
                          setMcxBrokerageType("crore");
                        }}
                      >
                        Charge Per Crore
                      </button>
                      <button
                        className={`btn ${mcxBrokerageType === "lot"
                          ? "btn-primary"
                          : "btn-outline-primary"
                          } mx-4`}
                        onClick={() => {
                          setMcxBrokerageType("lot");
                        }}
                      >
                        Charge Per Lot
                      </button>
                    </Col>
                    <Collapse isOpen={mcxBrokerageType === "crore"}>
                      <Col className="px-0" sm={12}>
                        <Label>Enter MCX Brokerage Per Crore</Label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter brokerage"
                          value={mcxBrokerage}
                          onChange={(e) => {
                            if (
                              e.target.value >= 0 ||
                              e.target.value === ""
                            ) {
                              setMcxBrokerage(e.target.value);
                            }
                          }}
                        />
                      </Col>
                    </Collapse>
                  </>
                ) : (
                  ""
                )}



                {exchange === "fx" ? (
                  <Col className="px-0">
                    <Label>Enter FX Brokerage Per Lot</Label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter brokerage"
                      value={fxBrokerage}
                      onChange={(e) => {
                        if (e.target.value >= 0 || e.target.value === "") {
                          setFxBrokerage(e.target.value);
                        }
                      }}
                    />
                  </Col>
                ) : (
                  ""
                )}


                {exchange === "crypto" ? (
                  <Col className="px-0">
                    <Label>Enter Crypto Brokerage Per Lot</Label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter brokerage"
                      value={cryptoBrokerage}
                      onChange={(e) => {
                        if (e.target.value >= 0 || e.target.value === "") {
                          setCryptoBrokerage(e.target.value);
                        }
                      }}
                    />
                  </Col>
                ) : (
                  ""
                )}


                {exchange === "us" ? (
                  <Col className="px-0">
                    <Label>Enter US Brokerage Per Lot</Label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter brokerage"
                      value={usBrokerage}
                      onChange={(e) => {
                        if (e.target.value >= 0 || e.target.value === "") {
                          setUsBrokerage(e.target.value);
                        }
                      }}
                    />
                  </Col>
                ) : (
                  ""
                )}

              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <ScriptTable
        exchange={exchange}
        mcxBrokerageType={mcxBrokerageType}
        mcxSymbols={mcxSymbols}
        mcxBrokerageLot={mcxBrokerageLot}
        setMcxBrokerageLot={setMcxBrokerageLot}
      />

      <ScriptTableNfo
        exchange={exchange}
        nfoSymbols={nfoSymbols}
        nfoBrokerageLot={nfoBrokerageLot}
        setNfoBrokerageLot={setNfoBrokerageLot}
      />
      {/* </Container> */}
    </Fragment>
  );
};

export default GeneralComponent;
