import { CHANGE_ORDER_WINDOW, CLOSE_ORDER_WINDOW, CHANGE_ORDER_TYPE, REFRESH_ACTIVE_INSTRUMENT } from "./actionTypes";

export const changeOrderWindow = (data) => {
  return { type: CHANGE_ORDER_WINDOW, payload: data };
};

export const closeOrderWindow = ()=>{
  return { type: CLOSE_ORDER_WINDOW};
}

export const changeOrderType = (data)=>{
  return { type: CHANGE_ORDER_TYPE, payload:data};
}

export const refreshActiveInstrument = (data)=>{
  return {type:REFRESH_ACTIVE_INSTRUMENT,payload:data};
}