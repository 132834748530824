import React, { Fragment, useRef, useState } from "react";
import {
  Table,
} from "reactstrap";
import { useDraggable } from "react-use-draggable-scroll";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

const Transactions = (props) => {
  const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
  const { events } = useDraggable(ref);
    const [note,setNote] = useState("");
  const [noteModalOpen,setNoteModalOpen] = useState(false);

  //fetch first time users
//   useEffect(() => {
//     fetchTransactionsHandler();
//   }, []);

  function changeNoteModal(note){
    setNote(note);
    setNoteModalOpen(true);
  }
  return (
    <Fragment>
        <Modal isOpen={noteModalOpen} toggle={()=>{setNoteModalOpen(false)}}>
          <ModalHeader>Note</ModalHeader>
          <ModalBody className="fw-semibold f-16">{note}</ModalBody>
          <ModalFooter><button className="btn btn-dark btn-sm" onClick={()=>{setNoteModalOpen(false)}}>Close</button></ModalFooter>
        </Modal>
        <div className="table-responsive" ref={ref} {...events}>
              <Table className="table-border-horizontal fw-semibold text-nowrap align-middle" striped>
                <thead>
                  <tr className="bg-primary">
                  <th style={{ color: "#ffffff" }}>#</th>
                      <th style={{ color: "#ffffff" }}>TID</th>
                      <th style={{ color: "#ffffff" }}>SENDER</th>
                      <th style={{ color: "#ffffff" }}>METHOD</th>
                      <th style={{ color: "#ffffff" }}>TYPE</th>
                      <th style={{ color: "#ffffff" }}>AMOUNT</th>
                      <th style={{ color: "#ffffff" }}>DATE</th>
                      <th style={{ color: "#ffffff" }}>NOTE</th>
                      <th style={{ color: "#ffffff" }}>STATUS</th>
                      <th style={{ color: "#ffffff" }}>GID</th>
                      <th style={{ color: "#ffffff" }}>UTR</th>

                  </tr>
                </thead>
                <tbody>
                  {props.transactions.length
                    ? props.transactions.map((transaction,idx) => {
                        return (
                         <tr key={transaction.id}>
                         <td>{typeIconFormatter(transaction)}</td>
                         <td>{transaction.id}</td>
                          <td>{transaction.sender_username}</td>
                          <td>{transaction.method}</td>
                          <td>{transaction.type}</td>
                          <td>{transaction.amount}</td>
                          <td>{transaction.added_on}</td>
                         <td>{(transaction.note)?<button className="btn btn-xs btn-primary" onClick={()=>{changeNoteModal(transaction.note)}}>Note</button>:<i className="icofont icofont-emo-slightly-smile f-16"></i>}</td>
                          <td>{(transaction.status==="pending")?<span className="badge  btn-sm badge-warning fw-semibold f-14">PENDING</span>:(transaction.status==="complete")?<span className="badge  btn-sm badge-primary fw-semibold f-14">COMPLETE</span>:<span className="badge btn-sm badge-danger fw-semibold f-14">CANCEL</span>}</td>
                          <td>{transaction.gateway_order_id}</td>
                          <td>{transaction.utr}</td>

                        </tr>
                        );
                      })
                    : <tr><td className="text-center" colSpan={11}>No have any transaction</td></tr>}
                </tbody>
              </Table>
              </div>
    </Fragment>
  );
};

export default Transactions;

function typeIconFormatter(row) {
  if(row.type === "deposit"){
    return (
      <div
        className="d-flex justify-content-center align-items-center text-success btn-pill f-16"
        style={{
          width: "35px",
          height: "35px",
          backgroundColor: "rgba(36,105,92,.1)",
        }}
      >
        <i className="fa fa-angle-double-left"></i>
      </div>
    );
  }else{
    return (
      <div
        className="d-flex justify-content-center align-items-center text-danger btn-pill f-16"
        style={{
          width: "35px",
          height: "35px",
          backgroundColor: "rgba(220,53,69,.1)",
        }}
      >
        <i className="fa fa-angle-double-right"></i>
      </div>
    );
  }
  
}
