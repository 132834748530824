import React, { Fragment, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  CardBody,
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Table,
} from "reactstrap";
import { H2, H4, H5 } from "../../../AbstractElements";
import { useSelector } from "react-redux";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import { FETCH_USERS_URL, DELETE_USER_URL } from "../../../_helper/url_helper";
import { useNavigate } from "react-router-dom";

const GeneralComponent = () => {
  const navigate = useNavigate();
  const loginState = useSelector((state) => state.login);
  const [data, setData] = useState([]);
  const [type, setType] = useState("all");
  const [username, setUsername] = useState("");
  const [status, setStatus] = useState("all");
  const [pagination, setPagination] = useState({ current: 1, total: 1, totalRows: 0 });
  //fetch first time users
  useEffect(() => {
    fetchUsersHandler();
  }, []);

  useEffect(() => {
    fetchUsersHandler();
  }, [username, type, status]);

  async function deleteClientHandler(userid) {
    try {
      if (!window.confirm("Areyou sure?")) {
        return;
      }
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let data = {
        // jwttoken: loginState.jwttoken,
        user_id: userid
      };

      let res = await api.post(DELETE_USER_URL, data);
      if (res.data.status === "SUCCESS") {
        fetchUsersHandler();
        toast.success("user Deleted");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  function setPage(page) {
    if (page < 1 || page > pagination.total) {
      return;
    }
    // setPagination({ ...pagination, current: page });
    fetchUsersHandler(page);
  }

  async function fetchUsersHandler(page) {
    try {
      // let data = {
      //   jwttoken: loginState.jwttoken,
      // };
      let data = {};
      if (username != "") {
        data.username = username;
      }
      if (type != "all") {
        data.type = type;
      }
      if (status != "all") {
        data.status = status;
      }
      data.page = page;
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(`${FETCH_USERS_URL}`, { params: data });
      if (res.data.status == "SUCCESS") {
        setData(res.data.payload.data);
        setPagination({ current: res.data.payload.current_page, total: res.data.payload.last_page, totalRows: res.data.payload.total });
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }


  return (
    <Fragment>
      <Container fluid={true} className="datatables">
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="row">
                  <div className="col">
                    <label className="col-form-label form-label">
                      Username
                    </label>
                    <input
                      placeholder="Username"
                      type="text"
                      className="form-control"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="col">
                    <label className="col-form-label form-label">Type</label>
                    <select
                      className="form-control"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value="all">All</option>
                      <option value="admin">Admin</option>
                      <option value="broker">Broker</option>
                      {/* <option value="staff">OfficeStaff</option> */}
                    </select>
                  </div>
                  <div className="col">
                    <label className="col-form-label form-label">Status</label>
                    <select
                      className="form-control"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="all">All</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                  <div className="col row align-items-end">
                    {username || type != "all" || status != "all" ? (
                      <button
                        className="btn btn-danger m-t-5"
                        onClick={() => {
                          setUsername("");
                          setType("all");
                          setStatus("all");
                        }}
                      >
                        Reset
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="p-b-0">
                <div className="justify-content-between row">
                  <div className="col-4 col">
                    <h5 className="m-0 p-0 d-inline-block">
                      <i className="fa fa-users"></i> Users{" "}
                    </h5>{" "}
                    <span className="badge rounded-pill badge bg-primary rounded-pill">
                      {pagination.totalRows}
                    </span>
                  </div>
                  <div className="col-4 col text-end">
                    <button
                      className="btn btn-primary"
                      onClick={() => navigate("/user/create")}
                    >
                      <i className="fa fa-plus"></i> Create
                    </button>
                  </div>
                </div>
              </CardHeader>

              <CardBody >
                <div className="table-responsive">
                  <table className="table-border-horizontal w-100 fw-semibold table-striped text-nowrap">                  <thead>
                    <tr>
                      <td>ID</td>
                      <td>NAME</td>
                      <td>USERNAME</td>
                      <td>DEPOSIT INR</td>
                      <td>DEPOSIT USD</td>
                      <td>TYPE</td>
                      <td>STATUS</td>
                      <td>ACTIONS</td>
                    </tr>
                  </thead>
                    <tbody>
                      {data.map((user) => {
                        return (
                          <>
                            <tr>
                              <td>{user.id}</td>
                              <td>{user.firstname + " " + user.lastname}</td>
                              <td>{user.username}</td>
                              <td className="text-success">{user.user_relation.deposit}</td>
                              <td className="text-success">{user.user_relation.deposit_usd}</td>
                              <td>{user.type === "admin" ? <span className="text-info">admin</span> : <span >broker</span>}</td>
                              <td>{user.status === "active" ? <span className="badge badge-success">active</span> : <span className="badge badge-danger">inactive</span>}</td>
                              <td>{actionBinder(user, navigate, deleteClientHandler)}</td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="pagination mt-3 d-flex justify-content-between align-items-center" >
                  <div>
                    <span className="text-semibold">Page {pagination.current} out of {pagination.total}</span>
                  </div>
                  <div>
                    <button className="btn btn-outline-primary" onClick={() => { setPage(pagination.current - 1) }}>Prev</button>
                    <input type="number" className="form-control d-inline mx-3 text-center fw-semibold" style={{ maxWidth: '100px' }} min="1" value={pagination.current} onChange={(e) => { setPage(e.target.value) }} />
                    {/* <span>of</span> */}
                    {/* <span id="totalPages">10</span> */}
                    <button className="btn btn-outline-primary" onClick={() => { setPage(pagination.current + 1) }}>Next</button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GeneralComponent;

function actionBinder(row, navigate, deleteClientHandler) {
  return (
    <div>
      <ul className="list-inline hstack gap-1 mb-0">
        {/* <li className="list-inline-item">
          <button
            title="view"
            className="btn btn-sm btn-primary p-0 d-flex justify-content-center align-items-center fw-semibold"
            style={{ width: "30px", height: "30px" }}
            onClick={() => { navigate(`/adminProfile/${row.id}`) }}
          >
            <i className="fa fa-eye"></i>
          </button>
        </li> */}
        <li className="list-inline-item edit">
          <button
            title="update"
            className="btn btn-sm btn-secondary p-0 d-flex justify-content-center align-items-center fw-semibold"
            style={{ width: "30px", height: "30px" }}
            onClick={() => navigate(`/user/update/${row.id}`)}
          >
            <i className="fa fa-pencil"></i>
          </button>
        </li>
        <li>
          <button
            title="Delete"
            className="btn btn-sm btn-outline-danger p-0 d-flex justify-content-center align-items-center fw-semibold"
            style={{ width: "30px", height: "30px" }}
            onClick={() => { deleteClientHandler(row.id) }}
          >
            <i className="fa fa-trash-o"></i>
          </button>
        </li>
      </ul>
    </div>
  );
}
