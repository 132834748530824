import React, { Fragment, useRef } from "react";
import {
  Table,
} from "reactstrap";
import { useDraggable } from "react-use-draggable-scroll";

const ClosedPositions = (props) => {
  const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
  const { events } = useDraggable(ref);
  return (
    <Fragment>
        <div className="table-responsive" ref={ref} {...events}>
              <Table className="table-border-horizontal fw-semibold" striped>
                <thead>
                  <tr className="bg-primary">
                  <th style={{ color: "#ffffff" }}>ID</th>
                      <th style={{ color: "#ffffff" }}>Script</th>
                      <th style={{ color: "#ffffff" }}>Exchange</th>
                      <th style={{ color: "#ffffff" }}>Qty</th>
                      <th style={{ color: "#ffffff" }}>Type</th>
                      <th style={{ color: "#ffffff" }}>BuyRate</th>
                      <th style={{ color: "#ffffff" }}>SellRate</th>
                      <th style={{ color: "#ffffff" }}>Profit/Loss</th>
                      <th style={{ color: "#ffffff" }}>Brokerage</th>
                      <th style={{ color: "#ffffff" }}>Net</th>
                      <th style={{ color: "#ffffff" }}>UsedMargin</th>
                      <th style={{ color: "#ffffff" }}>BoughtAt</th>
                      <th style={{ color: "#ffffff" }}>SoldAt</th>
                  </tr>
                </thead>
                <tbody>
                  {props.client.trades.length
                    ? props.client.trades.map((trade, idx) => {
                        return (
                          <tr key={idx}>
                             <td>{trade.id}</td>
                              <td>{trade.trading_symbol}</td>
                              <td>{trade.exchange}</td>
                              <td>{trade.qty}</td>
                              <td className={`fw-semibold ${(trade.type==="buy")?"text-success":"text-danger"}`}>{trade.type}</td>
                              <td>{trade.buy_price}</td>
                              <td>{trade.sell_price}</td>
                              <td
                                className={`${
                                  trade.profit_and_loss > 0
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {trade.profit_and_loss}
                              </td>
                              <td>{trade.brokerage}</td>
                              <td
                                className={`${
                                  Number(trade.profit_and_loss) -
                                    Number(trade.brokerage) >
                                  0
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {parseFloat(
                                  Number(trade.profit_and_loss) -
                                    Number(trade.brokerage)
                                ).toFixed(2)}
                              </td>
                              <td>{trade.used_margin}</td>
                              <td className="text-nowrap">{trade.bought_at}</td>
                              <td className="text-nowrap">{trade.added_on}</td>
                          </tr>
                        );
                      })
                    : <tr><td className="text-center" colSpan={13}>No have any trade</td></tr>}
                </tbody>
              </Table>
              </div>
    </Fragment>
  );
};

export default ClosedPositions;
