import React, { Fragment, useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import {
    CardBody,
    Card,
    CardHeader,
    Container,
    Col,
    Row,
    Collapse,
    Table,
} from "reactstrap";
import { H2, H4, H5 } from "../../../AbstractElements";
import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import { FETCH_BILL_DATE_HISTORY_URL, SEARCH_CLIENT_BY_USERNAME_URL, FETCH_BILL_PDF_URL } from "../../../_helper/url_helper";
// import { useNavigate } from "react-router-dom";
import { AsyncPaginate } from 'react-select-async-paginate';

const GeneralComponent = () => {
    // const navigate = useNavigate();
    const loginState = useSelector((state) => state.login);
    //   const [isOpen, setIsOpen] = useState(1);
    //   const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
    //   const [startDate, setstartDate] = useState(new Date());
    const [data, setData] = useState([]);
    const [client, setClient] = useState(null);

    // useEffect(() => {
    //     // fetchDataHandler();
    // }, []);

    useEffect(() => {
        if (client && client.value) {
            fetchDataHandler();
        } else {
            setData([]);
        }
    }, [client]);

    const loadOptionsClient = async (inputValue) => {
        let data = [];
        data = await fetchUnderClientsHandler(inputValue);
        return { options: data };
    }

    async function fetchUnderClientsHandler(username) {
        try {
            // let data = {
            //   jwttoken: loginState.jwttoken,
            // };
            let data = {
                username: username
            }
            api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
            let res = await api.get(SEARCH_CLIENT_BY_USERNAME_URL, { params: data });
            if (res.data.status === "SUCCESS") {
                let temp = [];
                temp = res.data.payload.map((user) => {
                    return { value: user.id, label: user.username };
                });
                // setUnderClientOptions(temp);
                return temp;
            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    }

    async function fetchDataHandler() {
        try {
            let data = {};
            if (client && client.value) {
                data.client_id = client.value;
            } else {
                return;
            }
            // let data = {
            //   jwttoken: loginState.jwttoken,
            // };
            api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
            let res = await api.post(FETCH_BILL_DATE_HISTORY_URL, data);
            if (res.data.status === "SUCCESS") {
                setData(res.data.payload);
            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    }

    function fetchPdf(bill) {
        try {
            api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
            api.get('https://appalphastock.bullex.in/api/user/bill/getBill', {
                params: {
                    client_id: client.value,
                    last_bill_date: bill.generaton_date,
                    second_last_bill_date: bill.previous_date
                },
                responseType: 'arraybuffer' // Ensure the response is treated as binary data
            })
                .then(function (response) {
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    const url = window.URL.createObjectURL(blob);
                    window.open(url, '_blank');
                })
        } catch (error) {
            toast.error(error.message);
        }
    }

    // async function updateDataHandler() {
    //     try {
    //         // let data = {
    //         //   jwttoken: loginState.jwttoken,
    //         // };
    //         api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
    //         let res = await api.post(UPDATE_CURRENCY_RATE_URL, data);
    //         if (res.data.status === "SUCCESS") {
    //             toast.success("Rate updated successfully");
    //         } else {
    //             toast.error(res.data.message);
    //         }
    //     } catch (error) {
    //         toast.error(error.message);
    //     }
    // }

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            {/* <CardBody> */}
                            <CardHeader className="py-3">
                                <div className="justify-content-between row align-items-center">
                                    <div className="col-4 col">
                                        <h5 className="m-0 p-0 d-inline-block">
                                            <i className="fa fa-users"></i> Bill Master{" "}
                                        </h5>{" "}
                                        {/* <span className="badge rounded-pill badge bg-primary rounded-pill">
                                            {pagination.totalRows}
                                        </span> */}
                                    </div>
                                    <div className="col-4 col ">
                                        {/* <button
                                            className="btn btn-primary"
                                            onClick={() => { updateDataHandler() }}
                                        // onClick={() => navigate("/client/create")}
                                        >
                                            Update
                                        </button> */}
                                        <div className="text-dark">
                                            <AsyncPaginate
                                                className="js-example-basic-single col-sm-12"
                                                value={client}
                                                isClearable={true}
                                                loadOptions={loadOptionsClient}
                                                // onChange={setValue}
                                                onChange={(e) => { setClient(e) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </CardHeader>
                            <div className="table-responsive">
                                <Table className="table-border-horizontal align-middle fw-semibold text-nowrap" striped>
                                    <thead>
                                        <tr className="bg-primary">
                                            <th style={{ color: "#ffffff" }}>#</th>
                                            <th style={{ color: "#ffffff" }}>Previous date</th>
                                            <th style={{ color: "#ffffff" }}>Generation date</th>
                                            <th style={{ color: "#ffffff" }}>Trades</th>
                                            <th style={{ color: "#ffffff" }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.length
                                            ? data.map((bill, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{idx + 1}</td>
                                                        <td>{bill.previous_date}</td>
                                                        <td>{bill.generaton_date}</td>
                                                        <td>{bill.trades_count}</td>
                                                        <td><button className="btn btn-sm btn-danger" onClick={() => { fetchPdf(bill); }}>PDF</button></td>
                                                    </tr>
                                                );
                                            })
                                            : ""}

                                    </tbody>
                                </Table>
                            </div>
                            {/* </CardBody> */}
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default GeneralComponent;
