import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
  Input,
  Label,
  Collapse,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { closeOrderWindow, changeOrderType } from "../../../store/Order/action";
import { Accordion } from "reactstrap";
import Select from "react-select";
import {
  PUSH_CLIENT_ORDER_URL,
  SEARCH_CLIENT_BY_USERNAME_URL,
} from "../../../_helper/url_helper";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import { AsyncPaginate } from 'react-select-async-paginate';

export default function CreateOrderModel() {
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const orderState = useSelector((state) => state.order);
  const loginState = useSelector((state) => state.login);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [qty, setQty] = useState(1);
  const [price, setPrice] = useState(1);
  const [orderType, setOrderType] = useState("market");
  const [bidPrice, setBidPrice] = useState(0);
  const [askPrice, setAskPrice] = useState(0);
  const [margin, setMargin] = useState({});
  const [localInstrument, setLocalInstrument] = useState({});
  const [underCientOptions, setUnderClientOptions] = useState([]);
  const [client, setClient] = useState(null);

  useEffect(() => {
    if (orderState.status === false) {
      setOrderType("market");
      setClient(null);
    }
  }, [orderState.status]);

  useEffect(() => {
    setBidPrice(orderState.instrument.bid_price);
    setAskPrice(orderState.instrument.ask_price);
  }, [orderState.instrument]);

  useEffect(() => {
    if (
      (localInstrument.token === undefined &&
        orderState.instrument.token !== undefined) ||
      orderState.instrument.token !== localInstrument.token
    ) {
      setLocalInstrument(orderState.instrument);
      setQty(Math.floor(orderState.instrument.lotsize));
      //  marginHandler();
    }
  }, [orderState.instrument]);

  // useEffect(() => {
  //   fetchUnderClientsHandler();
  // }, []);

  function instrumentQuantityHandler(actionType) {
    switch (actionType) {
      case "increment":
        setQty(Number(qty) + 1);
        break;
      case "decrement":
        if (Number(qty) > 1) {
          setQty(Number(qty) - 1);
        }
        break;
    }
  }
  const loadOptionsClient = async (inputValue) => {
    let data = [];
    data = await fetchUnderClientsHandler(inputValue);
    return { options: data };
  }

  async function fetchUnderClientsHandler(username) {
    try {
      // let data = {
      //   jwttoken: loginState.jwttoken,
      // };
      let data = {
        username: username
      }
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(SEARCH_CLIENT_BY_USERNAME_URL, { params: data });
      if (res.data.status === "SUCCESS") {
        let temp = [];
        temp = res.data.payload.map((user) => {
          return { value: user.id, label: user.username };
        });
        setUnderClientOptions(temp);
        return temp;
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function pushClientOrder() {
    try {
      if (orderType != "market" && (price === "" || price < 2)) {
        toast.error("Price should be greater than 2");
        return;
      }
      if (client === null) {
        toast.error("Client required");
        return;
      }

      let data = {
        // jwttoken: loginState.jwttoken,
        client_id: client.value,
        token: orderState.instrument.token,
        type: orderState.type,
        order_type: orderType,
        qty: qty,
        price: orderType === "market" ? 2222 : price,
      };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.post(PUSH_CLIENT_ORDER_URL, data);
      if (res.data.status === "SUCCESS") {
        toast.success("Order Placed");
        dispatch(closeOrderWindow());
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  }
  return (
    <Modal
      isOpen={orderState.status}
      toggle={() => {
        dispatch(closeOrderWindow());
      }}
    >
      <ModalHeader>
        <p className="text-center f-16 fw-semibold">
          NEW ORDER - {orderState.instrument.trading_symbol}{" "}
          <small className="text-muted">{orderState.instrument.exchange}</small>{" "}
          x{" "}
          <span className="badge rounded-pill pill-badge-danger badge bg-danger">
            {orderState.instrument.lotsize}
          </span>
        </p>
      </ModalHeader>
      <ModalBody>
        <div className="row mb-4">
          <div className="col">
            <button
              className={
                "btn btn-sm w-100 " +
                (orderState.type === "buy"
                  ? "btn-primary"
                  : "btn-outline-primary")
              }
              onClick={() => {
                dispatch(changeOrderType("buy"));
              }}
            >
              Buy
              <br />@{askPrice}
            </button>
          </div>
          <div className="col">
            <button
              className={
                "btn btn-sm w-100 " +
                (orderState.type === "sell"
                  ? "btn-danger"
                  : "btn-outline-danger")
              }
              onClick={() => {
                dispatch(changeOrderType("sell"));
              }}
            >
              Sell
              <br />@{bidPrice}
            </button>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col">
            <label className="text-center d-block"> client</label>
            {/* <Select
              options={underCientOptions}
              className="js-example-basic-single col-sm-12"
              value={client}
              isClearable={true}
              isSearchable={true}
              onChange={(e) => {
                setClient(e);
              }}
            />{" "} */}
            <AsyncPaginate
              className="js-example-basic-single col-sm-12"
              value={client}
              isClearable={true}
              loadOptions={loadOptionsClient}
              // onChange={setValue}
              onChange={(e) => { setClient(e) }}
            />
          </div>
        </div>

        <div className="row justify-content-between mb-5">
          <label className="col-12 mb-1 text-center d-block">Qty</label>
          <div className="col" style={{ width: "20px" }}>
            <button
              className="btn btn-light btn-pill"
              onClick={() => {
                instrumentQuantityHandler("decrement");
              }}
            >
              <i className="fa fa-minus"></i>
            </button>
          </div>
          <div className="col-8">
            <input
              type="number"
              value={qty}
              onChange={(e) => {
                if (
                  (e.target.value > 0 &&
                    Number.isInteger(Number(e.target.value))) ||
                  e.target.value === ""
                ) {
                  setQty(e.target.value);
                }
              }}
              className="form-control text-center btn-pill"
              placeholder="Enter Qty"
            />
          </div>
          <div className="col">
            <button
              className="btn btn-light btn-pill"
              onClick={() => {
                instrumentQuantityHandler("increment");
              }}
            >
              <i className="fa fa-plus"></i>
            </button>
          </div>
        </div>

        <div className="mb-4">
          <div role="group" className=" btn-group d-flex">
            <button
              className={`btn btn${orderType !== "market" ? "-outline" : ""}-${orderState.type === "buy" ? "primary" : "danger"
                } btn-lg`}
              onClick={() => {
                setOrderType("market");
              }}
            >
              Market
            </button>
            <button
              className={`btn btn${orderType !== "limit" ? "-outline" : ""}-${orderState.type === "buy" ? "primary" : "danger"
                } btn-lg`}
              onClick={() => {
                setOrderType("limit");
              }}
            >
              Limit
            </button>
            <button
              className={`btn btn${orderType !== "force" ? "-outline" : ""
                }-${orderState.type === "buy" ? "primary" : "danger"} btn-lg`}
              onClick={() => {
                setOrderType("force");
              }}
            >
              Force
            </button>
          </div>
        </div>

        <Accordion defaultActiveKey="0">
          <div className="default-according" id="accordion1">
            <Collapse isOpen={orderType !== "market"}>
              <div className="row justify-content-between mb-4">
                <p className="col-12 mb-1 text-center">Price</p>
                <div className="col" style={{ width: "20px" }}>
                  <button
                    className="btn btn-light btn-pill"
                    onClick={() => {
                      if (price > 2) {
                        setPrice(price - 1);
                      }
                    }}
                  >
                    <i className="fa fa-minus"></i>
                  </button>
                </div>
                <div className="col-8">
                  <input
                    type="number"
                    className="form-control text-center fw-semibold btn-pill"
                    value={price}
                    onChange={(e) => {
                      if (e.target.value === "" || e.target.value > 0) {
                        setPrice(e.target.value);
                      }
                    }}
                    placeholder="Enter Price"
                  />
                </div>
                <div className="col">
                  <button
                    className="btn btn-dark btn-pill"
                    onClick={() => {
                      setPrice(price + 1);
                    }}
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                </div>
              </div>
            </Collapse>
          </div>
        </Accordion>

        <div className="row justify-content-between align-items-center">
          <div className="col"></div>
          <div className="col text-end">
            <SubmitButton
              orderState={orderState}
              pushClientOrder={pushClientOrder}
            />
            <button
              className="btn btn-outline-secondary btn-sm"
              onClick={() => {
                dispatch(closeOrderWindow());
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

function SubmitButton(props) {
  if (props.orderState.type === "buy") {
    return (
      <button
        className="btn btn-primary mx-3 btn-sm"
        onClick={() => {
          props.pushClientOrder();
        }}
      >
        Buy
      </button>
    );
  } else {
    return (
      <button
        className="btn btn-danger mx-3 btn-sm"
        onClick={() => {
          props.pushClientOrder();
        }}
      >
        Sell
      </button>
    );
  }
}
