import React, { Fragment, useState, useContext, useEffect } from 'react';
import { LI } from '../../../AbstractElements';
import ConfigDB from '../../../Config/ThemeConfig';
import CustomizerContext from '../../../_helper/Customizer';

const MoonLight = () => {
    const { addMixBackgroundLayout } = useContext(CustomizerContext);
    const localStorageLayout = localStorage.getItem('layout_type') || ConfigDB.data.settings.layout_type;

    const storedMoonLightStatus = localStorage.getItem('moonlight') === 'true';
    const [moonlight, setMoonlight] = useState(storedMoonLightStatus);

    const toggleMode = () => {
        const newMoonlight = !moonlight;
        setMoonlight(newMoonlight);
        localStorage.setItem('moonlight', newMoonlight.toString());
    };

    useEffect(() => {
        const updateBodyClasses = () => {
            if (moonlight) {
                document.body.classList.add("light-only");
                document.body.classList.remove("dark-only");
            } else {
                document.body.classList.remove("light-only");
                document.body.classList.add("dark-only");
            }
            document.body.classList.add(localStorageLayout);
        };

        // Update body classes based on moonlight state and layout on mount
        addMixBackgroundLayout(moonlight ? 'light-only' : 'dark-only');
        updateBodyClasses();

        // Cleanup function to remove body class on unmount or state change
        return () => {
            document.body.classList.remove("light-only", "dark-only", localStorageLayout);
        };
    }, [moonlight, localStorageLayout, addMixBackgroundLayout]);

    useEffect(() => {
        // Initialize moonlight state and layout on mount
        setMoonlight(storedMoonLightStatus);
    }, [storedMoonLightStatus]);

    return (
        <Fragment>
            <LI>
                <div className="mode" onClick={toggleMode}>
                    {moonlight ? <i className="fa fa-lightbulb-o"></i> : <i className="fa fa-moon-o"></i>}
                </div>
            </LI>
        </Fragment>
    );
};

export default MoonLight;
