import React, { useEffect } from "react";
import Routers from "./Route";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/Customizer/CustomizerProvider";
import { useSelector, useDispatch } from "react-redux";
import { fetchOwner } from "./store/login/action";

const App = () => {
  const loginState = useSelector((state) => state.login);
  const dispatch = useDispatch();
  useEffect(() => {
    if (loginState.jwttoken) {
      let data = {
        jwttoken: loginState.jwttoken,
      };
      dispatch(fetchOwner(data));
    }
  }, []);
  return (
    <div className="App">
      <CustomizerProvider>
        <AnimationThemeProvider>
          <Routers />
        </AnimationThemeProvider>
      </CustomizerProvider>
    </div>
  );
};

export default App;
