import { UPDATE_INSTRUMENTS } from "./actionTypes";
let initialState = {};
const market = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_INSTRUMENTS:
      return action.payload;
    default:
      return state;
  }
};
export default market;
