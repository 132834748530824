import React, { Fragment, useEffect, useState } from "react";
import {
  CardBody,
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Collapse,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
} from "reactstrap";
import Select from "react-select";
import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import {
  FETCH_SYMBOLS_URL,
  BLOCK_SCRIPT_URL,
  UNBLOCK_SCRIPTS_URL,
  FETCH_BLOCK_SCRIPTS_URL
} from "../../../_helper/url_helper";

const GeneralComponent = () => {
  const loginState = useSelector((state) => state.login);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  const [data, setData] = useState([]);
  const [exchange, setExchange] = useState("MCX");
  const [symbol, setSymbol] = useState(null);
  const [marginType, setMarginType] = useState("multiply");
  const [marginMis, setMarginMis] = useState("");
  const [marginCnc, setMarginCnc] = useState("");
  const [symbols, setSymbols] = useState({ MCX: [], CDS: [], NFO: [], FX: [], CRYPTO: [], US: [] });
  const [createModalStatus, setCreateModalStatus] = useState(false);
  // const [refreshMargin,setRefreshMargin] = useState(true);
  const [symbolFilter, setSymbolFilter] = useState("");
  const [exchangeFilter, setExchangeFilter] = useState("all");

  useEffect(() => {
    getDataHandler();
    fetchSymbolsHandler();
  }, []);

  useEffect(() => {
    if (createModalStatus === false) {
      setSymbol(null);
      setMarginMis("");
      setExchange("MCX");
      setMarginType("multiply");
      setMarginCnc("");
    }
  }, [createModalStatus]);

  useEffect(() => {
    getDataHandler();
  }, [symbolFilter, exchangeFilter]);

  useEffect(() => {
    setSymbol(null);
  }, [exchange]);

  useEffect(() => {
    setMarginMis("");
    setMarginCnc("");
  }, [marginType]);

  // function setCloneModal(script){
  //   setRefreshMargin(false);
  //   setUpdateMarginId(script.id);
  //   setExchange(script.exchange);
  //   setSymbol({value:script.symbol,label:`${script.exchange}::${script.symbol}`});
  //   setMarginType(script.margin_type);
  //   setMarginMis(script.margin_mis);
  //   setMarginCnc(script.margin_cnc);
  //   setUpdateModalStatus(true);
  //   setCreateModalStatus(true);
  // }

  function isInt(value) {
    if (isNaN(value)) {
      return false;
    }
    var x = parseFloat(value);
    return (x | 0) === x;
  }

  function changeMarginHandler(value, setFun) {
    if (marginType === "multiply") {
      if ((isInt(value) && value > 0) || value === "") {
        setFun(value);
      }
    } else {
      if (value > 0 || value === "") {
        setFun(value);
      }
    }
  }

  async function getDataHandler() {
    try {
      // let data = {
      //   jwttoken: loginState.jwttoken,
      // };
      // if (symbolFilter !== "") {
      //   data.symbol = symbolFilter;
      // }
      // if (exchangeFilter !== "all") {
      //   data.exchange = exchangeFilter;
      // }
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_BLOCK_SCRIPTS_URL);
      if (res.data.status === "SUCCESS") {
        setData(res.data.payload);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function blockScriptHandler() {
    try {
      if (symbol === null) {
        toast.error(`${exchange} Symbol required`);
        return;
      }

      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;

      let data = {
        // jwttoken: loginState.jwttoken,
        symbol: symbol.value,
        // exchange: exchange,
      };
      let res = await api.post(BLOCK_SCRIPT_URL, data);
      if (res.data.status === "SUCCESS") {
        toast.success(`${symbol.label} Blocked`);
        setCreateModalStatus(false);
        getDataHandler();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }


  async function fetchSymbolsHandler() {
    try {
      // let data = {
      //   jwttoken: loginState.jwttoken,
      // };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_SYMBOLS_URL);
      if (res.data.status === "SUCCESS") {
        //setup mcx data
        let temp = {};
        temp["MCX"] = res.data.payload["MCX"].length
          ? res.data.payload["MCX"].map((symbol) => ({
            value: symbol["symbol"],
            label: `${symbol["exchange"]}::${symbol["symbol"]}`,
          }))
          : [];
        temp["NFO"] = res.data.payload["NFO"].length
          ? res.data.payload["NFO"].map((symbol) => ({
            value: symbol["symbol"],
            label: `${symbol["exchange"]}::${symbol["symbol"]}`,
          }))
          : [];
        temp["CDS"] = res.data.payload["CDS"].length
          ? res.data.payload["CDS"].map((symbol) => ({
            value: symbol["symbol"],
            label: `${symbol["exchange"]}::${symbol["symbol"]}`,
          }))
          : [];

        temp["FX"] = res.data.payload["FX"].length
          ? res.data.payload["FX"].map((symbol) => ({
            value: symbol["symbol"],
            label: `${symbol["exchange"]}::${symbol["symbol"]}`,
          }))
          : [];

        temp["CRYPTO"] = res.data.payload["CRYPTO"].length
          ? res.data.payload["CRYPTO"].map((symbol) => ({
            value: symbol["symbol"],
            label: `${symbol["exchange"]}::${symbol["symbol"]}`,
          }))
          : [];

        temp["US"] = res.data.payload["US"].length
          ? res.data.payload["US"].map((symbol) => ({
            value: symbol["symbol"],
            label: `${symbol["exchange"]}::${symbol["symbol"]}`,
          }))
          : [];
        setSymbols(temp);

      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function unBlockScriptHandler(script) {
    try {
      if (!window.confirm("Are you sure?")) {
        return;
      }
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let data = {
        // jwttoken: loginState.jwttoken,
        symbol: script.symbol
      };
      let res = await api.post(UNBLOCK_SCRIPTS_URL, data);
      if (res.data.status === "SUCCESS") {
        getDataHandler();
        toast.success(`${script.symbol} unblocked`);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Fragment>
      <Container fluid={true}>
        <Modal
          isOpen={createModalStatus}
          toggle={() => {
            setCreateModalStatus(false);
          }}
        >
          <ModalHeader>BLOCK Script</ModalHeader>
          <ModalBody>
            <Row className="mb-4">
              <Col>
                <button
                  className={`btn ${exchange === "MCX" ? "btn-primary" : "btn-outline-primary"
                    }`}
                  onClick={() => {
                    setExchange("MCX");
                  }}
                >
                  MCX
                </button>
                <button
                  className={`mx-3 btn ${exchange === "NFO" ? "btn-primary" : "btn-outline-primary"
                    }`}
                  onClick={() => {
                    setExchange("NFO");
                  }}
                >
                  NFO
                </button>
                <button
                  className={`btn ${exchange === "CDS" ? "btn-primary" : "btn-outline-primary"
                    }`}
                  onClick={() => {
                    setExchange("CDS");
                  }}
                >
                  CDS
                </button>

                <button
                  className={`mx-3 btn ${exchange === "FX" ? "btn-primary" : "btn-outline-primary"
                    }`}
                  onClick={() => {
                    setExchange("FX");
                  }}
                >
                  FX
                </button>

                <button
                  className={`mt-3 btn ${exchange === "CRYPTO" ? "btn-primary" : "btn-outline-primary"
                    }`}
                  onClick={() => {
                    setExchange("CRYPTO");
                  }}
                >
                  CRYPTO
                </button>

                <button
                  className={`mx-3 mt-3 btn ${exchange === "US" ? "btn-primary" : "btn-outline-primary"
                    }`}
                  onClick={() => {
                    setExchange("US");
                  }}
                >
                  US
                </button>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col>
                <label>Symbol</label>
                <Select
                  options={symbols[exchange]}
                  className="js-example-basic-single col-sm-12"
                  value={symbol}
                  isClearable={true}
                  isSearchable={true}
                  onChange={(e) => {
                    setSymbol(e);
                  }}
                />
              </Col>
            </Row>

          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-danger mx-3"
              onClick={blockScriptHandler}
            >
              BLOCK
            </button>
            <button
              className="btn btn-outline-secondary"
              onClick={() => {
                setCreateModalStatus(false);
              }}
            >
              Cancel
            </button>
          </ModalFooter>
        </Modal>
        {/* <Row>
          <Col sm="12">
            <Accordion defaultActiveKey="0">
              <div className="default-according" id="accordion1">
                <Card className="mb-4">
                  <CardHeader
                    className="bg-secondary"
                    onClick={() => toggle(1)}
                  >
                    <h5 className="d-flex justify-content-between">
                      <span>Filters</span>
                      {isOpen === 1 ? (
                        <i className="icofont icofont-rounded-up"></i>
                      ) : (
                        <i className="icofont icofont-rounded-down"></i>
                      )}
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={isOpen === 1}>
                    <CardBody>
                      <Row className="row">
                        <Col className="col" md={4}>
                          <label className="col-form-label form-label">
                            Symbol
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter symbol"
                            value={symbolFilter}
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                /^[a-zA-Z]+$/.test(e.target.value)
                              ) {
                                setSymbolFilter(e.target.value);
                              }
                            }}
                          />
                        </Col>

                        <Col className="col" md={4}>
                          <label className="col-form-label form-label">
                            Exchange
                          </label>
                          <select
                            className="form-control"
                            value={exchangeFilter}
                            onChange={(e) => {
                              setExchangeFilter(e.target.value);
                            }}
                          >
                            <option value="all">All</option>
                            <option value="MCX">MCX</option>
                            <option value="NFO">NFO</option>
                            <option value="CDS">CDS</option>
                          </select>
                        </Col>

                        <div className="col row align-items-end">
                          <div>
                            {symbolFilter !== "" || exchangeFilter !== "all" ? (
                              <button
                                className="btn btn-danger mx-4 m-t-5"
                                onClick={() => {
                                  setSymbolFilter("");
                                  setExchangeFilter("all");
                                }}
                              >
                                Reset
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </div>
            </Accordion>
          </Col>
        </Row> */}

        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="p-b-0 mb-3 pt-4">
                <div className="justify-content-between row align-items-center">
                  <div className="col-4 col">
                    <h5 className="m-0 p-0 d-inline-block">
                      <i className="fa fa-users"></i> Blocked Scripts{" "}
                    </h5>{" "}
                    <span className="badge rounded-pill badge bg-primary rounded-pill">
                      {/* {data.length} */}
                    </span>
                  </div>
                  <div className="col-4 col text-end">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        setCreateModalStatus(true);
                      }}
                    >
                      <i className="fa fa-plus"></i> Create
                    </button>
                  </div>
                </div>
              </CardHeader>
              <Table className="table-border-horizontal align-middle" striped>
                <thead>
                  <tr className="bg-primary">
                    <th style={{ color: "#ffffff" }}>#</th>
                    <th style={{ color: "#ffffff" }}>ID</th>
                    <th style={{ color: "#ffffff" }}>Symbol</th>
                    <th style={{ color: "#ffffff" }}>Action</th>
                  </tr>
                </thead>
                <tbody className="fw-semibold">
                  {data.length ? (
                    data.map((script, idx) => {
                      return (
                        <tr key={script.id}>
                          <td>{idx + 1}</td>
                          <td>{script.id}</td>
                          <td>{script.symbol}</td>
                          <td>{actionBinder(script, unBlockScriptHandler)}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4} className="text-center">
                        No Scripts found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GeneralComponent;

function actionBinder(script, unBlockScriptHandler) {
  return (
    <div>
      <ul className="list-inline hstack gap-1 mb-0">
        <li>
          <button
            title="Delete"
            className="btn btn-sm btn-outline-danger p-0 d-flex justify-content-center align-items-center fw-semibold"
            style={{ width: "30px", height: "30px" }}
            onClick={() => { unBlockScriptHandler(script) }}
          >
            <i className="fa fa-trash-o"></i>
          </button>
        </li>
      </ul>
    </div>
  );
}
