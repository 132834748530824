import React, { Fragment, useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import {
  CardBody,
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Collapse,
  Table,
} from "reactstrap";
import { H2, H4, H5 } from "../../../AbstractElements";
import { Accordion } from "react-bootstrap";
import {
  FETCH_POSITIONS_URL,
  FETCH_CURRENCY_RATE_URL
} from "../../../_helper/url_helper";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import api from "../../../_helper/api_helper";
import { useDraggable } from "react-use-draggable-scroll";
import { CSVLink } from "react-csv";
import io from 'socket.io-client';
import CreatePositionModal from "./CreatePositionModal";

const GeneralComponent = () => {
  const loginState = useSelector((state) => state.login);
  const [isOpen, setIsOpen] = useState(1);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  const [startDate, setstartDate] = useState(new Date());
  const [currencyRates, setCurrencyRates] = useState({});
  // const [endDate, setEndDate] = useState(new Date());
  const [positions, setPositions] = useState([]);
  const [changablePositions, setChangeablePositions] = useState([]);
  const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
  const { events } = useDraggable(ref);
  const [username, setUsername] = useState("");
  const usernameRef = useRef(0);
  usernameRef.current = username;
  const [script, setScript] = useState("");
  const scriptRef = useRef(0);
  scriptRef.current = script;
  const [exchange, setExchange] = useState("all");
  const exchangeRef = useRef(0);
  exchangeRef.current = exchange;
  const [pagination, setPagination] = useState({ current: 1, total: 1, totalRows: 0 });
  const [createPositionModalStatus, setCreatePositionModalStatus] = useState(false);

  useEffect(() => {
    fetchCurrencyandler();
    fetchPositionsHandler();
  }, []);

  useEffect(() => {
    if (createPositionModalStatus === false) {
      fetchPositionsHandler();
    }
  }, [createPositionModalStatus]);

  function setPage(page) {
    if (page < 1 || page > pagination.total) {
      return;
    }
    // setPagination({ ...pagination, current: page });
    fetchPositionsHandler(page);
  }

  async function fetchPositionsHandler(page = 1) {
    try {
      // let data = {
      //   jwttoken: loginState.jwttoken,
      // };
      let data = {};
      if (usernameRef.current !== "") {
        data["username"] = usernameRef.current;
      }
      if (scriptRef.current !== "") {
        data["script"] = scriptRef.current;
      }
      if (exchangeRef.current !== "all") {
        data["exchange"] = exchangeRef.current;
      }

      data["page"] = page;
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_POSITIONS_URL, { params: data });
      if (res.data.status === "SUCCESS") {
        if (res.data.payload.data !== undefined) {
          setChangeablePositions(res.data.payload.data);
          setPositions(res.data.payload.data);
          setPagination({ current: res.data.payload.current_page, total: res.data.payload.last_page, totalRows: res.data.payload.total });
        } else {
          setPositions([]);
          setChangeablePositions([]);
        }
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }


  async function fetchCurrencyandler() {
    try {
      // let data = {
      //   jwttoken: loginState.jwttoken,
      // };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_CURRENCY_RATE_URL);
      if (res.data.status === "SUCCESS") {
        setCurrencyRates(res.data.payload);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }


  useEffect(() => {
    // setChangeablePositions(positions);
    // let tempArr = JSON.parse(JSON.stringify(positions));
    // const socket = io('https://ws2.tradeyarr.com');
    const socket = io('https://ws2alphastock.bullex.in');
    // const socket = io('https://ws2.bullex.in');
    socket.on('connect', () => {
      positions.forEach((position) => {
        socket.emit('tokenData', position.token);
      });
    });

    positions.forEach((position, idx) => {
      socket.on(`room${position.token}`, (data) => {
        let tempArr = [...changablePositions];
        // console.log(data);
        if (data.token !== undefined) {
          let price = 0;
          if (tempArr[idx].type === "buy") {
            price = (data.bid_price > 0) ? data.bid_price : data.ltp;
            tempArr[idx].active_profit_and_loss = (price - tempArr[idx].avg_price) * tempArr[idx].qty;
          } else {
            price = (data.ask_price > 0) ? data.ask_price : data.ltp;
            tempArr[idx].active_profit_and_loss = (tempArr[idx].avg_price - price) * tempArr[idx].qty;
          }
          if (tempArr[idx].currency === "USD") {
            tempArr[idx].active_profit_and_loss *= currencyRates[tempArr[idx].base_currency];
          }
          // console.log(tempArr[idx].active_profit_and_loss);
          setChangeablePositions(tempArr);
        }
      });
    });

    return () => {
      socket.disconnect();
    };
  }, [positions]);


  return (
    <Fragment>
      <Container fluid={true}>
        <CreatePositionModal createPositionModalStatus={createPositionModalStatus} setCreatePositionModalStatus={setCreatePositionModalStatus} />
        <Row>
          <Col sm="12">
            <Accordion defaultActiveKey="0">
              <div className="default-according" id="accordion1">
                <Card className="mb-4">
                  <CardHeader
                    className="bg-secondary"
                    onClick={() => toggle(1)}
                  >
                    <h5 className="d-flex justify-content-between">
                      <span>Filters</span>
                      {isOpen === 1 ? (
                        <i className="icofont icofont-rounded-up"></i>
                      ) : (
                        <i className="icofont icofont-rounded-down"></i>
                      )}
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={isOpen === 1}>
                    <CardBody>
                      <Row className="row">
                        <Col className="col mb-3" md={4}>
                          <label className="col-form-label form-label">
                            Username
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter username"
                            value={username}
                            onChange={(e) => {
                              setUsername(e.target.value);
                            }}
                          />
                        </Col>

                        <Col className="col" md={4}>
                          <label className="col-form-label form-label">
                            Script
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter script"
                            value={script}
                            onChange={(e) => {
                              setScript(e.target.value);
                            }}
                          />
                        </Col>

                        <Col className="col" md={4}>
                          <label className="col-form-label form-label">
                            Exchange
                          </label>
                          <select
                            className="form-control"
                            value={exchange}
                            onChange={(e) => {
                              setExchange(e.target.value);
                            }}
                          >
                            <option value="all">All</option>
                            <option value="MCX">MCX</option>
                            <option value="NFO">NFO</option>
                            <option value="CDS">CDS</option>
                            <option value="FX">FX</option>
                            <option value="CRYPTO">CRYPTO</option>
                            <option value="US">US</option>
                          </select>
                        </Col>



                        <div className="col row align-items-end">
                          <div>
                            <button
                              className="btn btn-primary m-t-5"
                              onClick={() => { fetchPositionsHandler(pagination.current) }}
                            >
                              Search
                            </button>

                            {/* {(username !== "" || script !== "" || exchange !== "all") && <button className="btn btn-danger mx-4 m-t-5" onClick={()=>{setUsername(""); setScript(""); setExchange(""); fetchTradesHandler();}}>
                              Reset
                            </button>} */}
                          </div>
                        </div>
                      </Row>
                    </CardBody>
                  </Collapse>
                </Card>
              </div>
            </Accordion>
          </Col>
        </Row>


        <Row>
          <Col sm="12">
            <Card>
              {/* <CardBody> */}
              <CardHeader className="py-3">
                <div className="d-flex justify-content-between align-items-center">
                  <h5>Positions</h5>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setCreatePositionModalStatus(true);
                    }}
                  >
                    Create
                  </button>
                </div>
              </CardHeader>
              <div className="table-responsive" ref={ref} {...events}>
                <Table className="table-border-horizontal fw-semibold align-middle" striped>
                  <thead>
                    <tr className="bg-primary">
                      <th style={{ color: "#ffffff" }}>PID</th>
                      <th style={{ color: "#ffffff" }}>Instrument</th>
                      <th style={{ color: "#ffffff" }}>Exchnage</th>
                      <th style={{ color: "#ffffff" }}>Client</th>
                      <th style={{ color: "#ffffff" }}>Type</th>
                      <th style={{ color: "#ffffff" }}>Qty</th>
                      <th style={{ color: "#ffffff" }}>MisQty</th>
                      <th style={{ color: "#ffffff" }}>P&L</th>
                      <th style={{ color: "#ffffff" }}>Avg</th>
                      <th style={{ color: "#ffffff" }}>Currency</th>
                      <th style={{ color: "#ffffff" }}>UsedMargin</th>
                      <th style={{ color: "#ffffff" }}>AddedOn</th>

                    </tr>
                  </thead>
                  <tbody>
                    {changablePositions.length
                      ? changablePositions.map((position, idx) => {
                        return (
                          <tr key={position.id}>
                            <td>{position.id}</td>
                            <td>{position.trading_symbol}</td>
                            <td>{position.exchange}</td>
                            <td>{position.client_name}</td>
                            <td className={`fw-semibold ${(position.type === "buy") ? "text-success" : "text-danger"}`}>{position.type}</td>
                            <td>{position.qty}</td>
                            <td>{position.mis_qty}</td>
                            <td
                              className={`${position.active_profit_and_loss > 0
                                ? "text-success"
                                : "text-danger"
                                }`}
                            >
                              {parseFloat(position.active_profit_and_loss).toFixed(2)}
                            </td>
                            <td>{position.avg_price}</td>
                            <td>{position.currency}</td>
                            <td>{position.used_margin}</td>
                            <td className="text-nowrap">{position.added_on}</td>
                          </tr>
                        );
                      })
                      : ""}
                  </tbody>
                </Table>
              </div>
              <div className="pagination mt-3 d-flex justify-content-between align-items-center mb-3 px-3" >
                <div>
                  <span className="text-semibold">Page {pagination.current} out of {pagination.total}</span>
                </div>
                <div>
                  <button className="btn btn-outline-primary" onClick={() => { setPage(pagination.current - 1) }}>Prev</button>
                  <input type="number" className="form-control d-inline mx-3 text-center fw-semibold" style={{ maxWidth: '100px' }} min="1" value={pagination.current} onChange={(e) => { setPage(e.target.value) }} />
                  {/* <span>of</span> */}
                  {/* <span id="totalPages">10</span> */}
                  <button className="btn btn-outline-primary" onClick={() => { setPage(pagination.current + 1) }}>Next</button>
                </div>
              </div>
              {/* </CardBody> */}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GeneralComponent;



function actionBinder(trade, deleteTradesHandler, setModifyTradeModal) {
  return (
    <div>
      <ul className="list-inline hstack gap-1 mb-0">
        <li className="list-inline-item edit">
          <button
            title="update"
            className="btn btn-sm btn-secondary p-0 d-flex justify-content-center align-items-center fw-semibold"
            style={{ width: "30px", height: "30px" }}
            onClick={() => { setModifyTradeModal(trade.id, trade.buy_price, trade.sell_price, trade.qty, trade.brokerage); }}
          >
            <i className="fa fa-pencil"></i>
          </button>
        </li>
        <li>
          <button
            title="Delete"
            className="btn btn-sm btn-outline-danger p-0 d-flex justify-content-center align-items-center fw-semibold"
            style={{ width: "30px", height: "30px" }}
            onClick={() => { deleteTradesHandler(trade.id) }}
          >
            <i className="fa fa-trash-o"></i>
          </button>
        </li>
      </ul>
    </div>
  );
}
