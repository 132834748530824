import { DO_LOGIN, FETCH_OWNER } from "./actionTypes";
import { LOGIN_URL, FETCH_OWNER_URL } from "../../_helper/url_helper";
import api from "../../_helper/api_helper";
import { toast } from "react-toastify";

export const login = (data) => {
  return async (dispatch) => {
    try {
      let res = await api.post(LOGIN_URL, data);
      if (res.data.status === "SUCCESS") {
        toast.success(res.data.message);
        dispatch({ type: DO_LOGIN, payload: res.data.payload.token });
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
};

export const fetchOwner = (data) => {
  return async (dispatch) => {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${data.jwttoken}`;
      let res = await api.get(FETCH_OWNER_URL);
      if (res.data.status === "SUCCESS") {
        dispatch({ type: FETCH_OWNER, payload: res.data.payload });
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
}