import React, { Fragment, useState } from "react";
import { Btn, H4, H5, P, H6, UL, LI } from "../../../AbstractElements";
import { Form, FormGroup, Input, Label } from "reactstrap";
// import { Facebook, Instagram, Linkedin, Twitter } from "react-feather";
import { login } from "../../../store/login/action";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";

const LoginForm = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  

  const loginHandler = () => {
    let data = {
      username: username,
      password: password,
    };
    dispatch(login(data));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      loginHandler();
    }
  };

  return (
    <Fragment>
      <ToastContainer theme="colored" />
      <div
        className="login-card"
        style={{
          backgroundImage:
            "url(https://www.atlasandboots.com/wp-content/uploads/2019/05/ama-dablam2-most-beautiful-mountains-in-the-world.jpg)",
          backgroundSize: "cover",
        }}
      >
        <div className="theme-form login-form">
          <H4>Login</H4>
          <H6>This is Virtual Trading platform only for educational purpose. and Developers are not responsible for any losses/profits if incurred.</H6>
          <FormGroup>
            <Label>Username</Label>
            <div className="input-group">
              <span className="input-group-text">
                <i className="icon-email"></i>
              </span>
              <Input
                className="form-control"
                type="text"
                required=""
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                placeholder="Enter username"
              />
            </div>
          </FormGroup>
          <FormGroup className="position-relative">
            <Label>Password</Label>
            <div className="input-group">
              <span className="input-group-text">
                <i className="icon-lock"></i>
              </span>
              <Input
                className="form-control"
                type="password"
                required=""
                placeholder="*********"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                onKeyDown={handleKeyDown}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <button className="btn btn-primary" onClick={loginHandler}>Sign in</button>
          </FormGroup>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginForm;
