import React, { Fragment, useState } from "react";
import DatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import Platform from 'react-platform-js';
import {
  CardBody,
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Collapse,
  Table,
} from "reactstrap";
import { H2, H4, H5 } from "../../../AbstractElements";
import { Accordion } from "react-bootstrap";

const GeneralComponent = () => {
  const [isOpen, setIsOpen] = useState(1);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  const [startDate, setstartDate] = useState(new Date());

  const tableData = [
    {
      id: "1",
      name: "Product Menu",
      status: <i className="fa fa-circle font-success f-12" />,
      creat_on: "2022-04-18T00:00:00",
    },
    {
      id: "2",
      name: "Category Menu",
      status: <i className="fa fa-circle font-warning f-12" />,
      creat_on: "2022-04-18T00:00:00",
    },
    {
      id: "3",
      name: "Subcategory Menu",
      status: <i className="fa fa-circle font-success f-12" />,
      creat_on: "2022-04-18T00:00:00",
    },
    {
      id: "4",
      name: "Sales  Menu",
      status: <i className="fa fa-circle font-danger f-12" />,
      creat_on: "2022-04-18T00:00:00",
    },
    {
      id: "5",
      name: "Vendor Menu",
      status: <i className="fa fa-circle font-success f-12" />,
      creat_on: "2022-04-18T00:00:00",
    },
    {
      id: "6",
      name: "Category Menu",
      status: <i className="fa fa-circle font-warning f-12" />,
      creat_on: "2022-04-18T00:00:00",
    },
  ];

  const [data, setData] = useState(tableData);

  const tableColumns = [
    {
      name: "Message",
      selector: (row) =>
        "Admin (test)have Created one Broker. Broker Name:: ( test).",
      sortable: true,
      center: true,
    },
    {
      name: "Created Date",
      selector: (row) => "Dec 7, 2022, 5:35:44 AM",
      sortable: true,
      center: true,
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Users per page",
  };

  return (
    <Fragment>
      <Container fluid={true} className="datatables">
        <Row>
          <Col sm="12">
            <Accordion defaultActiveKey="0">
              <div className="default-according" id="accordion1">
                <Card className="mb-4">
                  <CardHeader className="bg-primary" onClick={() => toggle(1)}>
                    <h5 className="d-flex justify-content-between">
                      <span>Filters</span>
                      {isOpen === 1 ? (
                        <i className="icofont icofont-rounded-up"></i>
                      ) : (
                        <i className="icofont icofont-rounded-down"></i>
                      )}
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={isOpen === 1}>
                    <CardBody>
                      <div className="row">
                        <div className="col">
                          <label className="col-form-label form-label">
                            Created
                          </label>
                          <DatePicker
                            className="form-control digits"
                            selected={startDate}
                            onChange={(date) => setstartDate(date)}
                          />
                        </div>

                        <div className="col row align-items-end">
                          <div>
                            <button className="btn btn-danger m-t-5">
                              Reset
                            </button>
                          </div>
                        </div>
                        <div className="col"></div>
                      </div>
                    </CardBody>
                  </Collapse>
                </Card>
              </div>
            </Accordion>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <DataTable
                  data={data}
                  columns={tableColumns}
                  striped
                  // striped={true}
                  center={false}
                  paginationRowsPerPageOptions={[10, 25, 50, 100]}
                  pagination
                  paginationComponentOptions={paginationComponentOptions}

                  // selectableRows
                  // persistTableHead
                  // contextActions={contextActions}
                  // onSelectedRowsChange={handleRowSelected}
                  // clearSelectedRows={toggleCleared}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <pre>
        OS: {Platform.OS},
              OSVersion: {Platform.OSVersion},
              v: {Platform.DeviceVendor},
              Description: {Platform.Description},
              Browser: {Platform.Browser},
              BrowserVersion: {Platform.BrowserVersion},
              DeviceType: {Platform.DeviceType},
              DeviceModel: {Platform.DeviceModel},
              DeviceVendor: {Platform.DeviceVendor},
              Engine: {Platform.Engine},
              EngineVersion: {Platform.EngineVersion},
              CPU: {Platform.CPU},
              UA: {Platform.UA},
              </pre>
      </Container>
    </Fragment>
  );
};

export default GeneralComponent;
