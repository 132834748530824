import React, { Fragment } from "react";
import {
  Table,
} from "reactstrap";

const ActivePositions = (props) => {

  return (
    <Fragment>
        <div className="table-responsive">
              <Table className="table-border-horizontal fw-semibold" striped>
                <thead>
                  <tr className="bg-secondary">
                  <th style={{ color: "#ffffff" }}>PID</th>
                    <th style={{ color: "#ffffff" }}>Instrument</th>
                    <th style={{ color: "#ffffff" }}>Type</th>
                    <th style={{ color: "#ffffff" }}>Qty</th>
                    <th style={{ color: "#ffffff" }}>P&L</th>
                    <th style={{ color: "#ffffff" }}>Avg</th>
                    <th style={{ color: "#ffffff" }}>UsedMargin</th>
                    <th style={{ color: "#ffffff", minWidth:"220px" }}>AddedOn</th>
                    {/* <th style={{color:"#ffffff"}}>Net</th> */}
                  </tr>
                </thead>
                <tbody>
                  {props.client.active_positions.length
                    ? props.client.active_positions.map((script, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{script.id}</td>
                            <td>{script.trading_symbol}</td>
                            <td className={`${(script.type==="buy")?"text-success":"text-danger"}`}>{(script.type==="buy")?"Buy":"Sell"}</td>
                            <td className={`${(script.type==="buy")?"text-success":"text-danger"}`}>{`${(script.type==="sell")?"-":""}`+script.qty}</td>
                            <td className={`${(script.active_profit_and_loss > 0)?"text-success":"text-danger"}`}>{(script.active_profit_and_loss > 0) && "+"}{script.active_profit_and_loss}</td>
                            <td>{script.avg_price}</td>
                            <td>{script.used_margin}</td>
                            <td>{script.added_on}</td>
                          </tr>
                        );
                      })
                    : <tr><td className="text-center" colSpan={9}>No have any positions</td></tr>}
                </tbody>
              </Table>
              </div>
    </Fragment>
  );
};

export default ActivePositions;
