import login from "./login/reducer";
import order from "./Order/reducer";
import market from "./market/reducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  login,
  order,
  market
});
export default rootReducer;
