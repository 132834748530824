import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Table,
  Modal,
  ModalBody,
} from "reactstrap";
import { useSelector } from "react-redux";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import { FETCH_ACCOUNTS_URL, GENERATE_BILL_URL } from "../../../_helper/url_helper";
import { useNavigate } from "react-router-dom";
import { useDraggable } from "react-use-draggable-scroll";
import { Spinner } from '../../../AbstractElements';
import { useDownloadExcel } from 'react-export-table-to-excel';

const GeneralComponent = () => {
  const navigate = useNavigate();
  const loginState = useSelector((state) => state.login);
  const [data, setData] = useState([]);
  const [currency, setCurrency] = useState('INR');
  const [loading, setLoading] = useState(false);
  const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
  const tableRef = useRef(null);
  const { events } = useDraggable(ref);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Users table',
    sheet: 'Users'
  })
  useEffect(() => {
    fetchDataHandler();
  }, []);

  useEffect(() => {
    fetchDataHandler();
  }, [currency]);

  async function fetchDataHandler() {
    try {
      // let data = {
      //   jwttoken: loginState.jwttoken,
      // };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_ACCOUNTS_URL, { params: { currency: currency } });
      if (res.data.status === "SUCCESS") {
        setData(res.data.payload);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function generateBillHandler() {
    try {
      if (!window.confirm("Are you sure?")) {
        return;
      }
      if (!window.confirm("Are you realy sure?")) {
        return;
      }
      setLoading(true);

      let data = {
        jwttoken: loginState.jwttoken,
      };
      let res = await api.post(GENERATE_BILL_URL, data);
      if (res.data.status === 1) {
        toast.success("Bill Generated Successfully");
        fetchDataHandler();
        setLoading(false);
      } else {
        toast.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  }

  return (
    <Fragment>
      <Modal isOpen={loading}>
        <ModalBody>
          <div className="loader-box">
            <Spinner attrSpinner={{ className: 'loader-20' }} />
          </div>
          <p className="text-center fw-semibold f-16 text-danger"><span className="fw-bold">Note:- </span>Please wait otherwise its harm the bill</p>
        </ModalBody>
      </Modal>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="py-2">
                <div className="d-flex align-items-center">
                  <><div className="col">
                    <h4 className="mb-0 fw-semibold">Bill</h4>
                  </div>
                    <div className="col text-end">
                      <button
                        className={`btn ${currency === "INR"
                          ? "btn-primary"
                          : "btn-outline-primary"
                          }`}
                        onClick={() => setCurrency("INR")}
                      >
                        <i className="icon-wallet"></i>&nbsp; INR
                      </button>
                      <button
                        className={`btn mx-3 ${currency === "USD"
                          ? "btn-secondary"
                          : "btn-outline-secondary"
                          }`}
                        onClick={() => setCurrency("USD")}
                      >
                        <i className="icon-wallet"></i>&nbsp; USD
                      </button>

                      {/* <button className="btn btn-primary me-3" onClick={onDownload}>
                        Download
                      </button> */}
                      {/* <button className="btn btn-secondary" onClick={generateBillHandler}>
                        Generate
                      </button> */}
                    </div></>
                </div>
              </CardHeader>
              <div className="table-responsive" ref={ref} {...events}>
                <table className="table-border-horizontal fw-semibold table-striped" ref={tableRef}>
                  <thead>
                    <tr className="bg-primary">
                      {/* <th className="text-nowrap" style={{ color: "#ffffff" }}>Date</th> */}
                      {/* <th className="text-nowrap" style={{ color: "#ffffff" }}>Recievable/Payable</th> */}
                      <th className="text-nowrap" style={{ color: "#ffffff" }}>User</th>
                      <th className="text-nowrap" style={{ color: "#ffffff" }}>Client PL Sum</th>
                      <th className="text-nowrap" style={{ color: "#ffffff" }}>Client Brokerage Sum</th>
                      <th className="text-nowrap" style={{ color: "#ffffff" }}>Client Net Sum</th>
                      {loginState.owner.type === "super" && <><th className="text-nowrap" style={{ color: "#ffffff" }}>PL Share</th>
                        <th className="text-nowrap" style={{ color: "#ffffff" }}>Brokerage Share</th>
                        <th className="text-nowrap" style={{ color: "#ffffff" }}>Net Share</th></>}
                    </tr>
                  </thead>
                  <tbody>
                    {data.length
                      ? data.map((account, idx) => {
                        return (
                          <tr key={idx}>
                            {/* <td className="text-nowrap"><span className="badge rounded-pill badge bg-primary rounded-pill px-3 py-2">{account.date}</span></td> */}
                            {/* <td className="text-nowrap">{account.rp}</td> */}
                            <td>{account.username}</td>
                            <td className={`${(account.sum_of_client_pl > 0) ? "text-success" : "text-danger"}`}>{account.sum_of_client_pl}</td>
                            <td>{account.sum_of_client_brokerage}</td>
                            <td className={`${(account.client_net > 0) ? "text-success" : "text-danger"}`}>{account.client_net}</td>
                            {loginState.owner.type === "super" && <><td>{account.pl_share}</td>
                              <td>{account.brokerage_share}</td>
                              <td>{account.net_share}</td></>}
                          </tr>
                        );
                      })
                      : ""}

                  </tbody>
                </table>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GeneralComponent;
