import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  Card,
  CardBody,
  CardHeader,
  Collapse,
} from "reactstrap";
import Detail from "./Detail";
import { Accordion } from "react-bootstrap";
import Brokers from "./Brokers";
import { Btn, H5 } from "../../../../AbstractElements";
import {FETCH_USER_URL, FETCH_USERS_URL , FETCH_ALL_UNDER_CLIENTS_URL} from "../../../../_helper/url_helper";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import api from "../../../../_helper/api_helper";
import { toast } from "react-toastify";
import Clients from "./Clients";

const GeneralComponent = () => {
  const [isOpen, setIsOpen] = useState(1);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  const loginState = useSelector((state)=>state.login);
  const { userid } = useParams();
  const [user,setUser] = useState({});
  const [data,setData] = useState([]);

  useEffect(()=>{
    fetchUserHandler();
  },[]);

  useEffect(()=>{
    if(user["type"] === "admin"){
      fetchUsersHandler();
    }else{
       fetchClientsHandler();
    }
  },[user]);

  async function fetchUserHandler() {
    try {
      let data = {
        jwttoken: loginState.jwttoken,
        userid: userid
      };
      let res = await api.post(FETCH_USER_URL, data);
      if (res.data.status === 1) {
          setUser(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }  

  async function fetchUsersHandler() {
    try {
      let data = {
        jwttoken: loginState.jwttoken,
        userid: userid
      };
      let res = await api.post(FETCH_USERS_URL, data);
      if (res.data.status === 1) {
          setData(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }  
  

  async function fetchClientsHandler() {
    try {
      let data = {
        jwttoken: loginState.jwttoken,
        userid: userid
      };
      let res = await api.post(FETCH_ALL_UNDER_CLIENTS_URL, data);
      if (res.data.status == 1) {
        setData(res.data.data);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }
  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Accordion defaultActiveKey="0">
            <div className="default-according" id="accordion1">
              <Card className="mb-4">
                <CardHeader className="bg-primary" onClick={() => toggle(1)}>
                  <h5 className="d-flex justify-content-between">
                    <span>Detail</span>{" "}
                    {isOpen === 1 ? (
                      <i className="icofont icofont-rounded-up"></i>
                    ) : (
                      <i className="icofont icofont-rounded-down"></i>
                    )}
                  </h5>
                </CardHeader>
                <Collapse isOpen={isOpen === 1}>
                  <CardBody>
                    <Detail user={user}/>
                  </CardBody>
                </Collapse>
              </Card>

              <Card>
                <CardHeader className="bg-secondary" onClick={() => toggle(2)}>
                  <h5 className="d-flex justify-content-between">
                    <span>{(user.type === "admin")?"Broker List":"Client List"}</span>{" "}
                    {isOpen === 2 ? (
                      <i className="icofont icofont-rounded-up"></i>
                    ) : (
                      <i className="icofont icofont-rounded-down"></i>
                    )}
                  </h5>
                </CardHeader>
                <Collapse isOpen={isOpen === 2}>
                  <CardBody>
                    {user.type === "admin" && <Brokers data={data}/>}
                    {user.type === "broker" && <Clients data={data}/>}
                  </CardBody>
                </Collapse>
              </Card>
            </div>
          </Accordion>
        </Row>
      </Container>
    </Fragment>
  );
};
export default GeneralComponent;
