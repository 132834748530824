import React, { Fragment, useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import {
    CardBody,
    Card,
    CardHeader,
    Container,
    Col,
    Row,
    Collapse,
    Table,
} from "reactstrap";
import { H2, H4, H5 } from "../../../AbstractElements";
import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import { SEARCH_CLIENT_BY_USERNAME_URL, FETCH_LEDGER_MASETR_URL } from "../../../_helper/url_helper";
// import { useNavigate } from "react-router-dom";
import { AsyncPaginate } from 'react-select-async-paginate';

const GeneralComponent = () => {
    // const navigate = useNavigate();
    const loginState = useSelector((state) => state.login);
    //   const [isOpen, setIsOpen] = useState(1);
    //   const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
    //   const [startDate, setstartDate] = useState(new Date());
    const [data, setData] = useState([]);
    const [client, setClient] = useState(null);
    const [activeCurrency, setActiveCurrency] = useState("INR");

    // useEffect(() => {
    //     // fetchDataHandler();
    // }, []);

    useEffect(() => {
        if (client && client.value) {
            fetchDataHandler();
        } else {
            setData([]);
        }
    }, [client]);

    const loadOptionsClient = async (inputValue) => {
        let data = [];
        data = await fetchUnderClientsHandler(inputValue);
        return { options: data };
    }

    async function fetchUnderClientsHandler(username) {
        try {
            // let data = {
            //   jwttoken: loginState.jwttoken,
            // };
            let data = {
                username: username
            }
            api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
            let res = await api.get(SEARCH_CLIENT_BY_USERNAME_URL, { params: data });
            if (res.data.status === "SUCCESS") {
                let temp = [];
                temp = res.data.payload.map((user) => {
                    return { value: user.id, label: user.username };
                });
                // setUnderClientOptions(temp);
                return temp;
            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    }

    async function fetchDataHandler() {
        try {
            let data = {};
            if (client && client.value) {
                data.client_id = client.value;
            } else {
                return;
            }
            // let data = {
            //   jwttoken: loginState.jwttoken,
            // };
            api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
            let res = await api.post(FETCH_LEDGER_MASETR_URL, data);
            if (res.data.status === "SUCCESS") {
                setData(res.data.payload);
            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    }

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            {/* <CardBody> */}
                            <CardHeader className="py-3">
                                <div className="justify-content-between row align-items-center">
                                    <div className="col-4 col">
                                        <h5 className="m-0 p-0 d-inline-block">
                                            <i className="fa fa-users"></i> Ledger Master{" "}
                                        </h5>{" "}
                                        {/* <span className="badge rounded-pill badge bg-primary rounded-pill">
                                            {pagination.totalRows}
                                        </span> */}
                                    </div>
                                    <div className="col-6 col text-end">
                                        <div className="d-inline-block ">
                                            <button
                                                className={`btn btn-${(activeCurrency !== "INR") ? "outline-" : ""}primary`}
                                                onClick={() => { setActiveCurrency("INR") }}
                                            >
                                                INR
                                            </button>
                                            <button
                                                className={`btn btn-${(activeCurrency !== "USD") ? "outline-" : ""}primary mx-3`}
                                                onClick={() => { setActiveCurrency("USD") }}
                                            >
                                                USD
                                            </button>
                                        </div>
                                        <div className="text-dark text-start d-inline-block" style={{ minWidth: "250px" }}>
                                            <AsyncPaginate
                                                className="js-example-basic-single col-sm-12"
                                                value={client}
                                                isClearable={true}
                                                loadOptions={loadOptionsClient}
                                                // onChange={setValue}
                                                onChange={(e) => { setClient(e) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </CardHeader>
                            <div className="table-responsive">
                                <Table className="table-border-horizontal align-middle fw-semibold text-nowrap" striped>
                                    <thead>
                                        <tr className="bg-primary">
                                            <th style={{ color: "#ffffff" }}>DATE</th>
                                            <th style={{ color: "#ffffff" }}>CREDIT</th>
                                            <th style={{ color: "#ffffff" }}>DEBIT</th>
                                            <th style={{ color: "#ffffff" }}>CLOSING</th>
                                            <th style={{ color: "#ffffff" }}>DESCRIPTION</th>
                                            {/* <th style={{ color: "#ffffff" }}>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data[activeCurrency] !== undefined && data[activeCurrency].length
                                            ? data[activeCurrency].map((data, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{data.added_on}</td>
                                                        <td className="text-success">{(data.amount > 0) ? '+' + data.amount : "-"}</td>
                                                        <td className="text-danger">{(data.amount < 0) ? data.amount : "-"}</td>
                                                        <td>{parseFloat(data.closing).toFixed(2)}</td>
                                                        <td>{data.description}</td>
                                                    </tr>
                                                );
                                            })
                                            : ""}

                                    </tbody>
                                </Table>
                            </div>
                            {/* </CardBody> */}
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default GeneralComponent;
