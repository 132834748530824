import React, { Fragment, useState, useEffect } from "react";
import { CardBody, Card, CardHeader, Container, Col, Row } from "reactstrap";
import { H2, H4, H5 } from "../../../../AbstractElements";
import { useSelector } from "react-redux";
import api from "../../../../_helper/api_helper";
import { toast } from "react-toastify";
import {
  CREATE_USER_URL,
  UPDATE_USER_URL,
  FETCH_TEMPLATES_URL,
  FETCH_USER_URL
} from "../../../../_helper/url_helper";
import { useNavigate, useParams } from "react-router-dom";

const GeneralComponent = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const loginState = useSelector((state) => state.login);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState("active");
  const [type, setType] = useState("admin");
  const [limit, setLimit] = useState(0);
  const [idChargeType, setIdChargeType] = useState("disable");
  const [idCharge, setIdCharge] = useState(0);
  const [profitLossShareType, setProfitLossShareType] =
    useState("without_brokerage");
  const [profitLossShare, setProfitLossShare] = useState(0);
  const [brokerageShare, setBrokerageShare] = useState(0);
  // const [equityBrokeragePerCrore, setEquityBrokeragePerCrore] = useState(0);
  // const [mcxBrokerageType, setMcxBrokerageType] = useState("crore");
  // const [mcxBrokeragePerCrore, setMcxBrokeragePerCrore] = useState(0);
  const [brokerageTemplateId, setBrokerageTemplateId] = useState(0);
  const [brokerageTemplates, setBrokerageTemplates] = useState([]);

  useEffect(() => {
    fetchBrokerageTemplatesHandler();
  }, []);

  function isAlpha(value) {
    return /[a-z]/i.test(value) && !/\d/.test(value);
  }

  function generateRandomPassword() {
    var pass = "";
    var str =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ" + "abcdefghijklmnopqrstuvwxyz0123456789@#$";

    for (let i = 1; i <= 9; i++) {
      var char = Math.floor(Math.random() * str.length + 1);

      pass += str.charAt(char);
    }

    return pass;
  }

  function isBlank(str) {
    return !str || /^\s*$/.test(str);
  }

  async function fetchBrokerageTemplatesHandler() {
    try {
      // let data = {
      //   jwttoken: loginState.jwttoken,
      // };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_TEMPLATES_URL);
      if (res.data.status === "SUCCESS") {
        if (userId) {
          fetchUserHandler();
        }
        setBrokerageTemplates(res.data.payload);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }


  async function fetchUserHandler() {
    try {
      let data = {
        user_id: userId
      };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.post(FETCH_USER_URL, data);
      if (res.data.status === "SUCCESS") {
        setFirstname(res.data.payload.firstname);
        setLastname(res.data.payload.lastname);
        setUsername(res.data.payload.username);
        setContact(res.data.payload.contact);
        setEmail(res.data.payload.email);
        setPassword("");
        setStatus(res.data.payload.status);
        setType(res.data.payload.type);
        setLimit(res.data.payload.user_relation.client_limit)
        setIdChargeType(res.data.payload.user_relation.id_charge_type);
        setIdCharge(res.data.payload.user_relation.id_charge);
        setProfitLossShareType(res.data.payload.user_relation.profit_loss_share_type);
        setProfitLossShare(res.data.payload.user_relation.profit_loss_share);
        setBrokerageShare(res.data.payload.user_relation.brokerage_share);
        setBrokerageTemplateId(res.data.payload.user_relation.brokerage_template_id);

      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function createUserHandler() {
    try {
      if (isBlank(firstname)) {
        toast.warn("Firstname required");
        return;
      }
      if (isBlank(lastname)) {
        toast.warn("Lastname required");
        return;
      }
      if (isBlank(username)) {
        toast.warn("Username required");
        return;
      }

      if ((!userId) && password.length < 8) {
        toast.warn("Password should be minimum 8 characters");
        return;
      }

      if (type != "staff") {
        if (isBlank(limit) && limit !== 0) {
          toast.warn("Limit required atleat 0");
          return;
        }
        if (isBlank(idCharge) && idCharge !== 0) {
          toast.warn("Charge Required atleast 0");
          return;
        }
        if (isBlank(profitLossShare) && profitLossShare !== 0) {
          toast.warn("ProfitLoss share required atleast 0");
          return;
        }
        if (isBlank(idCharge) && idCharge !== 0) {
          toast.warn("Charge Required");
          return;
        }
        if (profitLossShareType === "without_brokerage") {
          if (Number(brokerageTemplateId) === 0) {
            toast.warn("Brokerage Template required");
            return;
          }
        }
        if (profitLossShareType === "brokerage_share") {
          if (isBlank(brokerageShare) && brokerageShare !== 0) {
            toast.warn("Brokerage share required atleast 0");
            return;
          }
        }
      }
      let data = {
        // jwttoken: loginState.jwttoken,
        firstname: firstname,
        lastname: lastname,
        username: username,
        status: status,
        type: type,
        client_limit: limit,
      };
      data.id_charge_type = idChargeType;
      data.id_charge = idCharge;
      data.profit_loss_share_type = profitLossShareType;
      data.profit_loss_share = profitLossShare;
      data.brokerage_share = brokerageShare;
      data.brokerage_template_id = brokerageTemplateId;
      if (password) {
        data.password = password;
      }
      if (!isBlank(email)) {
        data.email = email;
      }
      if (!isBlank(contact)) {
        data.contact = contact;
      }
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = "";
      if (userId) {
        data.userid = userId;
        res = await api.post(UPDATE_USER_URL, data);
      } else {
        res = await api.post(CREATE_USER_URL, data);
      }
      if (res.data.status === "SUCCESS") {
        toast.success((userId) ? "User updated" : "User created");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Fragment>
      <Container fluid={true} className="datatables">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="p-b-0 b-b-dark border-1">
                <div className="justify-content-between align-items-center mb-3 row">
                  <div className="col-4 col">
                    <h5 className="m-0 p-0">
                      <i className="fa fa-user"></i> Create User
                    </h5>
                  </div>
                  <div className="col-4 col  text-end">
                    <button
                      className="btn btn-primary mx-2"
                      onClick={createUserHandler}
                    >
                      {(userId) ? "Update" : "Create"}
                    </button>
                    <button className="btn btn-outline-secondary" onClick={() => navigate("/users")}>
                      Cancel
                    </button>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={4} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      First Name{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter first name"
                      type="text"
                      className="form-control"
                      pattern="[A-Za-z]"
                      value={firstname}
                      onChange={(e) => {
                        if (isAlpha(e.target.value) || e.target.value === "") {
                          setFirstname(e.target.value);
                        }
                      }}
                    />
                  </Col>
                  <Col md={4} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Last Name{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter last name"
                      type="text"
                      className="form-control"
                      value={lastname}
                      onChange={(e) => {
                        if (isAlpha(e.target.value) || e.target.value === "") {
                          setLastname(e.target.value);
                        }
                      }}
                    />
                  </Col>
                  <Col md={4} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Username{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter username"
                      type="text"
                      className="form-control"
                      value={username}
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                    />
                  </Col>

                  <Col md={4} className={"mb-3"}>
                    <label className="col-form-label form-label">Contact</label>
                    <input
                      placeholder="Enter contact"
                      type="number"
                      className="form-control"
                      value={contact}
                      onChange={(e) => {
                        if (
                          (e.target.value.length < 15 && e.target.value > 0) ||
                          e.target.value === ""
                        ) {
                          setContact(e.target.value);
                        }
                      }}
                    />
                  </Col>

                  <Col md={4} className={"mb-3"}>
                    <label className="col-form-label form-label">Email</label>
                    <input
                      placeholder="Enter email"
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Col>

                  <Col md={4} className={"mb-3"}>
                    <div>
                      <label className="col-form-label form-label">
                        Password{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </label>
                    </div>
                    <div className="row align-items-end">
                      <div className="input-group">
                        <input
                          placeholder="Enter password"
                          type="text"
                          className="form-control"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <button
                          className="btn btn-secondary"
                          onClick={() => setPassword(generateRandomPassword())}
                        >
                          G
                        </button>
                      </div>
                    </div>
                  </Col>

                  <Col md={4} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Status{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <select
                      className="form-control"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </Col>

                  <Col md={4} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      User Type{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <select
                      className="form-control"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option>Select user type</option>
                      {loginState.owner.type === "super" && (
                        <option value="admin">Admin</option>
                      )}

                      <option value="broker">Broker</option>

                      {/* <option value="staff">OfficeStaff</option> */}
                    </select>
                  </Col>

                  {type !== "staff" && (
                    <>
                      <Col md={4} className={"mb-3"}>
                        <label className="col-form-label form-label">
                          Client Limit{" "}
                          <sup>
                            <i
                              className="fa fa-asterisk text-danger"
                              style={{ fontSize: "8px" }}
                            ></i>
                          </sup>
                        </label>
                        <input
                          placeholder="Enter limit"
                          type="number"
                          className="form-control"
                          value={limit}
                          onChange={(e) => setLimit(e.target.value)}
                        />
                      </Col>

                      <Col md={4} className={"mb-3"}>
                        <label className="col-form-label form-label">
                          Per ID Charge Type{" "}
                          <sup>
                            <i
                              className="fa fa-asterisk text-danger"
                              style={{ fontSize: "8px" }}
                            ></i>
                          </sup>
                        </label>
                        <select
                          className="form-control"
                          value={idChargeType}
                          onChange={(e) => setIdChargeType(e.target.value)}
                        >
                          <option value="disable">Disable</option>
                          <option value="fix">Fix</option>
                          <option value="id">Per ID</option>
                        </select>
                      </Col>

                      {idChargeType !== "disable" && (
                        <Col md={4} className={"mb-3"}>
                          <label className="col-form-label form-label">
                            Per ID Charge{" "}
                            <sup>
                              <i
                                className="fa fa-asterisk text-danger"
                                style={{ fontSize: "8px" }}
                              ></i>
                            </sup>
                          </label>
                          <input
                            placeholder="Enter ID charge"
                            type="number"
                            className="form-control"
                            min="0"
                            value={idCharge}
                            onChange={(e) => setIdCharge(e.target.value)}
                          />
                        </Col>
                      )}

                      <Col md={4} className={"mb-3"}>
                        <label className="col-form-label form-label">
                          Profit / loss Share (%) Type{" "}
                          <sup>
                            <i
                              className="fa fa-asterisk text-danger"
                              style={{ fontSize: "8px" }}
                            ></i>
                          </sup>
                        </label>
                        <select
                          className="form-control"
                          value={profitLossShareType}
                          onChange={(e) =>
                            setProfitLossShareType(e.target.value)
                          }
                        >
                          <option value="with_brokerage">With Brokerage</option>
                          <option value="without_brokerage">
                            Brokerage Template
                          </option>
                          <option value="brokerage_share">
                            Different Brokerage Percentage
                          </option>
                        </select>
                      </Col>

                      <Col md={4} className={"mb-3"}>
                        <label className="col-form-label form-label">
                          Profit / loss Share (%){" "}
                          <sup>
                            <i
                              className="fa fa-asterisk text-danger"
                              style={{ fontSize: "8px" }}
                            ></i>
                          </sup>
                        </label>
                        <input
                          placeholder="Enter (%)"
                          type="text"
                          className="form-control"
                          min="0"
                          value={profitLossShare}
                          onChange={(e) => setProfitLossShare(e.target.value)}
                        />
                      </Col>

                      {profitLossShareType === "brokerage_share" && <Col md={4} className={"mb-3"}>
                        <label className="col-form-label form-label">
                          Brokerage Share (%){" "}
                          <sup>
                            <i
                              className="fa fa-asterisk text-danger"
                              style={{ fontSize: "8px" }}
                            ></i>
                          </sup>
                        </label>
                        <input
                          placeholder="Enter (%)"
                          type="text"
                          className="form-control"
                          min="0"
                          value={brokerageShare}
                          onChange={(e) => setBrokerageShare(e.target.value)}
                        />
                      </Col>}

                      {profitLossShareType === "without_brokerage" && (
                        // <>
                        //   <Col md={4} className={"mb-3"}>
                        //     <label className="col-form-label form-label">
                        //       Equity brokerage per crore{" "}
                        //       <sup>
                        //         <i
                        //           className="fa fa-asterisk text-danger"
                        //           style={{ fontSize: "8px" }}
                        //         ></i>
                        //       </sup>
                        //     </label>
                        //     <input
                        //       placeholder="Enter equity brokerage"
                        //       type="number"
                        //       className="form-control"
                        //       min="0"
                        //       value={equityBrokeragePerCrore}
                        //       onChange={(e) =>
                        //         setEquityBrokeragePerCrore(e.target.value)
                        //       }
                        //     />
                        //   </Col>
                        //   <Col md={4} className={"mb-3"}>
                        //     <label className="col-form-label form-label">
                        //       MCX brokerage Type (%){" "}
                        //       <sup>
                        //         <i
                        //           className="fa fa-asterisk text-danger"
                        //           style={{ fontSize: "8px" }}
                        //         ></i>
                        //       </sup>
                        //     </label>
                        //     <select
                        //       className="form-control"
                        //       value={mcxBrokerageType}
                        //       onChange={(e) =>
                        //         setMcxBrokerageType(e.target.value)
                        //       }
                        //     >
                        //       <option value="crore">Crore Bases</option>
                        //       <option value="lot">Lot Basis</option>
                        //     </select>
                        //   </Col>

                        // <Col md={4} className={"mb-3"}>
                        //   <label className="col-form-label form-label">
                        //     Mcx brokerage per crore{" "}
                        //     <sup>
                        //       <i
                        //         className="fa fa-asterisk text-danger"
                        //         style={{ fontSize: "8px" }}
                        //       ></i>
                        //     </sup>
                        //   </label>
                        //   <input
                        //     placeholder="Username"
                        //     type="number"
                        //     className="form-control"
                        //     value={mcxBrokeragePerCrore}
                        //     onChange={(e) =>
                        //       setMcxBrokeragePerCrore(e.target.value)
                        //     }
                        //   />
                        // </Col>

                        <Col md={4} className={"mb-3"}>
                          <label className="col-form-label form-label">
                            Brokerage Template{" "}
                            <sup>
                              <i
                                className="fa fa-asterisk text-danger"
                                style={{ fontSize: "8px" }}
                              ></i>
                            </sup>
                          </label>
                          <select
                            className="form-control"
                            value={brokerageTemplateId}
                            onChange={(e) =>
                              setBrokerageTemplateId(e.target.value)
                            }
                          >
                            <option key="0" value="0">
                              Select Template
                            </option>
                            {brokerageTemplates.map((value) => {
                              return (
                                <option value={value.id}>{value.name}</option>
                              );
                            })}
                          </select>
                        </Col>
                      )}
                    </>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GeneralComponent;
