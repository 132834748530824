import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import Flatpickr from "react-flatpickr";
import { FETCH_TIMING_URL, UPDATE_TIMING_URL } from "../../../_helper/url_helper";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";

export default function GeneralComponent() {
  const loginState = useSelector((state) => state.login);

  const [nfoStartTiming, setNfoStartTiming] = useState("09:15");
  const [nfoEndTiming, setNfoEndTiming] = useState("03:30");

  const [mcxStartTiming, setMcxStartTiming] = useState("09:00");
  const [mcxEndTiming, setMcxEndTiming] = useState("23:30");

  const [cdsStartTiming, setCdsStartTiming] = useState("09:00");
  const [cdsEndTiming, setCdsEndTiming] = useState("23:30");

  const [usStartTiming, setUsStartTiming] = useState("09:00");
  const [usEndTiming, setUsEndTiming] = useState("23:30");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchMarketTiming();
  }, []);

  async function updateMarketTiming() {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let data = {
        // jwttoken: loginState.jwttoken,
        nfo_start_time: nfoStartTiming,
        nfo_end_time: nfoEndTiming,
        mcx_start_time: mcxStartTiming,
        mcx_end_time: mcxEndTiming,
        cds_start_time: cdsStartTiming,
        cds_end_time: cdsEndTiming,
        us_start_time: usStartTiming,
        us_end_time: usEndTiming,
      };
      let res = await api.post(UPDATE_TIMING_URL, data);
      if (res.data.status === "SUCCESS") {
        toast.success("Timings updated successfully");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function fetchMarketTiming() {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_TIMING_URL, {
        // jwttoken: loginState.jwttoken,
      });
      if (res.data.status === "SUCCESS") {
        setNfoStartTiming(res.data.payload.nfo_start_time);
        setNfoEndTiming(res.data.payload.nfo_end_time);
        setMcxStartTiming(res.data.payload.mcx_start_time);
        setMcxEndTiming(res.data.payload.mcx_end_time);
        setCdsStartTiming(res.data.payload.cds_start_time);
        setCdsEndTiming(res.data.payload.cds_end_time);
        setUsStartTiming(res.data.payload.us_start_time);
        setUsEndTiming(res.data.payload.us_end_time);
        setLoading(false);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <>
      <ToastContainer />
      <Row>
        <Col lg={12}>
          <Card>
            <CardHeader className="py-3">
              <div className="row g-4 align-items-center">
                <div className="col">
                  <div>
                    <h5 className="card-title mb-0">Market Timing</h5>
                  </div>
                </div>
                <div className="col col-auto">
                  <button className="btn btn-sm btn-primary" onClick={updateMarketTiming}>
                    Save
                  </button>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div className="text-center mb-4">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/7/7a/Alarm_Clock_GIF_Animation_High_Res.gif"
                  width="200px"
                  alt=""
                />
              </div>
              {loading === false && (
                <>
                  <div className="mb-4 row">
                    <div className="col-6">
                      <label className="form-label mb-0">NFO Start</label>
                      <Flatpickr
                        className="form-control"
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "H:i",
                          time_24hr: true,
                          minTime: "09:15",
                          maxTime: "15:30",
                          defaultDate: nfoStartTiming,
                          onChange: (date) => {
                            let d = new Date(date);
                            setNfoStartTiming(
                              d.getHours() + ":" + d.getMinutes()
                            );
                          },
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <label className="form-label mb-0">NFO End</label>
                      <Flatpickr
                        className="form-control"
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "H:i",
                          time_24hr: true,
                          minTime: "09:15",
                          maxTime: "15:30",
                          defaultDate: nfoEndTiming,
                          onChange: (date) => {
                            let d = new Date(date);
                            setNfoEndTiming(
                              d.getHours() + ":" + d.getMinutes()
                            );
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="mb-4 row">
                    <div className="col-6">
                      <label className="form-label mb-0">MCX Start</label>
                      <Flatpickr
                        className="form-control"
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "H:i",
                          time_24hr: true,
                          minTime: "09:00",
                          defaultDate: mcxStartTiming,
                          onChange: (date) => {
                            let d = new Date(date);
                            setMcxStartTiming(
                              d.getHours() + ":" + d.getMinutes()
                            );
                          },
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <label className="form-label mb-0">MCX End</label>
                      <Flatpickr
                        className="form-control"
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "H:i",
                          time_24hr: true,
                          minTime: "00:00",
                          defaultDate: mcxEndTiming,
                          onChange: (date) => {
                            let d = new Date(date);
                            setMcxEndTiming(
                              d.getHours() + ":" + d.getMinutes()
                            );
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="row mb-4">
                    <div className="col">
                      <label className="form-label mb-0">CDS Start</label>
                      <Flatpickr
                        className="form-control"
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "H:i",
                          time_24hr: true,
                          minTime: "09:00",
                          maxTime: "00:00",
                          defaultDate: cdsStartTiming,
                          onChange: (date) => {
                            let d = new Date(date);
                            setCdsStartTiming(
                              d.getHours() + ":" + d.getMinutes()
                            );
                          },
                        }}
                      />
                    </div>
                    <div className="col">
                      <label className="form-label mb-0">CDS End</label>
                      <Flatpickr
                        className="form-control"
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "H:i",
                          time_24hr: true,
                          minTime: "09:00",
                          maxTime: "00:00",
                          defaultDate: cdsEndTiming,
                          onChange: (date) => {
                            let d = new Date(date);
                            setCdsEndTiming(
                              d.getHours() + ":" + d.getMinutes()
                            );
                          },
                        }}
                      />
                    </div>
                  </div>






                  <div className="row">
                    <div className="col">
                      <label className="form-label mb-0">US Start</label>
                      <Flatpickr
                        className="form-control"
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "H:i",
                          time_24hr: true,
                          // minTime: "09:00",
                          // maxTime: "00:00",
                          defaultDate: usStartTiming,
                          onChange: (date) => {
                            let d = new Date(date);
                            setUsStartTiming(
                              d.getHours() + ":" + d.getMinutes()
                            );
                          },
                        }}
                      />
                    </div>
                    <div className="col">
                      <label className="form-label mb-0">US End</label>
                      <Flatpickr
                        className="form-control"
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: "H:i",
                          time_24hr: true,
                          // minTime: "09:00",
                          // maxTime: "00:00",
                          defaultDate: usEndTiming,
                          onChange: (date) => {
                            let d = new Date(date);
                            setUsEndTiming(
                              d.getHours() + ":" + d.getMinutes()
                            );
                          },
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
