import React, { Fragment, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

const Clients = ({ data }) => {
  const navigate = useNavigate();

  const tableColumns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      width: "120px",
      center: true,
    },
    {
      name: "Name",
      selector: (row) => row.firstname + " " + row.lastname,
      sortable: true,
      center: true,
      width: "130px",
    },
    {
      name: "Username",
      selector: (row) => row.username,
      width: "130px",
      sortable: true,
      center: true,
    },
    {
      name: "Ledger",
      selector: (row) => row.balance,
      sortable: true,
      center: true,
      width: "130px",
    },
    {
      name: "Goss PL",
      selector: (row) =>
        !row.profit_and_loss ? (
          <i className="icofont icofont-emo-slightly-smile"></i>
        ) : row.profit_and_loss > 0 ? (
          <span className="text-success">{row.profit_and_loss}</span>
        ) : (
          <span className="text-danger">{row.profit_and_loss}</span>
        ),
      sortable: true,
      center: true,
    },
    {
      name: "Brokerage",
      selector: (row) => row.brokerage,
      sortable: true,
      center: true,
    },
    {
      name: "Net PL ",
      selector: (row) => Number(row.profit_and_loss) + Number(row.brokerage),
      sortable: true,
      center: true,
      width: "130px",
    },
    {
      name: "Is Demo",
      selector: (row) => row.is_demo,
      sortable: true,
      center: true,
      width: "130px",
    },
    {
      name: "Status",
      selector: (row) =>
        row.status === "active" ? (
          <span className="badge bg-success">active</span>
        ) : (
          <span className="badge bg-danger">inactive</span>
        ),
      sortable: true,
      center: true,
      width: "130px",
    },
    {
      name: "Action",
      selector: (row) => actionBinder(row, navigate),
      width: "150px",
      sortable: true,
      center: true,
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Users per page",
  };

  return (
    <Fragment>
      <div className="datatables">
        <DataTable
          data={data}
          columns={tableColumns}
          striped
          center={false}
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          pagination
          paginationComponentOptions={paginationComponentOptions}
        />
      </div>
    </Fragment>
  );
};

export default Clients;

function actionBinder(row, navigate) {
  return (
    <div>
      <ul className="list-inline hstack gap-1 mb-0">
        <li className="list-inline-item">
          <button
            title="view"
            className="btn btn-sm btn-primary p-0 d-flex justify-content-center align-items-center fw-semibold"
            style={{ width: "30px", height: "30px" }}
            onClick={()=>{navigate(`/client/profile/${row.id}`)}}
          >
            <i className="fa fa-eye"></i>
          </button>
        </li>
      </ul>
    </div>
  );
}
