import React, { Fragment } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import CountUp from 'react-countup';
import { H4, H5 } from '../../../AbstractElements';

const DashboardDetail = () => {
    const SocialData = [
        {
            id: 1,
            icon: 'fa fa-facebook',
            socialName: 'Facebook',
            count1: '6589',
            count2: '75269',
            title: 'Like'
        },
        {
            id: 2,
            icon: 'fa fa-twitter',
            socialName: 'Twitter',
            count1: '6589',
            count2: '75269',
            title: 'Follower'
        },
        {
            id: 3,
            icon: 'fa fa-linkedin',
            socialName: 'Linkedin',
            count1: '1234',
            count2: '4369',
            title: 'Linkedin'
        },
        {
            id: 4,
            icon: 'fa fa-google-plus',
            socialName: 'Google +',
            count1: '6589',
            count2: '75269',
            title: 'Like'
        },
        {
            id: 5,
            icon: 'fa fa-google-plus',
            socialName: 'Google +',
            count1: '6589',
            count2: '75269',
            title: 'Like'
        },
        {
            id: 6,
            icon: 'fa fa-google-plus',
            socialName: 'Google +',
            count1: '6589',
            count2: '75269',
            title: 'Like'
        },
        {
            id: 7,
            icon: 'fa fa-google-plus',
            socialName: 'Google +',
            count1: '6589',
            count2: '75269',
            title: 'Like'
        },
        {
            id: 8,
            icon: 'fa fa-google-plus',
            socialName: 'Google +',
            count1: '6589',
            count2: '75269',
            title: 'Like'
        }
    ];    
    
    return (
        <Fragment>
            {
                SocialData.map((item) =>
                    <Col sm='6' xl="4" lg="6" className="xl-33 box-col-6" key={item.id}>
                        <Card className="social-widget-card">
                            <CardBody>
                                <div className="redial-social-widget radial-bar-70" data-label="50%"><i className={`${item.icon} font-primary`}></i></div>
                                <H5 attrH5={{ className: 'b-b-light' }}>{item.socialName}</H5>
                                <Row>
                                    <Col className="text-center b-r-light">
                                        <span>Post</span>
                                        <H4 attrH4={{ className: 'counter mb-0' }} ><CountUp end={item.count1} duration={5} /></H4>
                                    </Col>
                                    <Col className="text-center"><span>{item.title}</span>
                                        <H4 attrH4={{ className: 'counter mb-0' }}><CountUp end={item.count2} duration={5} /></H4>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                )
            }

        </Fragment>
    );
};

export default DashboardDetail;