import React, { Fragment, useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import {
    CardBody,
    Card,
    CardHeader,
    Container,
    Col,
    Row,
    Collapse,
    Table,
} from "reactstrap";
import { H2, H4, H5 } from "../../../AbstractElements";
import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import { FETCH_CURRENCY_RATE_URL, UPDATE_CURRENCY_RATE_URL } from "../../../_helper/url_helper";
// import { useNavigate } from "react-router-dom";

const GeneralComponent = () => {
    // const navigate = useNavigate();
    const loginState = useSelector((state) => state.login);
    //   const [isOpen, setIsOpen] = useState(1);
    //   const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
    //   const [startDate, setstartDate] = useState(new Date());
    const [data, setData] = useState({});

    useEffect(() => {
        fetchDataHandler();
    }, []);

    //   useEffect(() => {
    //     let interval = setInterval(fetchCloseScriptsHandler, 20000);
    //     return () => clearInterval(interval);
    //   }, []);

    async function fetchDataHandler() {
        try {
            // let data = {
            //   jwttoken: loginState.jwttoken,
            // };
            api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
            let res = await api.get(FETCH_CURRENCY_RATE_URL);
            if (res.data.status === "SUCCESS") {
                setData(res.data.payload);
            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    }

    async function updateDataHandler() {
        try {
            // let data = {
            //   jwttoken: loginState.jwttoken,
            // };
            api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
            let res = await api.post(UPDATE_CURRENCY_RATE_URL, data);
            if (res.data.status === "SUCCESS") {
                toast.success("Rate updated successfully");
            } else {
                toast.error(res.data.message);
            }
        } catch (error) {
            toast.error(error.message);
        }
    }

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            {/* <CardBody> */}
                            <CardHeader className="py-3">
                                <div className="justify-content-between row align-items-center">
                                    <div className="col-4 col">
                                        <h5 className="m-0 p-0 d-inline-block">
                                            <i className="fa fa-users"></i> Currency rate manager{" "}
                                        </h5>{" "}
                                        {/* <span className="badge rounded-pill badge bg-primary rounded-pill">
                                            {pagination.totalRows}
                                        </span> */}
                                    </div>
                                    <div className="col-4 col text-end">
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => { updateDataHandler() }}
                                        // onClick={() => navigate("/client/create")}
                                        >
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </CardHeader>
                            <div className="table-responsive">
                                <Table className="table-border-horizontal fw-semibold text-nowrap" striped>
                                    <thead>
                                        <tr className="bg-primary">
                                            <th style={{ color: "#ffffff" }}>#</th>
                                            <th style={{ color: "#ffffff" }}>Currency</th>
                                            <th style={{ color: "#ffffff" }}>Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(data).length
                                            ? Object.keys(data).map((currencyKey, idx) => {
                                                return (
                                                    <tr key={idx}>
                                                        <td>{idx + 1}</td>
                                                        <td>{currencyKey}</td>
                                                        <td><input className="fw-semibold form-control" type='number' onChange={(e) => { let tempObj = { ...data }; tempObj[currencyKey] = e.target.value; setData(tempObj) }} value={data[currencyKey]} /></td>
                                                    </tr>
                                                );
                                            })
                                            : ""}

                                    </tbody>
                                </Table>
                            </div>
                            {/* </CardBody> */}
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default GeneralComponent;
