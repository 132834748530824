import React, { Fragment, useState, useEffect } from "react";
import CreateBrokeragePage from "./CreateBrokerage"
import CreateMarginPage from "./CreateMargin"
import {
  CardBody,
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Label,
  Media,
  Input,
  Collapse,
} from "reactstrap";
import { H2, H4, H5 } from "../../../../AbstractElements";
import { Accordion } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import api from "../../../../_helper/api_helper";
import { toast } from "react-toastify";
import {
  FETCH_SYMBOLS_URL,
  FETCH_MCX_SYMBOLS_URL,
  FETCH_CLIENT_URL,
  FETCH_TEMPLATES_URL,
  FETCH_MARGIN_TEMPLATES_URL,
  FETCH_BROKERS_URL,
  CREATE_CLIENT_URL,
  UPDATE_CLIENT_URL
} from "../../../../_helper/url_helper";
import { useNavigate, useParams } from "react-router-dom";
import { fetchOwner } from "../../../../store/login/action";

const GeneralComponent = () => {
  const [brokerageIsOpen, setBrokerageIsOpen] = useState(false);
  const [brokerageData, setBrokerageData] = useState({});
  const [marginIsOpen, setMarginIsOpen] = useState(false);
  const [marginData, setMarginData] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { copyClientId } = useParams();
  const { client_id } = useParams();
  const loginState = useSelector((state) => state.login);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState("active");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [under, setUnder] = useState("broker");
  // const [equityBrokeragePerCrore, setEquityBrokeragePerCrore] = useState(0);
  // const [mcxBrokerageType, setMcxBrokerageType] = useState("crore");
  // const [mcxBrokeragePerCrore, setMcxBrokeragePerCrore] = useState(0);
  const [brokerageTemplateId, setBrokerageTemplateId] = useState(0);
  const [brokerageTemplates, setBrokerageTemplates] = useState([]);
  const [marginTemplateId, setMarginTemplateId] = useState(0);
  const [marginTemplates, setMarginTemplates] = useState([]);
  const [showProfile, setShowProfile] = useState(true);
  const [isDemo, setIsDemo] = useState(false);
  const [limitAboveHighLow, setLimitAboveHighLow] = useState(true);
  const [limitBetweenHighLow, setLimitBetweenHighLow] = useState(true);
  const [slAboveHighLow, setSlAboveHighLow] = useState(true);
  const [slBetweenHighLow, setSlBetweenHighLow] = useState(true);
  const [onlyIntraday, setOnlyIntraday] = useState(false);
  const [intradayBrokerageOneSide, setIntradayBrokerageOneSide] =
    useState(false);
  const [tradeEquityInUnit, setTradeEquityInUnit] = useState(false);
  const [tradeMcxInUnit, setTradeMcxInUnit] = useState(false);
  // const [isBillEffectOnLedger, setIsBillEffectOnLedger] = useState(false);
  const [autoClossOnLoss, setAutoClossOnLoss] = useState(true);
  const [minLotMcxSingleTrade, setMinLotMcxSingleTrade] = useState("");
  const [maxLotMcxSingleTrade, setMaxLotMcxSingleTrade] = useState("");
  const [minLotEquitySingleTrade, setMinLotEquitySingleTrade] = useState("");
  const [maxLotEquitySingleTrade, setMaxLotEquitySingleTrade] = useState("");
  const [minLotCdsSingleTrade, setMinLotCdsSingleTrade] = useState("");
  const [maxLotCdsSingleTrade, setMaxLotCdsSingleTrade] = useState("");

  const [minLotFxSingleTrade, setMinLotFxSingleTrade] = useState("");
  const [maxLotFxSingleTrade, setMaxLotFxSingleTrade] = useState("");
  const [minLotCryptoSingleTrade, setMinLotCryptoSingleTrade] = useState("");
  const [maxLotCryptoSingleTrade, setMaxLotCryptoSingleTrade] = useState("");
  const [minLotUsSingleTrade, setMinLotUsSingleTrade] = useState("");
  const [maxLotUsSingleTrade, setMaxLotUsSingleTrade] = useState("");

  const [maxLotMcxPerScript, setMaxLotMcxPerScript] = useState("");
  const [maxLotEquityPerScript, setMaxLotEquityPerScript] = useState("");
  const [maxLotCdsPerScript, setMaxLotCdsPerScript] = useState("");

  const [maxLotFxPerScript, setMaxLotFxPerScript] = useState("");
  const [maxLotCryptoPerScript, setMaxLotCryptoPerScript] = useState("");
  const [maxLotUsPerScript, setMaxLotUsPerScript] = useState("");

  const [maxLotCanStand, setMaxLotCanStand] = useState("");
  const [maxLotCanStandUsd, setMaxLotCanStandUsd] = useState("");

  // const [tradeAutoCloseOn, setTradeAutoCloseOn] = useState("");
  // const [lossNotificationOn, setLossNotificationOn] = useState("");
  const [isMcxTradingActive, setIsMcxTradingActive] = useState(true);
  const [isEquityTradingActive, setIsEquityTradingActive] = useState(true);
  const [isCdsTradingActive, setIsCdsTradingActive] = useState(true);
  const [isFxTradingActive, setIsFxTradingActive] = useState(true);
  const [isCryptoTradingActive, setIsCryptoTradingActive] = useState(true);
  const [isUsTradingActive, setIsUsTradingActive] = useState(true);
  const [isOptionBuyActive, setIsOptionBuyAcive] = useState(true);
  const [isOptionSellActive, setIsOptionSellAcive] = useState(true);
  const [optionOnlyOnMarketOrder, setOptionOnlyOnMarketOrder] = useState(true);
  const [equityStoplossPercentage, setEquityStoplossPercentage] = useState("");
  const [cdsStoplossPercentage, setCdsStoplossPercentage] = useState("");
  const [mcxStoplossType, setMcxStoplossType] = useState("0");
  const [mcxStoplossPercentage, setMcxStoplossPercentage] = useState("");
  const [tradeGapMinutes, setTradeGapMinutes] = useState("");
  const [tradeGapMinutesUsd, setTradeGapMinutesUsd] = useState("");
  const [
    tradeGapMinutesApplyOnMarketOrder,
    setTradeGapMinutesApplyOnMarketOrder,
  ] = useState(false);
  const [mcxSymbols, setMcxSymbols] = useState([]);
  const [mcxPoints, setMcxPoints] = useState({});
  const [ownBrokers, setOwnBrokers] = useState([]);
  const [adminBrokers, setAdminBrokers] = useState([]);
  const [activeBrokers, setActiveBrokers] = useState([]);
  const [brokerId, setBrokerId] = useState(false);

  useEffect(() => {
    dispatch(fetchOwner({ jwttoken: loginState.jwttoken }));
    // fetchBrokerageTemplatesHandler();
    // fetchMarginTemplatesHandler();
    fetchMcxSymbols();
    if (loginState.owner.type !== "broker") {
      fetchBrokersHandler();
    }
  }, []);

  useEffect(() => {
    if (copyClientId || client_id) {
      fetchClientHandler();
    }
  }, []);

  useEffect(() => {
    if (under === "admin") {
      setActiveBrokers(adminBrokers);
    } else if (under === "broker") {
      setActiveBrokers(ownBrokers);
    }
    setBrokerId(0);
  }, [under]);
  function isAlpha(value) {
    return /[a-z]/i.test(value) && !/\d/.test(value);
  }

  function isIntegerForm(str) {
    var n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n > 0;
  }

  function setIntegerHandler(value, setFun) {
    if (value === "" || isIntegerForm(value) || Number(value) === 0) {
      setFun(value);
    }
  }

  function isNumericAndNotNegative(value, changer) {
    // Check if the value is a number and not NaN (Not a Number)
    if (!isNaN(value)) {
      // Check if the value is not smaller than zero
      if (Number(value) >= 0) {
        changer(value); // Value is numeric and not smaller than zero
      } else {
        return false; // Value is numeric but smaller than zero
      }
    } else {
      return false; // Value is not a number
    }
  }


  function changeMcxPoints(value, symbol) {
    if (value === "" || isIntegerForm(value)) {
      // const temp = JSON.parse(JSON.stringify(mcxPoints));
      // console.log(mcxPoints)
      // console.log(value, symbol)
      // console.log(temp)
      // console.log(mcxSymbols)
      // temp[idx][symbol["symbol"]] = value;
      // temp[symbol] = value;
      setMcxPoints((prev) => ({ ...prev, [symbol]: value }));
    }
  }

  function generateRandomPassword() {
    var pass = "";
    var str =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ" + "abcdefghijklmnopqrstuvwxyz0123456789@#$";

    for (let i = 1; i <= 9; i++) {
      var char = Math.floor(Math.random() * str.length + 1);

      pass += str.charAt(char);
    }

    return pass;
  }

  function isBlank(str) {
    // return !str || /^\s*$/.test(str);
    return str === null || str === undefined || /^\s*$/.test(str);
  }

  async function fetchMcxSymbols() {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_SYMBOLS_URL);
      if (res.data.status === "SUCCESS") {
        const temp = {};
        if (!client_id) {
          res.data.payload["MCX"].map((symbol) => {
            let obj = {};
            temp[symbol["symbol"]] = "";
            return obj;
          });

          setMcxPoints(temp);
        }
        setMcxSymbols(res.data.payload["MCX"]);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function fetchBrokerageTemplatesHandler() {
    try {
      // let data = {
      //   jwttoken: loginState.jwttoken,
      // };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_TEMPLATES_URL);
      if (res.data.status === "SUCCESS") {
        setBrokerageTemplates(res.data.payload);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function fetchClientHandler() {
    try {
      // if(copyClientId)
      let data = {
        // jwttoken: loginState.jwttoken,
      };
      if (copyClientId) {
        data.client_id = copyClientId;
      }
      if (client_id) {
        data.client_id = client_id;
      }
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.post(FETCH_CLIENT_URL, data);
      if (res.data.status === "SUCCESS") {
        console.log(res.data)
        setFirstname(res.data.payload.firstname);
        setLastname(res.data.payload.lastname);
        setUsername(res.data.payload.username);
        setContact(res.data.payload.contact);
        setEmail(res.data.payload.email);
        setStatus(res.data.payload.status);
        setBrokerageTemplateId(res.data.payload.client_account.brokerage_template_id);
        setMarginTemplateId(res.data.payload.client_account.margin_template_id);

        // setShowProfile(res.data.payload.show_profile === "yes");
        setAutoClossOnLoss(res.data.payload.client_account.auto_close_on_loss === "yes");
        setIsDemo(res.data.payload.client_account.is_demo === "yes");
        setLimitAboveHighLow(res.data.payload.client_account.limit_above_high_low === "yes");
        setLimitBetweenHighLow(res.data.payload.client_account.limit_between_high_low === "yes");
        setSlAboveHighLow(res.data.payload.client_account.sl_above_high_low === "yes");
        setSlBetweenHighLow(res.data.payload.client_account.sl_between_high_low === "yes");
        setOnlyIntraday(res.data.payload.client_account.only_intraday === "yes");
        setIntradayBrokerageOneSide(res.data.payload.client_account.intraday_brokerage_oneside === "yes");
        setTradeEquityInUnit(res.data.payload.client_account.trade_equity_in_unit === "yes");
        setTradeMcxInUnit(res.data.payload.client_account.trade_mcx_in_unit === "yes");
        setIsMcxTradingActive(res.data.payload.client_account.is_mcx_trading_active === "yes");
        setIsEquityTradingActive(res.data.payload.client_account.is_equity_trading_active === "yes");
        setIsCdsTradingActive(res.data.payload.client_account.is_cds_trading_active === "yes");

        setIsFxTradingActive(res.data.payload.client_account.is_forex_trading_active === "yes");
        setIsCryptoTradingActive(res.data.payload.client_account.is_crypto_trading_active === "yes");
        setIsUsTradingActive(res.data.payload.client_account.is_us_trading_active === "yes");

        setIsOptionBuyAcive(res.data.payload.client_account.is_option_buy_active === "yes");
        setIsOptionSellAcive(res.data.payload.client_account.is_option_sell_active === "yes");
        //  setIsBillEffectOnLedger(res.data.payload.client_account.bill_effect_on_ledger === "yes");
        setOptionOnlyOnMarketOrder(res.data.payload.client_account.options_only_on_market_order === "yes");
        setTradeGapMinutesApplyOnMarketOrder(res.data.payload.client_account.trade_gap_minutes_apply_on_market_order === "yes");

        setMinLotMcxSingleTrade(res.data.payload.client_account.min_lot_mcx_single_trade);
        setMinLotEquitySingleTrade(res.data.payload.client_account.min_lot_equity_single_trade);
        setMinLotCdsSingleTrade(res.data.payload.client_account.min_lot_cds_single_trade);
        setMaxLotMcxSingleTrade(res.data.payload.client_account.max_lot_mcx_single_trade);
        setMaxLotEquitySingleTrade(res.data.payload.client_account.max_lot_equity_single_trade);
        setMaxLotCdsSingleTrade(res.data.payload.client_account.max_lot_cds_single_trade);

        setMinLotFxSingleTrade(res.data.payload.client_account.min_lot_fx_single_trade);
        setMaxLotFxSingleTrade(res.data.payload.client_account.max_lot_fx_single_trade);
        setMinLotCryptoSingleTrade(res.data.payload.client_account.min_lot_crypto_single_trade);
        setMaxLotCryptoSingleTrade(res.data.payload.client_account.max_lot_crypto_single_trade);
        setMinLotUsSingleTrade(res.data.payload.client_account.min_lot_us_single_trade);
        setMaxLotUsSingleTrade(res.data.payload.client_account.max_lot_us_single_trade);

        setMaxLotMcxPerScript(res.data.payload.client_account.max_lot_mcx_per_script);
        setMaxLotEquityPerScript(res.data.payload.client_account.max_lot_equity_per_script);
        setMaxLotCdsPerScript(res.data.payload.client_account.max_lot_cds_per_script);

        setMaxLotFxPerScript(res.data.payload.client_account.max_lot_fx_per_script);
        setMaxLotCryptoPerScript(res.data.payload.client_account.max_lot_crypto_per_script);
        setMaxLotUsPerScript(res.data.payload.client_account.max_lot_us_per_script);

        setMaxLotCanStand(res.data.payload.client_account.max_lot_can_stand);
        setMaxLotCanStandUsd(res.data.payload.client_account.max_lot_can_stand_usd);

        setEquityStoplossPercentage(res.data.payload.client_account.equity_sl_percentage);
        setCdsStoplossPercentage(res.data.payload.client_account.cds_sl_percentage);
        setMcxStoplossPercentage(res.data.payload.client_account.mcx_sl_percentage);
        setMcxStoplossType(res.data.payload.client_account.mcx_sl_type);
        setTradeGapMinutes(res.data.payload.client_account.trade_gap_minutes);

        setTradeGapMinutesUsd(res.data.payload.client_account.trade_gap_minutes_usd);

        setTimeout(() => {
          res.data.payload.mcx_sl_points_client_relation.forEach((point) => {
            changeMcxPoints(String(point["point"]), point["symbol"]);
          });
        }, 2000);


      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function fetchMarginTemplatesHandler() {
    try {
      // let data = {
      //   jwttoken: loginState.jwttoken,
      // };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_MARGIN_TEMPLATES_URL);
      if (res.data.status === "SUCCESS") {
        setMarginTemplates(res.data.payload);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  function findMcxPointIndex(symbol) {
    let index = null;
    mcxPoints.forEach((point, idx) => {
      if (Object.keys(point)[0] === symbol) {
        index = idx;
      }
    });
    return index;
  }

  async function fetchBrokersHandler() {
    try {
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_BROKERS_URL);
      if (res.data.status === "SUCCESS") {
        setOwnBrokers(res.data.payload.related);
        setActiveBrokers(res.data.payload.related);
        if (res.data.payload.all !== undefined) {
          setAdminBrokers(res.data.payload.all);
        }
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function createClientHandler() {
    try {
      let data = {};
      if (loginState.owner.type !== "broker") {
        if ((!client_id) && Number(brokerId) === 0) {
          toast.error("Broker required");
          return;
        }
      }
      if (isBlank(firstname)) {
        toast.error("Firstname required");
        return;
      }
      if (isBlank(lastname)) {
        toast.error("Lastname required");
        return;
      }
      if (isBlank(username)) {
        toast.error("Username required");
        return;
      }
      if ((!client_id) && password.length < 5) {
        toast.error("Password should be minimum 5 characters");
        return;
      }
      if (Number(brokerageTemplateId) === 0) {
        toast.error("Brokerage template required");
        return;
      }
      if (Number(marginTemplateId) === 0) {
        toast.error("Margin template required");
        return;
      }
      // console.log(minLotMcxSingleTrade);
      if (isBlank(minLotMcxSingleTrade)) {
        toast.error("Min lot of mcx single trade required");
        return;
      }
      if (isBlank(maxLotMcxSingleTrade)) {
        toast.error("Max lot of mcx single trade required");
        return;
      }
      if (isBlank(minLotEquitySingleTrade)) {
        toast.error("Min lot of equity single trade required");
        return;
      }
      if (isBlank(maxLotEquitySingleTrade)) {
        toast.error("Max lot of equity single trade required");
        return;
      }
      if (isBlank(minLotCdsSingleTrade)) {
        toast.error("Min lot of cds single trade required");
        return;
      }
      if (isBlank(maxLotCdsSingleTrade)) {
        toast.error("Max lot of cds single trade required");
        return;
      }


      if (isBlank(minLotFxSingleTrade)) {
        toast.error("Min lot of fx single trade required");
        return;
      }
      if (isBlank(maxLotFxSingleTrade)) {
        toast.error("Max lot of fx single trade required");
        return;
      }

      if (isBlank(minLotCryptoSingleTrade)) {
        toast.error("Min lot of crypto single trade required");
        return;
      }
      if (isBlank(maxLotCryptoSingleTrade)) {
        toast.error("Max lot of crypto single trade required");
        return;
      }

      if (isBlank(minLotUsSingleTrade)) {
        toast.error("Min lot of us single trade required");
        return;
      }
      if (isBlank(maxLotUsSingleTrade)) {
        toast.error("Max lot of us single trade required");
        return;
      }

      if (isBlank(maxLotMcxPerScript)) {
        toast.error("Max lot of MCX per script required");
        return;
      }
      if (isBlank(maxLotEquityPerScript)) {
        toast.error("Max lot of Equity per script required");
        return;
      }
      if (isBlank(maxLotCdsPerScript)) {
        toast.error("Max lot of CDS per script required");
        return;
      }

      if (isBlank(maxLotFxPerScript)) {
        toast.error("Max lot of FX per script required");
        return;
      }
      if (isBlank(maxLotCryptoPerScript)) {
        toast.error("Max lot of Crypto per script required");
        return;
      }
      if (isBlank(maxLotUsPerScript)) {
        toast.error("Max lot of US per script required");
        return;
      }
      if (isBlank(maxLotCanStand)) {
        toast.error("Max lot can stand required");
        return;
      }
      if (isBlank(maxLotCanStandUsd)) {
        toast.error("Max lot can stand in USD required");
        return;
      }
      // if (isBlank(tradeAutoCloseOn)) {
      //   toast.error("Auto close trade on loss required");
      //   return;
      // }
      // if (isBlank(lossNotificationOn)) {
      //   toast.error("loss notification on required");
      //   return;
      // }
      if (isBlank(equityStoplossPercentage)) {
        toast.error("Equity stoploss percentage required");
        return;
      }
      if (isBlank(cdsStoplossPercentage)) {
        toast.error("CDS stoploss percentage required");
        return;
      }
      if (Number(mcxStoplossType) === "0") {
        toast.error("Mcx stoploss type required");
        return;
      }
      if (isBlank(tradeGapMinutes)) {
        toast.error("trade gap minutes required");
        return;
      }
      if (isBlank(tradeGapMinutesUsd)) {
        toast.error("trade gap minutes USD required");
        return;
      }
      if (mcxStoplossType === "percent") {
        if (isBlank(mcxStoplossPercentage)) {
          toast.error("Mcx stoploss percentage required");
          return;
        } else {
          data.mcx_sl_percentage = mcxStoplossPercentage;
        }
      } else if (mcxStoplossType === "point") {
        for (let i = 0; i < mcxSymbols.length; i++) {
          // console.log(mcxSymbols);
          if (isBlank(mcxPoints[mcxSymbols[i]["symbol"]])) {
            console.log(mcxPoints);
            console.log(mcxPoints[mcxSymbols[i]["symbol"]]);
            toast.error(`${mcxSymbols[i]["symbol"]} stoploss points required`);
            return;
          } else {
            data[mcxSymbols[i]["symbol"] + "_sl_point"] =
              mcxPoints[mcxSymbols[i]["symbol"]];
          }
        }
      }
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      data = {
        ...data,
        broker_id: brokerId,
        // jwttoken: loginState.jwttoken,
        firstname: firstname,
        lastname: lastname,
        username: username,
        status: status,
        brokerage_template_id: brokerageTemplateId,
        margin_template_id: marginTemplateId,
        show_profile: showProfile ? "yes" : "no",
        is_demo: isDemo ? "yes" : "no",
        limit_above_high_low: limitAboveHighLow ? "yes" : "no",
        limit_between_high_low: limitBetweenHighLow ? "yes" : "no",
        sl_above_high_low: slAboveHighLow ? "yes" : "no",
        sl_between_high_low: slBetweenHighLow ? "yes" : "no",
        only_intraday: onlyIntraday ? "yes" : "no",
        intraday_brokerage_oneside: intradayBrokerageOneSide ? "yes" : "no",
        trade_equity_in_unit: tradeEquityInUnit ? "yes" : "no",
        trade_mcx_in_unit: tradeMcxInUnit ? "yes" : "no",
        is_mcx_trading_active: isMcxTradingActive ? "yes" : "no",
        is_equity_trading_active: isEquityTradingActive ? "yes" : "no",
        is_cds_trading_active: isCdsTradingActive ? "yes" : "no",

        is_forex_trading_active: isFxTradingActive ? "yes" : "no",
        is_crypto_trading_active: isCryptoTradingActive ? "yes" : "no",
        is_us_trading_active: isUsTradingActive ? "yes" : "no",

        is_option_buy_active: isOptionBuyActive ? "yes" : "no",
        is_option_sell_active: isOptionSellActive ? "yes" : "no",
        // bill_effect_on_ledger: isBillEffectOnLedger ? "yes" : "no",
        auto_close_on_loss: autoClossOnLoss ? "yes" : "no",
        options_only_on_market_order: optionOnlyOnMarketOrder ? "yes" : "no",
        max_lot_can_stand: maxLotCanStand,
        max_lot_can_stand_usd: maxLotCanStandUsd,
        trade_gap_minutes_apply_on_market_order:
          tradeGapMinutesApplyOnMarketOrder ? "yes" : "no",
        min_lot_mcx_single_trade: minLotMcxSingleTrade,
        max_lot_mcx_single_trade: maxLotMcxSingleTrade,
        min_lot_equity_single_trade: minLotEquitySingleTrade,
        max_lot_equity_single_trade: maxLotEquitySingleTrade,
        min_lot_cds_single_trade: minLotCdsSingleTrade,
        max_lot_cds_single_trade: maxLotCdsSingleTrade,

        min_lot_fx_single_trade: minLotFxSingleTrade,
        max_lot_fx_single_trade: maxLotFxSingleTrade,
        min_lot_crypto_single_trade: minLotCryptoSingleTrade,
        max_lot_crypto_single_trade: maxLotCryptoSingleTrade,
        min_lot_us_single_trade: minLotUsSingleTrade,
        max_lot_us_single_trade: maxLotUsSingleTrade,

        max_lot_mcx_per_script: maxLotMcxPerScript,
        max_lot_equity_per_script: maxLotEquityPerScript,
        max_lot_cds_per_script: maxLotCdsPerScript,

        max_lot_fx_per_script: maxLotFxPerScript,
        max_lot_crypto_per_script: maxLotCryptoPerScript,
        max_lot_us_per_script: maxLotUsPerScript,

        // trade_auto_close_on: tradeAutoCloseOn,
        // loss_notification_on: lossNotificationOn,
        equity_sl_percentage: equityStoplossPercentage,
        cds_sl_percentage: cdsStoplossPercentage,
        mcx_sl_type: mcxStoplossType,
        trade_gap_minutes: tradeGapMinutes,
        trade_gap_minutes_usd: tradeGapMinutesUsd,
        trade_gap_minutes_apply_on_market_order:
          tradeGapMinutesApplyOnMarketOrder ? "yes" : "no",
        // type: type,
        // client_limit: limit,
        ...brokerageData,
        ...marginData
      };

      if (!isBlank(password)) {
        data.password = password;
      }

      if (!isBlank(email)) {
        data.email = email;
      }
      if (!isBlank(contact)) {
        data.contact = contact;
      }
      let res = "";
      if (client_id) {
        data.client_id = client_id;
        res = await api.post(UPDATE_CLIENT_URL, data);
      } else {
        res = await api.post(CREATE_CLIENT_URL, data);
      }
      if (res.data.status === "SUCCESS") {
        toast.success((client_id) ? "Client Updated" : "Client created");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Fragment>
      <Container fluid={true} className="datatables">
        <Row>
          <Col sm="12">
            <Card className="mb-0">
              <CardHeader className="p-b-0 b-b-dark border-1">
                <div className="justify-content-between align-items-center mb-3 row">
                  <div className="col-4 col">
                    <h5 className="m-0 p-0">
                      <i className="fa fa-user"></i> Create User
                    </h5>
                  </div>
                  <div className="col-4 col  text-end">
                    <button
                      className="btn btn-primary mx-2"
                      onClick={createClientHandler}
                    >
                      {(client_id) ? 'Update' : 'Create'}
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      onClick={() => navigate("/clients")}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  {loginState.owner.type !== "broker" ? (
                    <>
                      <Col md={4} className={"mb-3"}>
                        <label className="col-form-label form-label">
                          Select Under{" "}
                          <sup>
                            <i
                              className="fa fa-asterisk text-danger"
                              style={{ fontSize: "8px" }}
                            ></i>
                          </sup>
                        </label>
                        <select
                          className="form-control"
                          value={under}
                          onChange={(e) => setUnder(e.target.value)}
                        >
                          {loginState.owner.type === "super" && (
                            <option value="admin">Admin</option>
                          )}
                          {(loginState.owner.type === "super" ||
                            loginState.owner.type === "admin") && (
                              <option value="broker">Broker</option>
                            )}
                        </select>
                      </Col>

                      <Col md={4} className={"mb-3"}>
                        <label className="col-form-label form-label">
                          Select Broker{" "}
                          <sup>
                            <i
                              className="fa fa-asterisk text-danger"
                              style={{ fontSize: "8px" }}
                            ></i>
                          </sup>
                        </label>
                        <select
                          className="form-control"
                          value={brokerId}
                          onChange={(e) => setBrokerId(e.target.value)}
                        >
                          <option value="0">Select broker</option>
                          {activeBrokers.map((broker) => {
                            return (
                              <>
                                <option value={broker.id}>
                                  {broker.username}
                                </option>
                              </>
                            );
                          })}
                        </select>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}

                  <Col md={4} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      First Name{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter first name"
                      type="text"
                      className="form-control"
                      pattern="[A-Za-z]"
                      value={firstname}
                      onChange={(e) => {
                        if (isAlpha(e.target.value) || e.target.value === "") {
                          setFirstname(e.target.value);
                        }
                      }}
                    />
                  </Col>
                  <Col md={4} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Last Name{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter last name"
                      type="text"
                      className="form-control"
                      value={lastname}
                      onChange={(e) => {
                        if (isAlpha(e.target.value) || e.target.value === "") {
                          setLastname(e.target.value);
                        }
                      }}
                    />
                  </Col>
                  <Col md={4} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Username{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter username"
                      type="text"
                      className="form-control"
                      value={username}
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                    />
                  </Col>

                  <Col md={4} className={"mb-3"}>
                    <label className="col-form-label form-label">Contact</label>
                    <input
                      placeholder="Enter contact"
                      type="number"
                      className="form-control"
                      value={contact}
                      onChange={(e) => {
                        if (
                          (e.target.value.length < 15 && e.target.value > 0) ||
                          e.target.value === ""
                        ) {
                          setContact(e.target.value);
                        }
                      }}
                    />
                  </Col>

                  <Col md={4} className={"mb-3"}>
                    <label className="col-form-label form-label">Email</label>
                    <input
                      placeholder="Enter email"
                      type="email"
                      className="form-control"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </Col>

                  <Col md={4} className={"mb-3"}>
                    <div>
                      <label className="col-form-label form-label">
                        Password{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </label>
                    </div>
                    <div className="row align-items-end">
                      <div className="input-group">
                        <input
                          placeholder="Enter password"
                          type="text"
                          className="form-control"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <button
                          className="btn btn-secondary"
                          onClick={() => setPassword(generateRandomPassword())}
                        >
                          G
                        </button>
                      </div>
                    </div>
                  </Col>

                  <Col md={4} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Status{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <select
                      className="form-control"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </Col>

                  {/* <Col md={4} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Brokerage Template{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <select
                      className="form-control"
                      value={brokerageTemplateId}
                      onChange={(e) => setBrokerageTemplateId(e.target.value)}
                    >
                      <option key="0" value="0">
                        Select Template
                      </option>
                      {brokerageTemplates.map((value) => {
                        return <option value={value.id}>{value.name}</option>;
                      })}
                    </select>
                  </Col>

                  <Col md={4} className={"mb-4"}>
                    <label className="col-form-label form-label">
                      Margin Template{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <select
                      className="form-control"
                      value={marginTemplateId}
                      onChange={(e) => setMarginTemplateId(e.target.value)}
                    >
                      <option key="0" value="0">
                        Select Template
                      </option>
                      {marginTemplates.map((value) => {
                        return <option value={value.id}>{value.name}</option>;
                      })}
                    </select>
                  </Col>
                  <Col sm={12} className="mt-2"></Col> */}
                  {/* <Col md={4} className={"mb-3"}>
                    <Media>
                      <Label className="col-form-label m-r-10">
                        Show Profile{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </Label>
                      <Media body className="text-end">
                        <Label className="switch">
                          <Input
                            type="checkbox"
                            checked={showProfile}
                            onChange={() => {
                              setShowProfile(!showProfile);
                            }}
                          />
                          <span className="switch-state"></span>
                        </Label>
                      </Media>
                    </Media>
                  </Col> */}

                  <Col md={4} className={"mb-3"}>
                    <Media>
                      <Label className="col-form-label m-r-10">
                        Demo Account{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </Label>
                      <Media body className="text-end">
                        <Label className="switch">
                          <Input
                            type="checkbox"
                            checked={isDemo}
                            onChange={() => {
                              setIsDemo(!isDemo);
                            }}
                          />
                          <span className="switch-state"></span>
                        </Label>
                      </Media>
                    </Media>
                  </Col>

                  <Col md={4} className={"mb-3"}>
                    <Media>
                      <Label className="col-form-label m-r-10">
                        Limit above high low{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </Label>
                      <Media body className="text-end">
                        <Label className="switch">
                          <Input
                            type="checkbox"
                            checked={limitAboveHighLow}
                            onChange={() => {
                              setLimitAboveHighLow(!limitAboveHighLow);
                            }}
                          />
                          <span className="switch-state"></span>
                        </Label>
                      </Media>
                    </Media>
                  </Col>

                  <Col md={4} className={"mb-3"}>
                    <Media>
                      <Label className="col-form-label m-r-10">
                        Fresh Sl above high low{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </Label>
                      <Media body className="text-end">
                        <Label className="switch">
                          <Input
                            type="checkbox"
                            checked={slAboveHighLow}
                            onChange={() => {
                              setSlAboveHighLow(!slAboveHighLow);
                            }}
                          />
                          <span className="switch-state"></span>
                        </Label>
                      </Media>
                    </Media>
                  </Col>

                  <Col md={4} className={"mb-3"}>
                    <Media>
                      <Label className="col-form-label m-r-10">
                        Limit between high low{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </Label>
                      <Media body className="text-end">
                        <Label className="switch">
                          <Input
                            type="checkbox"
                            checked={limitBetweenHighLow}
                            onChange={() => {
                              setLimitBetweenHighLow(!limitBetweenHighLow);
                            }}
                          />
                          <span className="switch-state"></span>
                        </Label>
                      </Media>
                    </Media>
                  </Col>

                  <Col md={4} className={"mb-3"}>
                    <Media>
                      <Label className="col-form-label m-r-10">
                        Fresh Sl between high low{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </Label>
                      <Media body className="text-end">
                        <Label className="switch">
                          <Input
                            type="checkbox"
                            checked={slBetweenHighLow}
                            onChange={() => {
                              setSlBetweenHighLow(!slBetweenHighLow);
                            }}
                          />
                          <span className="switch-state"></span>
                        </Label>
                      </Media>
                    </Media>
                  </Col>

                  <Col md={4} className={"mb-3"}>
                    <Media>
                      <Label className="col-form-label m-r-10">
                        Only Intraday{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </Label>
                      <Media body className="text-end">
                        <Label className="switch">
                          <Input
                            type="checkbox"
                            checked={onlyIntraday}
                            onChange={() => {
                              setOnlyIntraday(!onlyIntraday);
                            }}
                          />
                          <span className="switch-state"></span>
                        </Label>
                      </Media>
                    </Media>
                  </Col>

                  <Col md={4} className={"mb-3"}>
                    <Media>
                      <Label className="col-form-label m-r-10">
                        Intraday One Side brokerage{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </Label>
                      <Media body className="text-end">
                        <Label className="switch">
                          <Input
                            type="checkbox"
                            checked={intradayBrokerageOneSide}
                            onChange={() => {
                              setIntradayBrokerageOneSide(
                                !intradayBrokerageOneSide
                              );
                            }}
                          />
                          <span className="switch-state"></span>
                        </Label>
                      </Media>
                    </Media>
                  </Col>

                  <Col md={4} className={"mb-3"}>
                    <Media>
                      <Label className="col-form-label m-r-10">
                        Mcx trading active{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </Label>
                      <Media body className="text-end">
                        <Label className="switch">
                          <Input
                            type="checkbox"
                            checked={isMcxTradingActive}
                            onChange={() => {
                              setIsMcxTradingActive(!isMcxTradingActive);
                            }}
                          />
                          <span className="switch-state"></span>
                        </Label>
                      </Media>
                    </Media>
                  </Col>

                  <Col md={4} className={"mb-3"}>
                    <Media>
                      <Label className="col-form-label m-r-10">
                        Equity trading active{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </Label>
                      <Media body className="text-end">
                        <Label className="switch">
                          <Input
                            type="checkbox"
                            checked={isEquityTradingActive}
                            onChange={() => {
                              setIsEquityTradingActive(!isEquityTradingActive);
                            }}
                          />
                          <span className="switch-state"></span>
                        </Label>
                      </Media>
                    </Media>
                  </Col>

                  <Col md={4} className={"mb-3"}>
                    <Media>
                      <Label className="col-form-label m-r-10">
                        Cds trading active{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </Label>
                      <Media body className="text-end">
                        <Label className="switch">
                          <Input
                            type="checkbox"
                            checked={isCdsTradingActive}
                            onChange={() => {
                              setIsCdsTradingActive(!isCdsTradingActive);
                            }}
                          />
                          <span className="switch-state"></span>
                        </Label>
                      </Media>
                    </Media>
                  </Col>


                  <Col md={4} className={"mb-3"}>
                    <Media>
                      <Label className="col-form-label m-r-10">
                        Fx trading active{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </Label>
                      <Media body className="text-end">
                        <Label className="switch">
                          <Input
                            type="checkbox"
                            checked={isFxTradingActive}
                            onChange={() => {
                              setIsFxTradingActive(!isFxTradingActive);
                            }}
                          />
                          <span className="switch-state"></span>
                        </Label>
                      </Media>
                    </Media>
                  </Col>


                  <Col md={4} className={"mb-3"}>
                    <Media>
                      <Label className="col-form-label m-r-10">
                        Crypto trading active{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </Label>
                      <Media body className="text-end">
                        <Label className="switch">
                          <Input
                            type="checkbox"
                            checked={isCryptoTradingActive}
                            onChange={() => {
                              setIsCryptoTradingActive(!isCryptoTradingActive);
                            }}
                          />
                          <span className="switch-state"></span>
                        </Label>
                      </Media>
                    </Media>
                  </Col>


                  <Col md={4} className={"mb-3"}>
                    <Media>
                      <Label className="col-form-label m-r-10">
                        Us trading active{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </Label>
                      <Media body className="text-end">
                        <Label className="switch">
                          <Input
                            type="checkbox"
                            checked={isUsTradingActive}
                            onChange={() => {
                              setIsUsTradingActive(!isUsTradingActive);
                            }}
                          />
                          <span className="switch-state"></span>
                        </Label>
                      </Media>
                    </Media>
                  </Col>

                  <Col md={4} className={"mb-3"}>
                    <Media>
                      <Label className="col-form-label m-r-10">
                        Option Buying{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </Label>
                      <Media body className="text-end">
                        <Label className="switch">
                          <Input
                            type="checkbox"
                            checked={isOptionBuyActive}
                            onChange={() => {
                              setIsOptionBuyAcive(
                                !isOptionBuyActive
                              );
                            }}
                          />
                          <span className="switch-state"></span>
                        </Label>
                      </Media>
                    </Media>
                  </Col>


                  <Col md={4} className={"mb-3"}>
                    <Media>
                      <Label className="col-form-label m-r-10">
                        Option Selling{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </Label>
                      <Media body className="text-end">
                        <Label className="switch">
                          <Input
                            type="checkbox"
                            checked={isOptionSellActive}
                            onChange={() => {
                              setIsOptionSellAcive(
                                !isOptionSellActive
                              );
                            }}
                          />
                          <span className="switch-state"></span>
                        </Label>
                      </Media>
                    </Media>
                  </Col>


                  {/* <Col md={4} className={"mb-3"}>
                    <Media>
                      <Label className="col-form-label m-r-10">
                       Bill effect on ledger{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </Label>
                      <Media body className="text-end">
                        <Label className="switch">
                          <Input
                            type="checkbox"
                            checked={isBillEffectOnLedger}
                            onChange={() => {
                              setIsBillEffectOnLedger(
                                !isBillEffectOnLedger
                              );
                            }}
                          />
                          <span className="switch-state"></span>
                        </Label>
                      </Media>
                    </Media>
                  </Col> */}

                  <Col md={4} className={"mb-3"}>
                    <Media>
                      <Label className="col-form-label m-r-10">
                        Auto closs on loss{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </Label>
                      <Media body className="text-end">
                        <Label className="switch">
                          <Input
                            type="checkbox"
                            checked={autoClossOnLoss}
                            onChange={() => {
                              setAutoClossOnLoss(
                                !autoClossOnLoss
                              );
                            }}
                          />
                          <span className="switch-state"></span>
                        </Label>
                      </Media>
                    </Media>
                  </Col>

                  <Col md={4} className={"mb-3"}>
                    <Media>
                      <Label className="col-form-label m-r-10">
                        Option only on market order{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </Label>
                      <Media body className="text-end">
                        <Label className="switch">
                          <Input
                            type="checkbox"
                            checked={optionOnlyOnMarketOrder}
                            onChange={() => {
                              setOptionOnlyOnMarketOrder(
                                !optionOnlyOnMarketOrder
                              );
                            }}
                          />
                          <span className="switch-state"></span>
                        </Label>
                      </Media>
                    </Media>
                  </Col>

                  <Col md={4} className={"mb-3"}>
                    <Media>
                      <Label className="col-form-label m-r-10">
                        Trade gap minutes apply on market order{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </Label>
                      <Media body className="text-end">
                        <Label className="switch">
                          <Input
                            type="checkbox"
                            checked={tradeGapMinutesApplyOnMarketOrder}
                            onChange={() => {
                              setTradeGapMinutesApplyOnMarketOrder(
                                !tradeGapMinutesApplyOnMarketOrder
                              );
                            }}
                          />
                          <span className="switch-state"></span>
                        </Label>
                      </Media>
                    </Media>
                  </Col>

                  <Col md={4} className={"mb-3"}>
                    <Media>
                      <Label className="col-form-label m-r-10">
                        Trade equity in units{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </Label>
                      <Media body className="text-end">
                        <Label className="switch">
                          <Input
                            type="checkbox"
                            checked={tradeEquityInUnit}
                            onChange={() => {
                              setTradeEquityInUnit(!tradeEquityInUnit);
                            }}
                          />
                          <span className="switch-state"></span>
                        </Label>
                      </Media>
                    </Media>
                  </Col>

                  <Col md={4} className={"mb-3"}>
                    <Media>
                      <Label className="col-form-label m-r-10">
                        Trade Mcx in units{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </Label>
                      <Media body className="text-end">
                        <Label className="switch">
                          <Input
                            type="checkbox"
                            checked={tradeMcxInUnit}
                            onChange={() => {
                              setTradeMcxInUnit(!tradeMcxInUnit);
                            }}
                          />
                          <span className="switch-state"></span>
                        </Label>
                      </Media>
                    </Media>
                  </Col>

                  <Col sm={12}></Col>

                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Minimum lot required per single trade of MCX{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter minimum lot"
                      type="text"
                      className="form-control"
                      value={minLotMcxSingleTrade}
                      onChange={(e) => {
                        setIntegerHandler(
                          e.target.value,
                          setMinLotMcxSingleTrade
                        );
                      }}
                    />
                  </Col>

                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Maximum lot allowed per single trade of MCX{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter maximum lot"
                      type="text"
                      className="form-control"
                      value={maxLotMcxSingleTrade}
                      onChange={(e) => {
                        setIntegerHandler(
                          e.target.value,
                          setMaxLotMcxSingleTrade
                        );
                      }}
                    />
                  </Col>

                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Minimum lot required per single trade of Equity{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter lot"
                      type="text"
                      className="form-control"
                      value={minLotEquitySingleTrade}
                      onChange={(e) => {
                        setIntegerHandler(
                          e.target.value,
                          setMinLotEquitySingleTrade
                        );
                      }}
                    />
                  </Col>

                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Maximum lot allowed per single trade of Equity{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter lot"
                      type="text"
                      className="form-control"
                      value={maxLotEquitySingleTrade}
                      onChange={(e) => {
                        setIntegerHandler(
                          e.target.value,
                          setMaxLotEquitySingleTrade
                        );
                      }}
                    />
                  </Col>

                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Minimum lot required per single trade of CDS{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter lot"
                      type="text"
                      className="form-control"
                      value={minLotCdsSingleTrade}
                      onChange={(e) => {
                        setIntegerHandler(
                          e.target.value,
                          setMinLotCdsSingleTrade
                        );
                      }}
                    />
                  </Col>

                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Maximum lot allowed per single trade of CDS{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter lot"
                      type="text"
                      className="form-control"
                      value={maxLotCdsSingleTrade}
                      onChange={(e) => {
                        setIntegerHandler(
                          e.target.value,
                          setMaxLotCdsSingleTrade
                        );
                      }}
                    />
                  </Col>

                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Minimum lot required per single trade of FX{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter lot"
                      type="text"
                      className="form-control"
                      value={minLotFxSingleTrade}
                      onChange={(e) => {
                        isNumericAndNotNegative(
                          e.target.value,
                          setMinLotFxSingleTrade
                        );
                      }}
                    />
                  </Col>

                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Maximum lot allowed per single trade of FX{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter lot"
                      type="text"
                      className="form-control"
                      value={maxLotFxSingleTrade}
                      onChange={(e) => {
                        isNumericAndNotNegative(
                          e.target.value,
                          setMaxLotFxSingleTrade
                        );
                      }}
                    />
                  </Col>


                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Minimum lot required per single trade of CRYPTO{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter lot"
                      type="text"
                      className="form-control"
                      value={minLotCryptoSingleTrade}
                      onChange={(e) => {
                        isNumericAndNotNegative(
                          e.target.value,
                          setMinLotCryptoSingleTrade
                        );
                      }}
                    />
                  </Col>

                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Maximum lot allowed per single trade of CRYPTO{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter lot"
                      type="text"
                      className="form-control"
                      value={maxLotCryptoSingleTrade}
                      onChange={(e) => {
                        isNumericAndNotNegative(
                          e.target.value,
                          setMaxLotCryptoSingleTrade
                        );
                      }}
                    />
                  </Col>

                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Minimum lot required per single trade of US{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter lot"
                      type="text"
                      className="form-control"
                      value={minLotUsSingleTrade}
                      onChange={(e) => {
                        setIntegerHandler(
                          e.target.value,
                          setMinLotUsSingleTrade
                        );
                      }}
                    />
                  </Col>

                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Maximum lot allowed per single trade of US{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter lot"
                      type="text"
                      className="form-control"
                      value={maxLotUsSingleTrade}
                      onChange={(e) => {
                        setIntegerHandler(
                          e.target.value,
                          setMaxLotUsSingleTrade
                        );
                      }}
                    />
                  </Col>



                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Maximum lot allowed per script of MCX{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter lot"
                      type="text"
                      className="form-control"
                      value={maxLotMcxPerScript}
                      onChange={(e) => {
                        setIntegerHandler(
                          e.target.value,
                          setMaxLotMcxPerScript
                        );
                      }}
                    />
                  </Col>

                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Maximum lot allowed per script of Equity{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter lot"
                      type="text"
                      className="form-control"
                      value={maxLotEquityPerScript}
                      onChange={(e) => {
                        setIntegerHandler(
                          e.target.value,
                          setMaxLotEquityPerScript
                        );
                      }}
                    />
                  </Col>

                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Maximum lot allowed per script of CDS{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter lot"
                      type="text"
                      className="form-control"
                      value={maxLotCdsPerScript}
                      onChange={(e) => {
                        setIntegerHandler(
                          e.target.value,
                          setMaxLotCdsPerScript
                        );
                      }}
                    />
                  </Col>


                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Maximum lot allowed per script of FX{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter lot"
                      type="text"
                      className="form-control"
                      value={maxLotFxPerScript}
                      onChange={(e) => {
                        setIntegerHandler(
                          e.target.value,
                          setMaxLotFxPerScript
                        );
                      }}
                    />
                  </Col>

                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Maximum lot allowed per script of CRYPTO{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter lot"
                      type="text"
                      className="form-control"
                      value={maxLotCryptoPerScript}
                      onChange={(e) => {
                        setIntegerHandler(
                          e.target.value,
                          setMaxLotCryptoPerScript
                        );
                      }}
                    />
                  </Col>

                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Maximum lot allowed per script of US{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter lot"
                      type="text"
                      className="form-control"
                      value={maxLotUsPerScript}
                      onChange={(e) => {
                        setIntegerHandler(
                          e.target.value,
                          setMaxLotUsPerScript
                        );
                      }}
                    />
                  </Col>

                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Maximum lot can stand{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter lot"
                      type="text"
                      className="form-control"
                      value={maxLotCanStand}
                      onChange={(e) => {
                        setIntegerHandler(
                          e.target.value,
                          setMaxLotCanStand
                        );
                      }}
                    />
                  </Col>


                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Maximum lot can stand USD{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter lot"
                      type="text"
                      className="form-control"
                      value={maxLotCanStandUsd}
                      onChange={(e) => {
                        setIntegerHandler(
                          e.target.value,
                          setMaxLotCanStandUsd
                        );
                      }}
                    />
                  </Col>


                  {/* <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      auto-Close all active trades when the losses reach %{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter percentage 11-98"
                      type="text"
                      className="form-control"
                      value={tradeAutoCloseOn}
                      onChange={(e) => {
                        setIntegerHandler(e.target.value, setTradeAutoCloseOn);
                      }}
                    />
                  </Col>

                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Notify client when the losses reach % of Ledger-balance{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter percentage 11-98"
                      type="text"
                      className="form-control"
                      value={lossNotificationOn}
                      onChange={(e) => {
                        setIntegerHandler(
                          e.target.value,
                          setLossNotificationOn
                        );
                      }}
                    />
                  </Col> */}

                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Orders to be away by % from current price Equity{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter percent"
                      type="text"
                      className="form-control"
                      value={equityStoplossPercentage}
                      onChange={(e) => {
                        setIntegerHandler(
                          e.target.value,
                          setEquityStoplossPercentage
                        );
                      }}
                    />
                  </Col>

                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Orders to be away by % from current price CDS{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter percentage"
                      type="text"
                      className="form-control"
                      value={cdsStoplossPercentage}
                      onChange={(e) => {
                        setIntegerHandler(
                          e.target.value,
                          setCdsStoplossPercentage
                        );
                      }}
                    />
                  </Col>

                  <Col md={6} className={"mb-4"}>
                    <label className="col-form-label form-label">
                      Orders to be away by % from current price MCX Type{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <select
                      className="form-control"
                      value={mcxStoplossType}
                      onChange={(e) => setMcxStoplossType(e.target.value)}
                    >
                      <option value="0">Select Type</option>

                      <option value="percent">Percent</option>
                      <option value="point">Point</option>
                    </select>
                  </Col>

                  {mcxStoplossType === "percent" ? (
                    <Col md={6} className={"mb-3"}>
                      <label className="col-form-label form-label">
                        Orders to be away by % from current price MCX{" "}
                        <sup>
                          <i
                            className="fa fa-asterisk text-danger"
                            style={{ fontSize: "8px" }}
                          ></i>
                        </sup>
                      </label>
                      <input
                        placeholder="Enter percentage"
                        type="text"
                        className="form-control"
                        value={mcxStoplossPercentage}
                        onChange={(e) => {
                          setIntegerHandler(
                            e.target.value,
                            setMcxStoplossPercentage
                          );
                        }}
                      />
                    </Col>
                  ) : (
                    ""
                  )}

                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Trade gap minutes{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter minutes"
                      type="text"
                      className="form-control"
                      value={tradeGapMinutes}
                      onChange={(e) => {
                        setIntegerHandler(e.target.value, setTradeGapMinutes);
                      }}
                    />
                  </Col>


                  <Col md={6} className={"mb-3"}>
                    <label className="col-form-label form-label">
                      Trade gap minutes USD{" "}
                      <sup>
                        <i
                          className="fa fa-asterisk text-danger"
                          style={{ fontSize: "8px" }}
                        ></i>
                      </sup>
                    </label>
                    <input
                      placeholder="Enter minutes"
                      type="text"
                      className="form-control"
                      value={tradeGapMinutesUsd}
                      onChange={(e) => {
                        setIntegerHandler(e.target.value, setTradeGapMinutesUsd);
                      }}
                    />
                  </Col>

                  <Accordion defaultActiveKey="0">
                    <div className="default-according" id="accordion1">
                      <Collapse isOpen={mcxStoplossType === "point"}>
                        <>
                          <h5 className="mt-3">
                            Enter Mcx stoploss points{" "}
                            <sup>
                              <i
                                className="fa fa-asterisk text-danger"
                                style={{ fontSize: "8px" }}
                              ></i>
                            </sup>
                          </h5>
                          <div className="border-bottom border-secondary mb-3"></div>
                          <Row>
                            {Object.keys(mcxPoints).map((symbol, idx) => {
                              return (
                                < Col md={6} className={"mb-3"} key={symbol}>
                                  <label className="col-form-label form-label">
                                    {symbol} stoploss points{" "}
                                    <span className="badge bg-secondary">
                                      {idx}
                                    </span>{" "}
                                  </label>
                                  <input
                                    placeholder="Enter points"
                                    type="text"
                                    className="form-control"
                                    value={mcxPoints[symbol]}
                                    onChange={(e) => {
                                      changeMcxPoints(e.target.value, symbol);
                                    }}
                                  />
                                </Col>
                              );
                            })}
                          </Row>
                        </>
                      </Collapse>
                    </div>
                  </Accordion>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* brokerage start from here */}
        {/* <Row> */}
        <Card className="mb-0">
          <CardHeader
            className="bg-primary py-3"
            onClick={() => setBrokerageIsOpen(!brokerageIsOpen)}
          >
            <h5 className="d-flex justify-content-between">
              <span>Brokerage</span>
              {brokerageIsOpen ? (
                <i className="icofont icofont-rounded-up"></i>
              ) : (
                <i className="icofont icofont-rounded-down"></i>
              )}
            </h5>
          </CardHeader>
        </Card>
        <Collapse isOpen={brokerageIsOpen}>
          <div className="bg-primary p-1">
            <CreateBrokeragePage setBrokerageData={setBrokerageData} brokerageTemplateId={brokerageTemplateId}/>
          </div>
        </Collapse>
        {/* </Row> */}
        {/* brokerage end from here */}
        {/* start mergin */}
        <Card className="mb-0">
          <CardHeader
            className="bg-secondary py-3"
            onClick={() => setMarginIsOpen(!marginIsOpen)}
          >
            <h5 className="d-flex justify-content-between">
              <span>Margin</span>
              {marginIsOpen ? (
                <i className="icofont icofont-rounded-up"></i>
              ) : (
                <i className="icofont icofont-rounded-down"></i>
              )}
            </h5>
          </CardHeader>
        </Card>
        <Collapse isOpen={marginIsOpen}>
          <div className="bg-secondary p-1">
            <CreateMarginPage setMarginData={setMarginData} marginTemplateId={marginTemplateId}/>
          </div>
        </Collapse>
        {/* end margin */}
      </Container >
    </Fragment >
  );
};

export default GeneralComponent;
