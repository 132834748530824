import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
  Input,
  Label,
} from "reactstrap";
import Select from "react-select";
import { useSelector } from "react-redux";
import {
  FETCH_MARKET_INSTRUMENTS_URL,
  // FETCH_ALL_UNDER_CLIENTS_URL,
  CREATE_POSITION_URL,
  SEARCH_CLIENT_BY_USERNAME_URL
} from "../../../_helper/url_helper";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import { AsyncPaginate } from 'react-select-async-paginate';

export default function CreatePositionModal(props) {
  const [type, setType] = useState("buy");
  const [avgPrice, setAvgPrice] = useState("");
  const [qty, setQty] = useState("");
  // const [underCientOptions, setUnderClientOptions] = useState([]);
  const [instrumentOptions, setInstrumentOptions] = useState([]);
  const [instrument, setInstrument] = useState(null);
  const [client, setClient] = useState(null);
  const loginState = useSelector((state) => state.login);

  useEffect(() => {
    // fetchUnderClientsHandler();
    fetchInstrumentsHandler();
  }, []);

  const loadOptionsClient = async (inputValue) => {
    let data = [];
    data = await fetchUnderClientsHandler(inputValue);
    return { options: data };
  }

  async function fetchUnderClientsHandler(username) {
    try {
      // let data = {
      //   jwttoken: loginState.jwttoken,
      // };
      let data = {
        username: username
      }
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(SEARCH_CLIENT_BY_USERNAME_URL, { params: data });
      if (res.data.status === "SUCCESS") {
        let temp = [];
        temp = res.data.payload.map((user) => {
          return { value: user.id, label: user.username };
        });
        // setUnderClientOptions(temp);
        return temp;
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  // async function fetchUnderClientsHandler() {
  //   try {
  //     let data = {
  //       jwttoken: loginState.jwttoken,
  //     };
  //     let res = await api.post(FETCH_ALL_UNDER_CLIENTS_URL, data);
  //     if (res.data.status === 1) {
  //       let temp = res.data.data.map((user) => {
  //         return { value: user.id, label: user.username };
  //       });
  //       setUnderClientOptions(temp);
  //     } else {
  //       toast.error(res.data.message);
  //     }
  //   } catch (error) {
  //     toast.error(error.message);
  //   }
  // }

  async function fetchInstrumentsHandler() {
    try {
      // let data = {
      //   jwttoken: loginState.jwttoken,
      // };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_MARKET_INSTRUMENTS_URL);
      if (res.data.status === "SUCCESS") {
        let temp = res.data.payload.map((instrument) => {
          return { value: instrument.token, label: instrument.trading_symbol };
        });
        setInstrumentOptions(temp);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function createPositionHandler() {
    try {
      if (instrument === null) {
        toast.error("Instrument required");
        return;
      }
      if (client === null) {
        toast.error("Client required");
        return;
      }
      if (!qty) {
        toast.error("Qty required");
        return;
      }
      if (!avgPrice) {
        toast.error("Avg Price required");
        return;
      }
      let data = {
        // jwttoken: loginState.jwttoken,
        client_id: client.value,
        token: instrument.value,
        type: type,
        qty: qty,
        price: avgPrice,
      };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.post(CREATE_POSITION_URL, data);
      if (res.data.status === "SUCCESS") {
        toast.success("Positon Created");
        props.setCreatePositionModalStatus(false);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Modal isOpen={props.createPositionModalStatus} toggle={() => { props.setCreatePositionModalStatus(false) }}>
      <ModalHeader>Create Position</ModalHeader>
      <ModalBody>
        <Row className="mb-3">
          <Col>
            <button
              className={`btn ${type === "buy" ? "btn-primary" : "btn-outline-primary"
                }`}
              onClick={() => {
                setType("buy");
              }}
            >
              Buy
            </button>
            <button
              className={`mx-3 btn ${type === "sell" ? "btn-danger" : "btn-outline-danger"
                }`}
              onClick={() => {
                setType("sell");
              }}
            >
              Sell
            </button>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <label>Instrument</label>
            <Select
              options={instrumentOptions}
              className="js-example-basic-single col-sm-12"
              value={instrument}
              isClearable={true}
              isSearchable={true}
              onChange={(e) => {
                setInstrument(e);
              }}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <label>Client</label>
            {/* <Select
              options={underCientOptions}
              className="js-example-basic-single col-sm-12"
              value={client}
              isClearable={true}
              isSearchable={true}
              onChange={(e) => {
                setClient(e);
              }}
            /> */}
            <AsyncPaginate
              className="js-example-basic-single col-sm-12"
              value={client}
              isClearable={true}
              loadOptions={loadOptionsClient}
              // onChange={setValue}
              onChange={(e) => { setClient(e) }}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <label>Qty</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Price"
              value={qty}
              onChange={(e) => {
                if (
                  (e.target.value > 0 &&
                    Number.isInteger(Number(e.target.value))) ||
                  e.target.value === ""
                ) {
                  setQty(e.target.value);
                }
              }}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <label>Avg Price</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Price"
              value={avgPrice}
              onChange={(e) => {
                if (e.target.value === "" || e.target.value > 0) {
                  setAvgPrice(e.target.value);
                }
              }}
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-primary mx-3" onClick={createPositionHandler}>Create</button>
        <button className="btn btn-outline-secondary" onClick={() => { props.setCreatePositionModalStatus(false) }}>Cancel</button>
      </ModalFooter>
    </Modal>
  );
}
