import React, { Fragment, useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import {
  CardBody,
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Collapse,
  Table,
} from "reactstrap";
import { H2, H4, H5 } from "../../../AbstractElements";
import { Accordion } from "react-bootstrap";
import { useSelector } from "react-redux";
import api from "../../../_helper/api_helper";
import { toast } from "react-toastify";
import { FETCH_CLOSE_SCRIPT_URL } from "../../../_helper/url_helper";
// import { useNavigate } from "react-router-dom";

const GeneralComponent = () => {
  // const navigate = useNavigate();
  const loginState = useSelector((state) => state.login);
  const [isOpen, setIsOpen] = useState(1);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  const [startDate, setstartDate] = useState(new Date());
  const [closeScripts, setCloseScripts] = useState([]);

  useEffect(() => {
    fetchCloseScriptsHandler();
  }, []);

  useEffect(() => {
    let interval = setInterval(fetchCloseScriptsHandler, 20000);
    return () => clearInterval(interval);
  }, []);

  async function fetchCloseScriptsHandler() {
    try {
      // let data = {
      //   jwttoken: loginState.jwttoken,
      // };
      api.defaults.headers.common["Authorization"] = `Bearer ${loginState.jwttoken}`;
      let res = await api.get(FETCH_CLOSE_SCRIPT_URL);
      if (res.data.status === "SUCCESS") {
        setCloseScripts(res.data.payload);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Fragment>
      <Container fluid={true}>
        {/* <Row>
          <Col sm="12">
            <Accordion defaultActiveKey="0">
              <div className="default-according" id="accordion1">
                <Card className="mb-4">
                  <CardHeader className="bg-secondary" onClick={() => toggle(1)}>
                    <h5 className="d-flex justify-content-between">
                      <span>Filters</span>
                      {isOpen === 1 ? (
                        <i className="icofont icofont-rounded-up"></i>
                      ) : (
                        <i className="icofont icofont-rounded-down"></i>
                      )}
                    </h5>
                  </CardHeader>
                  <Collapse isOpen={isOpen === 1}>
                    <CardBody>
                      <div className="row">
                        <div className="col">
                          <label className="col-form-label form-label">
                            Search Script
                          </label>
                          <input type="text" className="form-control" placeholder="Enter Script"/>
                        </div>

                        <div className="col">
                          <label className="col-form-label form-label">
                            View user panel
                          </label>
                          <input type="text" className="form-control" placeholder="Select Username"/>
                        </div>

                        <div className="col row align-items-end">
                          <div>
                            <button className="btn btn-danger m-t-5">
                              Reset
                            </button>
                          </div>
                        </div>
                        <div className="col"></div>
                      </div>
                    </CardBody>
                  </Collapse>
                </Card>
              </div>
            </Accordion>
          </Col>
        </Row> */}

        <Row>
          <Col sm="12">
            <Card>
              {/* <CardBody> */}
              <div className="table-responsive">
                <Table className="table-border-horizontal fw-semibold text-nowrap" striped>
                  <thead>
                    <tr className="bg-primary">
                      <th style={{ color: "#ffffff" }}>Script</th>
                      <th style={{ color: "#ffffff" }}>BuyQty</th>
                      <th style={{ color: "#ffffff" }}>SellQty</th>
                      <th style={{ color: "#ffffff" }}>Avg Buy Rate</th>
                      <th style={{ color: "#ffffff" }}>Avg Sell Rate</th>
                      <th style={{ color: "#ffffff" }}>Profit / Loss</th>
                      <th style={{ color: "#ffffff" }}>Brokerage</th>
                      <th style={{ color: "#ffffff" }}>Net P/L</th>
                    </tr>
                  </thead>
                  <tbody>
                    {closeScripts.scripts !== undefined && closeScripts.scripts.length
                      ? closeScripts.scripts.map((script, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{script.trading_symbol}</td>
                            <td>{parseFloat(script.active_buy).toFixed(2)}</td>
                            <td>{parseFloat(script.active_sell).toFixed(2)}</td>
                            <td>{parseFloat(script.avg_buy_price).toFixed(2)}</td>
                            <td>{parseFloat(script.avg_sell_price).toFixed(2)}</td>
                            <td>{parseFloat(script.profit_and_loss).toFixed(2)}</td>
                            <td>{parseFloat(script.brokerage).toFixed(2)}</td>
                            <td>{(script.profit_and_loss - script.brokerage).toFixed(2)}</td>
                            {/* <td>{parseFloat(script.net).toFixed(2)}</td> */}
                          </tr>
                        );
                      })
                      : ""}

                  </tbody>
                </Table>
              </div>
              {/* </CardBody> */}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GeneralComponent;
