import React, { Fragment, useState, useEffect } from "react";
import {
  CardBody,
  Card,
  CardHeader,
  Container,
  Col,
  Row,
  Nav,
  Collapse,
  NavItem,
  NavLink,
  Label,
} from "reactstrap";
import { useSelector } from "react-redux";
import api from "../../../../_helper/api_helper";
import { toast } from "react-toastify";
import {
  FETCH_SYMBOLS_URL,
  FETCH_SINGLE_TEMPLATE_URL,
  // UPDATE_BROKERAGE_TEMPLATE
} from "../../../../_helper/url_helper";
import { useNavigate, useParams } from "react-router-dom";
import ScriptTable from "./ScriptTable";
import ScriptTableNfo from "./ScriptTableNfo";

const GeneralComponent = () => {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const loginState = useSelector((state) => state.login);
  const [name, setName] = useState("");
  const [exchange, setExchange] = useState("equity");
  const [mcxBrokerageType, setMcxBrokerageType] = useState("crore");
  const [equityBrokerage, setEquityBrokerage] = useState("");
  const [cdsBrokerage, setCdsBrokerage] = useState("");
  const [mcxBrokerage, setMcxBrokerage] = useState("");
  const [mcxSymbols, setMcxSymbols] = useState("");
  const [mcxBrokerageLot, setMcxBrokerageLot] = useState("");
  const [nfoSymbols, setNfoSymbols] = useState([]);
  const [nfoBrokerageLot, setNfoBrokerageLot] = useState("");

  //fetch first time users
  useEffect(() => {
    fetchSymbolsHandler();
  }, []);

  useEffect(()=>{
    //  setup nfo data
    let temp = {};
    nfoSymbols.map((symbol,idx) => {
       let obj = {};
       temp[symbol["symbol"]] = (idx < 2)?nfoBrokerageLot[symbol["symbol"]]:equityBrokerage;
       return obj;
     });
     setNfoBrokerageLot(temp);
},[equityBrokerage]);

  async function fetchSymbolsHandler() {
    try {
      let data = {
        jwttoken: loginState.jwttoken,
      };
      let res = await api.post(FETCH_SYMBOLS_URL, data);
      if (res.data.status === 1) {
        //setup mcx data
        let temp = {};
        res.data.data.MCX.map((symbol) => {
          let obj = {};
          temp[symbol["symbol"]] = "";
          return obj;
        });
        setMcxBrokerageLot(temp);
        setMcxSymbols(res.data.data.MCX);
         //setup nfo data
         temp = {};
         res.data.data.NFO.map((symbol) => {
           let obj = {};
           temp[symbol["symbol"]] = "";
           return obj;
         });
         setNfoBrokerageLot(temp);
         setNfoSymbols(res.data.data.NFO);
         fetchTemplate();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function fetchTemplate() {
    try {
      let data = {
        jwttoken: loginState.jwttoken,
        template_id: templateId
      };
      let res = await api.post(FETCH_SINGLE_TEMPLATE_URL, data);
      if (res.data.status === 1) {
          setName(res.data.data.template.name);
          setEquityBrokerage(res.data.data.template.equity_brokerage_per_crore);
          setCdsBrokerage(res.data.data.template.cds_brokerage_per_lot);
          setMcxBrokerageType(res.data.data.template.mcx_brokerage_type);
          //set nfo option lot
          let temp = {};
          res.data.data.nfo_lot_brokerage.map((brokerage) => {
            let obj = {};
            temp[brokerage["instrument_symbol"]] = brokerage["brokerage"];
            return obj;
          });
          setNfoBrokerageLot(temp);
          //set mcx lot
          if(res.data.data.template.mcx_brokerage_type === "crore"){
            setMcxBrokerage(res.data.data.template.mcx_brokerage_per_crore);
          }else{
            temp = {};
            res.data.data.mcx_lot_brokerage.map((brokerage) => {
              let obj = {};
              temp[brokerage["instrument_symbol"]] = brokerage["brokerage"];
              return obj;
            });
            setMcxBrokerageLot(temp);
          }
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }

  
  return (
    <Fragment>
      <Container fluid={true} className="datatables">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="py-3">
                 <h5>{name}</h5>
              </CardHeader>
              <CardBody className="border-top">
                <Nav tabs className="row justify-content-between mb-4">
                  <NavItem className="col px-0">
                    <NavLink
                      className={`text-center ${
                        exchange === "equity" ? "active" : ""
                      }`}
                      onClick={() => setExchange("equity")}
                    >
                      EQUITY
                    </NavLink>
                  </NavItem>
                  <NavItem className="col px-0">
                    <NavLink
                      className={`text-center ${
                        exchange === "cds" ? "active" : ""
                      }`}
                      onClick={() => setExchange("cds")}
                    >
                      CDS
                    </NavLink>
                  </NavItem>

                  <NavItem className="col px-0">
                    <NavLink
                      className={`text-center ${
                        exchange === "mcx" ? "active" : ""
                      }`}
                      onClick={() => setExchange("mcx")}
                    >
                      MCX
                    </NavLink>
                  </NavItem>
                </Nav>
                <Row>
                  {exchange === "equity" ? (
                    <Col className="px-0">
                      <Label>Enter Equity Brokerage Per Crore</Label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter brokerage"
                        value={equityBrokerage}
                        readOnly
                      />
                    </Col>
                  ) : (
                    ""
                  )}

                  {exchange === "cds" ? (
                    <Col className="px-0">
                      <Label>Enter CDS Brokerage Per Lot</Label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter brokerage"
                        value={cdsBrokerage}
                        readOnly
                      />
                    </Col>
                  ) : (
                    ""
                  )}

                  {exchange === "mcx" ? (
                    <>
                      <Col className="mb-4 px-0" sm={12}>
                        <button
                          className={`btn ${
                            mcxBrokerageType === "crore"
                              ? "btn-primary"
                              : "btn-outline-primary"
                          }`}
                         
                        >
                          Charge Per Crore
                        </button>
                        <button
                          className={`btn ${
                            mcxBrokerageType === "lot"
                              ? "btn-primary"
                              : "btn-outline-primary"
                          } mx-4`}
                        
                        >
                          Charge Per Lot
                        </button>
                      </Col>
                      <Collapse isOpen={mcxBrokerageType === "crore"}>
                        <Col className="px-0" sm={12}>
                          <Label>Enter MCX Brokerage Per Crore</Label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter brokerage"
                            value={mcxBrokerage}
                            readOnly
                          />
                        </Col>
                      </Collapse>
                    </>
                  ) : (
                    ""
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ScriptTable
          exchange={exchange}
          mcxBrokerageType={mcxBrokerageType}
          mcxSymbols={mcxSymbols}
          mcxBrokerageLot={mcxBrokerageLot}
          setMcxBrokerageLot={setMcxBrokerageLot}
        />

        <ScriptTableNfo
          exchange={exchange}
          nfoSymbols={nfoSymbols}
          nfoBrokerageLot={nfoBrokerageLot}
          setNfoBrokerageLot={setNfoBrokerageLot}
        />
      </Container>
    </Fragment>
  );
};

export default GeneralComponent;
